import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openFile, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format, nextMonday } from 'date-fns';

export const getDefaultEmployeeDocumentFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
  type: 'salary_transfer_application',
});

export const getDefaultEmployeeVacationApplicationFormItem = () => ({
  type: 'vacation_paid',
  start: format(nextMonday(new Date()), 'yyyy-MM-dd'),
  is_approved: false,
  days: 5,
});

export const getDefaultEmployeeDocumentFilterParams = () => ({});

const state = {
  employeeDocuments: [],
  employeeDocumentPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedEmployeeDocument: {},
  newEmployeeDocument: getDefaultEmployeeDocumentFormItem(),
  newEmployeeVacationApplication: getDefaultEmployeeVacationApplicationFormItem(),
  employeeDocumentValidationErrors: {},
  employeeDocumentFilterParams: getDefaultEmployeeDocumentFilterParams(),
  downloadingEmployeeDocuments: false, // booleans instead of $store.getter.loading because both loading and downloading use the same route
  loadingEmployeeDocuments: false,
  loadingEmployeeVacationApplicationPreview: false,
  savingEmployeeVacationApplication: false,
};

const getters = {
  employeeDocumentTypes() {
    return [
      'salary_transfer_application',
      'employment_application',
      'applying_non_taxable_income_application',
      'employment_agreement',
      'vacation_application',
      'nid',
      'order',
      'other',
    ].map((t) => ({
      value: t,
      text: i18n.t(`documents.employee_document_types.${t}`),
    }));
  },

  employeeVacationApplicationTypes() {
    return ['vacation_paid', 'vacation_unpaid'].map((t) => ({
      value: t,
      text: i18n.t(`general.${t}`),
    }));
  },

  employeeDocumentFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  },
};

const mutations = {
  SET_EMPLOYEE_DOCUMENTS(state, { data, current_page, per_page, total }) {
    state.employeeDocuments = data;
    state.employeeDocumentPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_EMPLOYEE_DOCUMENT_FILTER_PARAMS(state, params) {
    state.employeeDocumentFilterParams = params;
  },

  SET_EDITED_EMPLOYEE_DOCUMENT(state, employeeDocument) {
    state.employeeDocumentValidationErrors = {};
    state.editedEmployeeDocument = JSON.parse(JSON.stringify(employeeDocument));
  },

  SET_NEW_EMPLOYEE_DOCUMENT(state, employeeDocument) {
    state.newEmployeeDocument = employeeDocument;
  },

  SET_NEW_EMPLOYEE_VACATION_APPLICATION(state, application) {
    state.newEmployeeVacationApplication = application;
  },

  STORE_EMPLOYEE_DOCUMENT(state, employeeDocument) {
    state.employeeDocuments.unshift(employeeDocument);
    state.employeeDocumentPagination.total += 1;
    state.employeeDocumentValidationErrors = {};
  },

  UPDATE_EMPLOYEE_DOCUMENT(state, employeeDocument) {
    state.employeeDocuments = updateArrayItem(state.employeeDocuments, employeeDocument);
  },

  DELETE_EMPLOYEE_DOCUMENT(state, employeeDocument) {
    state.employeeDocuments = removeArrayItem(state.employeeDocuments, employeeDocument);
    state.employeeDocumentPagination.total -= 1;
  },

  SET_EMPLOYEE_DOCUMENT_VALIDATION_ERRORS(state, employeeDocumentValidationErrors) {
    state.employeeDocumentValidationErrors = employeeDocumentValidationErrors;
  },

  CLEAR_EMPLOYEE_DOCUMENT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.employeeDocumentValidationErrors, field);
  },

  SET_DOWNLOADING_EMPLOYEE_DOCUMENTS(state, value) {
    state.downloadingEmployeeDocuments = value;
  },

  SET_LOADING_EMPLOYEE_DOCUMENTS(state, value) {
    state.loadingEmployeeDocuments = value;
  },

  SET_LOADING_EMPLOYEE_VACATION_APPLICATION_PREVIEW(state, value) {
    state.loadingEmployeeVacationApplicationPreview = value;
  },

  SET_SAVING_EMPLOYEE_VACATION_APPLICATION(state, value) {
    state.savingEmployeeVacationApplication = value;
  },
};

const actions = {
  fetchEmployeeDocuments({ commit }, params) {
    commit('SET_EMPLOYEE_DOCUMENT_FILTER_PARAMS', params);
    commit('SET_LOADING_EMPLOYEE_DOCUMENTS', true);
    return documentService
      .getPage(params, 'employee')
      .then((res) => {
        commit('SET_EMPLOYEE_DOCUMENTS', res.data);
        return res.data;
      })
      .finally(() => {
        commit('SET_LOADING_EMPLOYEE_DOCUMENTS', false);
      });
  },

  async downloadEmployeeDocuments({ state, commit }) {
    commit('SET_DOWNLOADING_EMPLOYEE_DOCUMENTS', true);
    const { data } = await documentService.downloadAll(
      state.employeeDocumentFilterParams,
      'employee'
    );
    commit('SET_DOWNLOADING_EMPLOYEE_DOCUMENTS', false);
    downloadFile(data, `${i18n.t('general.employee_documents')}.zip`);
  },

  storeEmployeeDocument({ commit }, employeeDocument) {
    return documentService
      .create({ ...employeeDocument, document_type: 'employee' })
      .then((res) => {
        commit('STORE_EMPLOYEE_DOCUMENT', res.data);
        commit('SET_NEW_EMPLOYEE_DOCUMENT', getDefaultEmployeeDocumentFormItem());
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_EMPLOYEE_DOCUMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  previewEmployeeVacationApplication({ commit }, { application, userId }) {
    commit('SET_LOADING_EMPLOYEE_VACATION_APPLICATION_PREVIEW', true);
    return documentService
      .createVacationApplication(userId, application, {
        responseType: 'blob',
        params: { save: false },
      })
      .then((res) => {
        const fileURL = URL.createObjectURL(res.data);
        openFile({
          name: 'Preview.pdf',
          url: fileURL,
          isPublic: true,
        });
        return res.data;
      })
      .catch((err) => {
        // because of responseType: 'blob' I can't display validation errors
        // commit('SET_EMPLOYEE_DOCUMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      })
      .finally(() => {
        commit('SET_LOADING_EMPLOYEE_VACATION_APPLICATION_PREVIEW', false);
      });
  },

  storeEmployeeVacationApplication({ commit }, { application, userId }) {
    commit('SET_SAVING_EMPLOYEE_VACATION_APPLICATION', true);
    return documentService
      .createVacationApplication(userId, application, { params: { save: true } })
      .then((res) => {
        commit('STORE_EMPLOYEE_DOCUMENT', res.data.document);
        commit(
          'SET_NEW_EMPLOYEE_VACATION_APPLICATION',
          getDefaultEmployeeVacationApplicationFormItem()
        );
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_EMPLOYEE_DOCUMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      })
      .finally(() => {
        commit('SET_SAVING_EMPLOYEE_VACATION_APPLICATION', false);
      });
  },

  editEmployeeDocument({ state, commit }, employeeDocumentId) {
    const employeeDocument = state.employeeDocuments?.find((c) => c.id === employeeDocumentId);
    if (employeeDocument) {
      commit('SET_EDITED_EMPLOYEE_DOCUMENT', employeeDocument);
      return Promise.resolve(employeeDocument);
    }
    return documentService.getById(employeeDocumentId).then((res) => {
      commit('SET_EDITED_EMPLOYEE_DOCUMENT', res.data);
      return res.data;
    });
  },

  updateEmployeeDocument({ commit }, employeeDocument) {
    return documentService
      .update(employeeDocument)
      .then((res) => {
        commit('UPDATE_EMPLOYEE_DOCUMENT', res.data);
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_EMPLOYEE_DOCUMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async toggleSeenEmployeeDocument({ commit }, employeeDocument) {
    try {
      commit('UPDATE_EMPLOYEE_DOCUMENT', {
        ...employeeDocument,
        seen: employeeDocument.seen ? null : {},
      });
      await documentService.toggleSeen(employeeDocument);
    } catch (e) {
      commit('UPDATE_EMPLOYEE_DOCUMENT', employeeDocument);
    }
  },

  async toggleEmployeeDocumentSignature({ commit }, updatedDocument) {
    try {
      commit('UPDATE_EMPLOYEE_DOCUMENT', updatedDocument);
      await documentService.update(updatedDocument);
    } catch (e) {
      commit('UPDATE_EMPLOYEE_DOCUMENT', {
        ...updatedDocument,
        is_signed_by_all_signees: !updatedDocument.is_signed_by_all_signees,
      });
    }
  },

  async uploadNewEmployeeDocumentFile({ commit }, { employeeDocument, newFile }) {
    try {
      const { data } = await documentService.uploadNewFile(employeeDocument, newFile);
      commit('UPDATE_EMPLOYEE_DOCUMENT', data);
      openSnackbar(i18n.t('documents.messages.file_changed'));
    } catch (e) {
      openSnackbar(i18n.t('documents.messages.file_upload_failed'));
    }
  },

  deleteEmployeeDocument({ commit }, employeeDocument) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(employeeDocument).then(() => {
        commit('DELETE_EMPLOYEE_DOCUMENT', employeeDocument);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import router from '@/router';

let previousHref = '';
export function onRouteChange(to, from) {
  // called inside router/index.js beforeEach hook
  if (router.resolve(to).href === previousHref) {
    previousHref = '';
  } else {
    previousHref = router.resolve(from).href;
  }
}

export async function navigate(route) {
  // Motivation: consider this scenario:
  // 1. User is on invoices list page
  // 2. User enters an invoice details page
  // 3. User opens edit form inside that page.
  //    What should back button do?
  //    It should go back to invoice details page, not to the invoice list page.
  // 4. Users saves the invoice and is redirected to the invoice details page.
  //    What should back button do now?
  //    It should go back to the invoice list page, not back to invoice form.
  // --
  // By default using router.push, in order to go back to the invoice list page at this point,
  // the user will have to click the back button three times (form, details, list).
  // --
  // To override this, we store the previous route,
  // and when we're about to push a new route we compare if the route being pushed is the same as the previousRoute.
  // If it is, we go back instead of pushing the route.
  // --
  // If this solution ends up working well,
  // you can safely replace router.push/replace calls everywhere with a call to navigate action.
  // Trialing this out in invoices module.
  let nextHref = router.resolve(route).href;
  let logMessage = '';
  if (previousHref === nextHref) {
    logMessage = 'going back';
    await router.go(-1);
  } else {
    logMessage = 'pushing new history entry';
    await router.push(route);
  }
  console.log(`navigate called:\n`, 'from', previousHref, '\n', 'to  ', nextHref, '\n', logMessage);
}

<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <InternalDocumentForm
      :internal-document="newInternalDocument"
      :errors="internalDocumentValidationErrors"
      :disabled="$store.getters.loading['post:api/documents']"
      @clear:errors="CLEAR_INTERNAL_DOCUMENT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import InternalDocumentForm from '@/components/forms/InternalDocumentForm.vue';
import { returnFromChildView } from '@/util/routing';

export default {
  name: 'CreateInternalDocument',

  components: { InternalDocumentForm },

  computed: {
    ...mapState('internalDocuments', [
      'newInternalDocument',
      'internalDocumentValidationErrors',
      'internalDocumentFilterParams',
    ]),
  },

  created() {
    this.SET_INTERNAL_DOCUMENT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('internalDocuments', ['storeInternalDocument']),
    ...mapMutations('internalDocuments', [
      'SET_INTERNAL_DOCUMENT_VALIDATION_ERRORS',
      'CLEAR_INTERNAL_DOCUMENT_VALIDATION_ERRORS',
    ]),

    onSave(internalDocument) {
      this.storeInternalDocument(internalDocument).then(() => {
        this.goBack();
      });
    },

    goBack() {
      returnFromChildView(this.$route, 'uploading-document', {
        name: 'internalDocuments',
        query: this.internalDocumentFilterParams,
      });
    },
  },
};
</script>

import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format } from 'date-fns';

export const getDefaultInternalDocumentFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultInternalDocumentFilterParams = () => ({});

const state = {
  internalDocuments: [],
  internalDocumentPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedInternalDocument: {},
  newInternalDocument: getDefaultInternalDocumentFormItem(),
  internalDocumentValidationErrors: {},
  internalDocumentFilterParams: getDefaultInternalDocumentFilterParams(),
  downloadingInternalDocuments: false, // booleans instead of $store.getter.loading because both loading and downloading use the same route
  loadingInternalDocuments: false,
};

const getters = {
  internalDocumentFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  },
};

const mutations = {
  SET_INTERNAL_DOCUMENTS(state, { data, current_page, per_page, total }) {
    state.internalDocuments = data;
    state.internalDocumentPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_INTERNAL_DOCUMENT_FILTER_PARAMS(state, params) {
    state.internalDocumentFilterParams = params;
  },

  SET_NEW_INTERNAL_DOCUMENT(state, internalDocument) {
    state.newInternalDocument = internalDocument;
  },

  SET_EDITED_INTERNAL_DOCUMENT(state, internalDocument) {
    state.internalDocumentValidationErrors = {};
    state.editedInternalDocument = JSON.parse(JSON.stringify(internalDocument));
  },

  STORE_INTERNAL_DOCUMENT(state, internalDocument) {
    state.internalDocuments.unshift(internalDocument);
    state.internalDocumentPagination.total += 1;
    state.internalDocumentValidationErrors = {};
    state.newInternalDocument = getDefaultInternalDocumentFormItem();
  },

  UPDATE_INTERNAL_DOCUMENT(state, internalDocument) {
    state.internalDocuments = updateArrayItem(state.internalDocuments, internalDocument);
  },

  DELETE_INTERNAL_DOCUMENT(state, internalDocument) {
    state.internalDocuments = removeArrayItem(state.internalDocuments, internalDocument);
    state.internalDocumentPagination.total -= 1;
  },

  SET_INTERNAL_DOCUMENT_VALIDATION_ERRORS(state, internalDocumentValidationErrors) {
    state.internalDocumentValidationErrors = internalDocumentValidationErrors;
  },

  CLEAR_INTERNAL_DOCUMENT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.internalDocumentValidationErrors, field);
  },

  SET_DOWNLOADING_INTERNAL_DOCUMENTS(state, value) {
    state.downloadingInternalDocuments = value;
  },

  SET_LOADING_INTERNAL_DOCUMENTS(state, value) {
    state.loadingInternalDocuments = value;
  },
};

const actions = {
  fetchInternalDocuments({ commit }, params) {
    commit('SET_INTERNAL_DOCUMENT_FILTER_PARAMS', params);
    commit('SET_LOADING_INTERNAL_DOCUMENTS', true);
    return documentService
      .getPage(params, 'internal')
      .then((res) => {
        commit('SET_INTERNAL_DOCUMENTS', res.data);
        return res.data;
      })
      .finally(() => {
        commit('SET_LOADING_INTERNAL_DOCUMENTS', false);
      });
  },

  async downloadInternalDocuments({ state, commit }) {
    commit('SET_DOWNLOADING_INTERNAL_DOCUMENTS', true);
    const { data } = await documentService.downloadAll(
      state.clientAgreementFilterParams,
      'internal'
    );
    commit('SET_DOWNLOADING_INTERNAL_DOCUMENTS', false);
    downloadFile(data, `${i18n.t('general.internal_documents')}.zip`);
  },

  storeInternalDocument({ commit }, internalDocument) {
    return documentService
      .create({ ...internalDocument, document_type: 'internal' })
      .then((res) => {
        commit('STORE_INTERNAL_DOCUMENT', res.data);
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_INTERNAL_DOCUMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editInternalDocument({ state, commit }, internalDocumentId) {
    const internalDocument = state.internalDocuments?.find((c) => c.id === internalDocumentId);
    if (internalDocument) {
      commit('SET_EDITED_INTERNAL_DOCUMENT', internalDocument);
      return Promise.resolve(internalDocument);
    }
    return documentService.getById(internalDocumentId).then((res) => {
      commit('SET_EDITED_INTERNAL_DOCUMENT', res.data);
      return res.data;
    });
  },

  updateInternalDocument({ commit }, internalDocument) {
    return documentService
      .update(internalDocument)
      .then((res) => {
        commit('UPDATE_INTERNAL_DOCUMENT', res.data);
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_INTERNAL_DOCUMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async toggleSeenInternalDocument({ commit }, internalDocument) {
    try {
      commit('UPDATE_INTERNAL_DOCUMENT', {
        ...internalDocument,
        seen: internalDocument.seen ? null : {},
      });
      await documentService.toggleSeen(internalDocument);
    } catch (e) {
      commit('UPDATE_INTERNAL_DOCUMENT', internalDocument);
    }
  },

  deleteInternalDocument({ commit }, internalDocument) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(internalDocument).then(() => {
        commit('DELETE_INTERNAL_DOCUMENT', internalDocument);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vue from 'vue';
import bankAccountService from '@/api/bank-account-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';

export const getDefaultClientBankAccountFormItem = () => ({});

export const getDefaultBankAccountFilterParams = () => ({});

const state = {
  bankAccounts: [],
  editedBankAccount: {},
  newBankAccount: getDefaultClientBankAccountFormItem(),
  bankAccountValidationErrors: {},
  bankAccountFilterParams: getDefaultBankAccountFilterParams(),
};

const getters = {};

const mutations = {
  SET_BANK_ACCOUNTS(state, data) {
    state.bankAccounts = data;
  },

  SET_BANK_ACCOUNT_FILTER_PARAMS(state, params) {
    state.bankAccountFilterParams = params;
  },

  SET_EDITED_BANK_ACCOUNT(state, bankAccount) {
    state.bankAccountValidationErrors = {};
    state.editedBankAccount = JSON.parse(JSON.stringify(bankAccount));
  },

  SET_NEW_BANK_ACCOUNT(state, bankAccount) {
    state.newBankAccount = bankAccount;
  },

  STORE_BANK_ACCOUNT(state, bankAccount) {
    state.bankAccounts.push(bankAccount);
    state.bankAccountValidationErrors = {};
    state.newBankAccount = getDefaultClientBankAccountFormItem();
  },

  UPDATE_BANK_ACCOUNT(state, bankAccount) {
    state.bankAccounts = updateArrayItem(state.bankAccounts, bankAccount);
  },

  DELETE_BANK_ACCOUNT(state, bankAccount) {
    state.bankAccounts = removeArrayItem(state.bankAccounts, bankAccount);
  },

  SET_BANK_ACCOUNT_VALIDATION_ERRORS(state, bankAccountValidationErrors) {
    state.bankAccountValidationErrors = bankAccountValidationErrors;
  },

  CLEAR_BANK_ACCOUNT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.bankAccountValidationErrors, field);
  },
};

const actions = {
  fetchBankAccounts({ commit }, params) {
    commit('SET_BANK_ACCOUNT_FILTER_PARAMS', params);
    return bankAccountService.getAll(params).then((res) => {
      commit('SET_BANK_ACCOUNTS', res.data);
      return res.data;
    });
  },

  storeBankAccount({ commit }, bankAccount) {
    return bankAccountService
      .create(bankAccount)
      .then((res) => {
        commit('STORE_BANK_ACCOUNT', res.data);
        openSnackbar(i18n.t('finance_planner.bank_account_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_BANK_ACCOUNT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editBankAccount({ state, commit }, bankAccountId) {
    const bankAccount = state.bankAccounts.find((c) => c.id === bankAccountId);
    if (bankAccount) {
      commit('SET_EDITED_BANK_ACCOUNT', bankAccount);
      return Promise.resolve(bankAccount);
    }
    return bankAccountService.getById(bankAccountId).then((res) => {
      commit('SET_EDITED_BANK_ACCOUNT', res.data);
      return res.data;
    });
  },

  updateBankAccount({ commit }, bankAccount) {
    return bankAccountService
      .update(bankAccount)
      .then((res) => {
        commit('UPDATE_BANK_ACCOUNT', {
          ...bankAccount,
          ...res.data,
        });
        openSnackbar(i18n.t('finance_planner.bank_account_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_BANK_ACCOUNT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deleteBankAccount({ commit }, bankAccount) {
    openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      bankAccountService.delete(bankAccount).then(() => {
        commit('DELETE_BANK_ACCOUNT', bankAccount);
        openSnackbar(i18n.t('finance_planner.bank_account_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <BaseFilePreviewFormLayout
    :file="item.file"
    :title="formTitle"
    :disabled="disabled"
    @cancel="$emit('cancel')"
    @save="$emit('save', item)"
  >
    <v-row dense>
      <v-col v-if="!item.id" cols="12">
        <v-file-input
          v-model="item.file"
          :error-messages="errors['file']"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
          :accept="receivedInvoiceFileFormats"
          @change="formMixin_clearErrors('file')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.document_date"
          :error-messages="errors['document_date']"
          :label="$t('documents.labels.document_date')"
          @input="formMixin_clearErrors('document_date')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <ContactAutocomplete
          v-model="item.supplier_id"
          :error-messages="errors['supplier_id']"
          :initial-item="item.supplier"
          :label="formMixin_getRequiredFieldLabel($t('general.contact'))"
          suppliers-only
          show-create-button
          @input="formMixin_clearErrors('supplier_id')"
          @update:initial-item="item.supplier = $event"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="item.sub_total"
          :error-messages="errors.sub_total"
          :label="formMixin_getRequiredFieldLabel($t('general.sum'))"
          @input="formMixin_clearErrors('sub_total')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="item.vat"
          :error-messages="errors.vat"
          :label="formMixin_getRequiredFieldLabel($t('general.vat'))"
          @input="formMixin_clearErrors('vat')"
        />
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="item.description"
          :error-messages="errors.description"
          :label="$t('general.description')"
          rows="2"
          auto-grow
          @input="formMixin_clearErrors('description')"
        />
      </v-col>
    </v-row>
  </BaseFilePreviewFormLayout>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput.vue';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import { mapGetters } from 'vuex';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete.vue';
import BaseFilePreviewFormLayout from '@/components/base/BaseFilePreviewFormLayout.vue';

export default {
  name: 'ReceivedInvoiceForm',

  components: {
    BaseFilePreviewFormLayout,
    ContactAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    receivedInvoice: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapGetters('receivedInvoices', ['receivedInvoiceFileFormats']),

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.receivedInvoice.id ? 'edit' : 'new'}_received_invoice`
      );
    },
  },

  created() {
    const item = clone(this.receivedInvoice);
    if (item.id) {
      item.description = item.model.description;
      item.supplier_id = item.model.supplier_id;
      item.supplier = item.model.supplier;
      item.vat = item.model.vat;
      item.sub_total = item.model.sub_total;
      item.date = item.model.date;
    }
    this.item = item;
  },
};
</script>

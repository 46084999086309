// We used to use a service worker for caching assets that would come added by default when initializing a project using @vue/cli.
// Since it was only causing headaches regarding cache busting and worked only on home page anyway,
// I decided to remove it and rely on standard browser caching instead.
export async function unregisterServiceWorkers() {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let i = 0; i < registrations.length; i++) {
      registrations[i].unregister();
      console.log('Successfully unregistered service worker');
    }
  } catch (e) {
    // User's probably in an environment that doesn't support service workers like Safari webview
    // in which case there's nothing to unregister
  }
}

<template>
  <v-dialog v-model="isOpen" max-width="400" persistent>
    <v-card>
      <v-card-title class="normal-word-break">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click.native="onCancel">
          {{ cancelText }}
        </v-btn>
        <v-btn color="primary" ref="confirmBtn" text @click.native="onConfirm">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../util/event-bus';

export default {
  name: 'ConfirmDialog',

  data() {
    return {
      isOpen: false,
      title: '',
      body: '',
      cancelText: '',
      confirmText: '',
    };
  },

  created() {
    eventBus.$on(OPEN_CONFIRM_DIALOG, async (options) => {
      this.title = typeof options.title === 'string' ? options.title : this.$t('are_you_sure');
      this.body = typeof options.body === 'string' ? options.body : '';
      this.cancelText =
        typeof options.cancelText === 'string'
          ? options.cancelText
          : this.$t('general.controls.cancel');
      this.confirmText =
        typeof options.confirmText === 'string'
          ? options.confirmText
          : this.$t('general.controls.confirm');
      this.isOpen = true;
      setTimeout(() => {
        this.$refs.confirmBtn.$el.focus();
      });
    });
  },

  methods: {
    onConfirm() {
      eventBus.$emit(CONFIRM_DIALOG_CLOSED, true);
      this.isOpen = false;
    },

    onCancel() {
      eventBus.$emit(CONFIRM_DIALOG_CLOSED, false);
      this.isOpen = false;
    },
  },
};
</script>

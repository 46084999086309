import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format } from 'date-fns';

export const getDefaultSupplierAgreementFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultSupplierAgreementFilterParams = () => ({});

const state = {
  supplierAgreements: [],
  supplierAgreementPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedSupplierAgreement: {},
  newSupplierAgreement: getDefaultSupplierAgreementFormItem(),
  supplierAgreementValidationErrors: {},
  supplierAgreementFilterParams: getDefaultSupplierAgreementFilterParams(),
  downloadingSupplierAgreements: false, // booleans instead of $store.getter.loading because both loading and downloading use the same route
  loadingSupplierAgreements: false,
};

const getters = {
  supplierAgreementFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  },
};

const mutations = {
  SET_SUPPLIER_AGREEMENTS(state, { data, current_page, per_page, total }) {
    state.supplierAgreements = data;
    state.supplierAgreementPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_SUPPLIER_AGREEMENT_FILTER_PARAMS(state, params) {
    state.supplierAgreementFilterParams = params;
  },

  SET_NEW_SUPPLIER_AGREEMENT(state, supplierAgreement) {
    state.newSupplierAgreement = supplierAgreement;
  },

  SET_EDITED_SUPPLIER_AGREEMENT(state, supplierAgreement) {
    state.supplierAgreementValidationErrors = {};
    state.editedSupplierAgreement = JSON.parse(JSON.stringify(supplierAgreement));
  },

  STORE_SUPPLIER_AGREEMENT(state, supplierAgreement) {
    state.supplierAgreements.unshift(supplierAgreement);
    state.supplierAgreementPagination.total += 1;
    state.supplierAgreementValidationErrors = {};
    state.newSupplierAgreement = getDefaultSupplierAgreementFormItem();
  },

  UPDATE_SUPPLIER_AGREEMENT(state, supplierAgreement) {
    state.supplierAgreements = updateArrayItem(state.supplierAgreements, supplierAgreement);
  },

  DELETE_SUPPLIER_AGREEMENT(state, supplierAgreement) {
    state.supplierAgreements = removeArrayItem(state.supplierAgreements, supplierAgreement);
    state.supplierAgreementPagination.total -= 1;
  },

  SET_SUPPLIER_AGREEMENT_VALIDATION_ERRORS(state, supplierAgreementValidationErrors) {
    state.supplierAgreementValidationErrors = supplierAgreementValidationErrors;
  },

  CLEAR_SUPPLIER_AGREEMENT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.supplierAgreementValidationErrors, field);
  },

  SET_DOWNLOADING_SUPPLIER_AGREEMENTS(state, value) {
    state.downloadingSupplierAgreements = value;
  },

  SET_LOADING_SUPPLIER_AGREEMENTS(state, value) {
    state.loadingSupplierAgreements = value;
  },
};

const actions = {
  fetchSupplierAgreements({ commit }, params) {
    commit('SET_SUPPLIER_AGREEMENT_FILTER_PARAMS', params);
    commit('SET_LOADING_SUPPLIER_AGREEMENTS', true);
    return documentService
      .getPage(params, 'supplier_agreement')
      .then((res) => {
        commit('SET_SUPPLIER_AGREEMENTS', res.data);
        return res.data;
      })
      .finally(() => {
        commit('SET_LOADING_SUPPLIER_AGREEMENTS', false);
      });
  },

  async downloadSupplierAgreements({ state, commit }) {
    commit('SET_DOWNLOADING_SUPPLIER_AGREEMENTS', true);
    const { data } = await documentService.downloadAll(
      state.supplierAgreementFilterParams,
      'supplier_agreement'
    );
    commit('SET_DOWNLOADING_SUPPLIER_AGREEMENTS', false);
    downloadFile(data, `${i18n.t('general.supplier_agreements')}.zip`);
  },

  storeSupplierAgreement({ commit }, supplierAgreement) {
    return documentService
      .create({ ...supplierAgreement, document_type: 'supplier_agreement' })
      .then((res) => {
        commit('STORE_SUPPLIER_AGREEMENT', res.data);
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_SUPPLIER_AGREEMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editSupplierAgreement({ state, commit }, supplierAgreementId) {
    const supplierAgreement = state.supplierAgreements?.find((c) => c.id === supplierAgreementId);
    if (supplierAgreement) {
      commit('SET_EDITED_SUPPLIER_AGREEMENT', supplierAgreement);
      return Promise.resolve(supplierAgreement);
    }
    return documentService.getById(supplierAgreementId).then((res) => {
      commit('SET_EDITED_SUPPLIER_AGREEMENT', res.data);
      return res.data;
    });
  },

  updateSupplierAgreement({ commit }, supplierAgreement) {
    return documentService
      .update(supplierAgreement)
      .then((res) => {
        commit('UPDATE_SUPPLIER_AGREEMENT', res.data);
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_SUPPLIER_AGREEMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async toggleSeenSupplierAgreement({ commit }, supplierAgreement) {
    try {
      commit('UPDATE_SUPPLIER_AGREEMENT', {
        ...supplierAgreement,
        seen: supplierAgreement.seen ? null : {},
      });
      await documentService.toggleSeen(supplierAgreement);
    } catch (e) {
      commit('UPDATE_SUPPLIER_AGREEMENT', supplierAgreement);
    }
  },

  async toggleSupplierAgreementSignature({ commit }, updatedDocument) {
    try {
      commit('UPDATE_SUPPLIER_AGREEMENT', updatedDocument);
      await documentService.update(updatedDocument);
    } catch (e) {
      commit('UPDATE_SUPPLIER_AGREEMENT', {
        ...updatedDocument,
        is_signed_by_all_signees: !updatedDocument.is_signed_by_all_signees,
      });
    }
  },

  async uploadNewSupplierAgreementFile({ commit }, { supplierAgreement, newFile }) {
    try {
      const { data } = await documentService.uploadNewFile(supplierAgreement, newFile);
      commit('UPDATE_SUPPLIER_AGREEMENT', data);
      openSnackbar(i18n.t('documents.messages.file_changed'));
    } catch (e) {
      openSnackbar(i18n.t('documents.messages.file_upload_failed'));
    }
  },

  deleteSupplierAgreement({ commit }, supplierAgreement) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(supplierAgreement).then(() => {
        commit('DELETE_SUPPLIER_AGREEMENT', supplierAgreement);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { permissionsGuard } from '@/router/guards';

export const receivedInvoiceRoutes = [
  {
    path: 'documents/received-invoices',
    name: 'receivedInvoices',
    component: () =>
      import(
        /* webpackChunkName: "receivedInvoices" */ '@/views/received-invoices/ReceivedInvoices.vue'
      ),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'documents',
      requiredPermissions: ['documents.received_invoice.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createReceivedInvoice',
        component: () =>
          import(
            /* webpackChunkName: "createReceivedInvoice" */ '@/views/received-invoices/CreateReceivedInvoice.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.received_invoice.view.*'],
        },
      },
      {
        path: ':id/edit',
        name: 'editReceivedInvoice',
        component: () =>
          import(
            /* webpackChunkName: "editReceivedInvoice" */ '@/views/received-invoices/EditReceivedInvoice.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.received_invoice.view.*'],
        },
      },
    ],
  },
];

import { permissionsGuard } from '@/router/guards';

export const internalDocumentRoutes = [
  {
    path: 'documents/internal-documents',
    name: 'internalDocuments',
    meta: { menuGroup: 'documents', requiredPermissions: ['documents.internal.view.*'] },
    component: () =>
      import(
        /* webpackChunkName: "internalDocuments" */ '@/views/internal-documents/InternalDocuments.vue'
      ),
    beforeEnter: permissionsGuard,
    children: [
      {
        path: 'create',
        name: 'createInternalDocument',
        component: () =>
          import(
            /* webpackChunkName: "createInternalDocument" */ '@/views/internal-documents/CreateInternalDocument.vue'
          ),
        meta: { menuGroup: 'documents', requiredPermissions: ['documents.internal.create'] },
      },
      {
        path: ':id/edit',
        name: 'editInternalDocument',
        component: () =>
          import(
            /* webpackChunkName: "editInternalDocument" */ '@/views/internal-documents/EditInternalDocument.vue'
          ),
        meta: { menuGroup: 'documents', requiredPermissions: ['documents.internal.create'] },
      },
    ],
  },
];

<template>
  <v-autocomplete
    :value="transformedValue"
    :search-input.sync="search"
    :error-messages="errorMessages"
    :loading="isDataLoading"
    :disabled="disabled"
    :hint="hintText"
    :label="label"
    :item-value="itemValue"
    :item-text="itemText"
    :items="items"
    :clearable="clearable"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :hide-no-data="hideNoData"
    :no-filter="noFilter"
    hide-selected
    @blur="$emit('blur', $event)"
    @click:append="$emit('click:append')"
    @click:clear="clearInput"
    @input="onInput"
    @keyup="onKeyUp"
  >
    <template v-if="useSelectionSlot" v-slot:selection="{ item }">
      <slot name="selection" :item="item"></slot>
    </template>
    <template v-if="useItemSlot" v-slot:item="{ item }">
      <slot name="item" :item="item"></slot>
    </template>
    <template v-if="useAppend" v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
// Stores selected values to be loaded back up when navigating back to component's page.
// Reason why it made sense to me - this data is not needed anywhere else in the app
// and it does not need to be reactive. The only downside being that all usages of this
// component will have access to the same object.
// So to solve that they are prefixed with 'label' prop's value.
// Might not seem logical, but I really didn't want to introduce another prop
// and label seems to work just fine.
// You are not going to label two different models with the same text (hopefully).
const localValueCache = {};

export default {
  name: 'BaseAutocomplete',

  props: {
    // Model (e.g. user) id.
    // Type 'String' is specified for when the ID is taken from URL params
    value: {
      type: [Number, String, null],
      default: () => null,
    },

    // Function called with the value that user has typed into the input.
    // Typically an API call
    searchFunction: {
      type: Function,
      required: true,
    },

    // Use if the object for the bound value (id) is present.
    // If it's not - BaseSearch will attempt to fetch it with getByIdFunction.
    // Needs to be present if getByIdFunction is missing but value is set.
    initialItem: {
      type: Object,
      default: () => null,
    },

    // Function called when initial value is not present, to fetch the related object.
    // Typically an API call.
    // Needs to be present if initialItem is missing but value is set.
    getByIdFunction: {
      type: Function,
      default: null,
    },

    label: {
      type: String,
      required: true,
    },

    hint: {
      type: String,
      default: '',
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    itemText: {
      type: String,
      default: 'name',
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    appendIcon: {
      type: String,
      default: '',
    },

    useSelectionSlot: {
      type: Boolean,
      default: false,
    },

    useItemSlot: {
      type: Boolean,
      default: false,
    },

    useAppend: {
      type: Boolean,
      default: false,
    },

    prependIcon: {
      type: String,
      default: 'search',
    },

    // How long will the component wait for an additional keypress after the last one
    // before calling searchFunction
    debounceTime: {
      type: Number,
      default: 600,
    },

    noFilter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      items: [],
      isDataLoading: false,
      search: '',
      timerId: null,
      hideNoData: true,
    };
  },

  computed: {
    transformedValue() {
      // when taking ID field from URL it comes as a string
      return this.value ? +this.value : null;
    },

    hintText() {
      if (this.hint) {
        return this.hint;
      }

      return !this.search || this.search.length < 3
        ? this.$t('general.enter_three_or_more_symbols')
        : '';
    },
  },

  watch: {
    initialItem(newValue) {
      if (!newValue || !newValue[this.itemValue]) {
        return;
      }
      for (let i = 0; i < this.items.length; i++) {
        if (newValue[this.itemValue] === this.items[i][this.itemValue]) {
          return;
        }
      }
      this.items.push(newValue);
    },
  },

  async created() {
    // Selected value must have a corresponding object inside items array
    if (this.transformedValue && this.initialItem) {
      // If initial item prop is set - let's use that
      this.items.push(this.initialItem);
    } else if (this.transformedValue && localValueCache[`${this.label}_${this.transformedValue}`]) {
      // Otherwise - check if the value is present in local component cache.
      const item = localValueCache[`${this.label}_${this.transformedValue}`];
      this.items.push(item);
      this.$emit('update:initial-item', item);
    } else if (this.transformedValue && !this.initialItem && this.getByIdFunction) {
      // Otherwise - fetch the value from API.
      this.isDataLoading = true;
      const { data } = await this.getByIdFunction(this.transformedValue);
      this.isDataLoading = false;
      this.items.push(data);
      this.$emit('update:initial-item', data);
      localValueCache[`${this.label}_${this.transformedValue}`] = data;
    }
  },

  methods: {
    clearInput() {
      this.hideNoData = true;
      this.items = [];
    },

    onKeyUp(event) {
      if (event.key === 'Enter') {
        return;
      }
      clearTimeout(this.timerId);
      if (!this.search) {
        this.onInput(null);
        this.clearInput();
        return;
      }
      if (this.search.length < 3) {
        return;
      }
      this.timerId = setTimeout(() => {
        this.getItems(this.search);
      }, this.debounceTime);
    },

    onInput(val) {
      let selectedItem = null;
      this.hideNoData = true;
      if (val) {
        selectedItem = this.items.find((item) => item.id === val);
      }
      setTimeout(() => {
        // ensures that backend is not being queried with value that was selected
        clearTimeout(this.timerId);
      });
      this.$emit('input', val || null);
      this.$emit('update:initial-item', selectedItem);
      if (!this.initialItem && this.label && val) {
        localValueCache[`${this.label}_${val}`] = selectedItem;
      }
    },

    async getItems(v) {
      this.isDataLoading = true;
      try {
        const res = await this.searchFunction(v);
        this.items = res.data.data ? res.data.data : res.data;
        this.hideNoData = false;
      } catch (e) {
        this.items = [];
        throw e;
      }
      this.isDataLoading = false;
    },
  },
};
</script>

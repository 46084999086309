const state = {
  documents: [],
};

const getters = {
  documents(state) {
    return state.documents;
  },
};

const mutations = {
  storeDocuments(state, documents) {
    state.documents = documents;
  },

  addDocuments(state, documents) {
    state.documents = documents.concat(state.documents);
  },

  storeDocument(state, document) {
    state.documents.unshift(document);
  },

  updateDocument(state, payload) {
    state.documents.splice(payload.index, 1, payload.item);
  },

  deleteDocument(state, index) {
    state.documents.splice(index, 1);
  },
};

const actions = {
  storeDocuments({ commit }, documents) {
    commit('storeDocuments', documents);
  },

  addDocuments({ commit }, documents) {
    commit('addDocuments', documents);
  },

  storeDocument({ commit }, document) {
    commit('storeDocument', document);
  },

  updateDocument({ commit }, payload) {
    commit('updateDocument', payload);
  },

  deleteDocument({ commit }, index) {
    commit('deleteDocument', index);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <SentInvoiceForm
      :sent-invoice="newSentInvoice"
      :errors="sentInvoiceValidationErrors"
      :disabled="$store.getters.loading['post:api/documents']"
      @clear:errors="CLEAR_SENT_INVOICE_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SentInvoiceForm from '@/components/forms/SentInvoiceForm.vue';
import { returnFromChildView } from '@/util/routing';

export default {
  name: 'CreateSentInvoice',

  components: { SentInvoiceForm },

  computed: {
    ...mapState('sentInvoices', [
      'newSentInvoice',
      'sentInvoiceValidationErrors',
      'sentInvoiceFilterParams',
    ]),
  },

  created() {
    this.SET_SENT_INVOICE_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('sentInvoices', ['storeSentInvoice']),
    ...mapMutations('sentInvoices', [
      'SET_SENT_INVOICE_VALIDATION_ERRORS',
      'CLEAR_SENT_INVOICE_VALIDATION_ERRORS',
    ]),

    onSave(sentInvoice) {
      this.storeSentInvoice(sentInvoice).then(() => {
        this.goBack();
      });
    },

    goBack() {
      returnFromChildView(this.$route, 'uploading-document', {
        name: 'sentInvoices',
        query: this.sentInvoiceFilterParams,
      });
    },
  },
};
</script>

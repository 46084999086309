import { permissionsGuard } from '@/router/guards';

export const commercialOfferRoutes = [
  {
    path: 'documents/commercial-offers',
    name: 'commercialOffers',
    component: () =>
      import(
        /* webpackChunkName: "commercialOffers" */ '@/views/commercial-offers/CommercialOffers.vue'
      ),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'documents',
      requiredPermissions: ['documents.commercial_offer.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createCommercialOffer',
        component: () =>
          import(
            /* webpackChunkName: "createCommercialOffer" */ '@/views/commercial-offers/CreateCommercialOffer.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.commercial_offer.view.*'],
        },
      },
      {
        path: ':id/edit',
        name: 'editCommercialOffer',
        component: () =>
          import(
            /* webpackChunkName: "editCommercialOffer" */ '@/views/commercial-offers/EditCommercialOffer.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.commercial_offer.view.*'],
        },
      },
      {
        path: 'create-pdf',
        name: 'createCommercialOfferPdf',
        component: () =>
          import(
            /* webpackChunkName: "createCommercialOfferPdf" */ '@/views/commercial-offers/CreateCommercialOfferPdf.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.commercial_offer.view.*'],
        },
      },
    ],
  },
];

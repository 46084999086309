import infrastructureService from '@/api/infrastructure-service';

export const getDefaultInfrastructureHealthFilterParams = () => ({});

const state = {
  infrastructureHealth: [],
  infrastructureHealthFilterParams: getDefaultInfrastructureHealthFilterParams(),
};

const getters = {};

const mutations = {
  SET_INFRASTRUCTURE_HEALTH(state, data) {
    state.infrastructureHealth = data;
  },

  SET_INFRASTRUCTURE_HEALTH_FILTER_PARAMS(state, params) {
    state.bankAccountFilterParams = params;
  },
};

const actions = {
  fetchInfrastructureHealth({ commit }, params) {
    commit('SET_INFRASTRUCTURE_HEALTH_FILTER_PARAMS', params);
    return infrastructureService.getHealth(params).then((res) => {
      const formattedArray = Object.values(res.data);
      commit('SET_INFRASTRUCTURE_HEALTH', formattedArray);
      return formattedArray;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

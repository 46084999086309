import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import VueQuillEditor from 'vue-quill-editor';
import moment from 'moment';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'simplebar/dist/simplebar.min.css';
import eventBus from './util/event-bus';
import App from './App.vue';
import NoData from './legacy-components/NoData.vue';
import PrimaryActionButton from './legacy-components/PrimaryActionButton.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n/i18n-config';
import round from './util/numbers';
import { formatDistanceToNow, subSeconds } from 'date-fns';
import { lt } from 'date-fns/locale';
import httpClient from '@/api/http-client';
import { unregisterServiceWorkers } from '@/util/unregister-service-workers';

Vue.config.productionTip = false;
Vue.use(VueQuillEditor);
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$http = httpClient;

Vue.prototype.$isAdmin = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'admin';
  }
  return false;
};

Vue.prototype.$isEmployee = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'employee';
  }
  return false;
};

Vue.prototype.$isClient = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'client';
  }
  return false;
};

Vue.prototype.$isCurrentUser = (id) => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].id === id;
  }
  return false;
};

Vue.prototype.$frontendUrl = (path) => {
  return `/${path}`; // should be import.meta.env.VITE_APP_PUBLIC_URL instead of /
};

Vue.prototype.$can = (permissions) => {
  if (!Vue.prototype.$isAdmin()) {
    let isAllowed = false;
    if (permissions && store.getters['auth/permissions']) {
      for (let i = 0; i < permissions.length; i++) {
        if (store.getters['auth/permissions'][permissions[i]]) {
          isAllowed = true;
          break;
        }
      }
    }
    return isAllowed;
  }
  return true;
};

// alias used in newer projects
Vue.prototype.$hasPermission = Vue.prototype.$can;

Vue.prototype.$openFile = (attachment, nameField) => {
  if (!attachment.url) {
    return;
  }
  let fileName;
  if (nameField) {
    fileName = nameField;
  } else {
    fileName = 'file_name';
  }
  httpClient({
    url: attachment.url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const fileURL = URL.createObjectURL(response.data);
    const windowRef = window.open(fileURL, attachment[fileName]);
    setTimeout(() => {
      if (!windowRef || (windowRef && !windowRef.self)) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment[fileName]);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    }, 500);
  });
};

Vue.prototype.$openFileAlt = (url, name) => {
  httpClient({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const fileURL = URL.createObjectURL(response.data);
    const windowRef = window.open(fileURL, name);
    setTimeout(() => {
      if (!windowRef || (windowRef && !windowRef.self)) {
        const href = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    }, 500);
  });
};

Vue.prototype.$moment = moment;

Vue.prototype.$timeFromDate = (date) => {
  if (!date) {
    return '';
  }
  // return moment.utc(date).subtract(10, 'seconds').local().fromNow();
  const options = {
    addSuffix: true,
  };
  if (i18n.locale === 'lt') {
    options.locale = lt;
  }
  return formatDistanceToNow(subSeconds(new Date(date), 10), options);
};

Vue.filter('currency', (value) => {
  let str = '-';
  if (+value >= 0) {
    str = `€${round(+value, 2).toFixed(2)}`;
  }
  return str;
});

Vue.filter('percentage', (value) => (+value >= 0 ? `${round(+value, 2).toFixed(2)}%` : '-'));

Vue.filter('truncate', (value, length) =>
  value && value.length > length ? `${value.slice(0, length)}...` : value
);

Vue.component('no-data', NoData);

Vue.component('primary-action-button', PrimaryActionButton);

if (
  import.meta.env.VITE_APP_SENTRY_DSN &&
  import.meta.env.VITE_APP_SENTRY_DSN !== 'VITE_APP_SENTRY_DSN_PLACEHOLDER' // default value in env template
) {
  Sentry.init({
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    Vue,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      'Unauthenticated',
      'NavigationDuplicated',
      'Non-Error promise rejection',
      'Network Error',
      'Request aborted',
      'timeout of 0ms exceeded',
      'Refresh token not found',
      /Redirected when going from/,
      /401/,
      /422/,
      /500/,
      /Navigation cancelled from/,

      // These occur when a new version was pushed but the user didn't click refresh on the UpdateNotifier
      /error loading dynamically imported module/,
      /Failed to fetch dynamically imported module/,
    ],
  });
}

unregisterServiceWorkers();

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

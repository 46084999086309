<template>
  <v-menu
    v-model="isMenuOpen"
    :class="{ 'mr-1': $vuetify.breakpoint.mdAndUp }"
    :close-on-content-click="false"
    :disabled="!isCountBadgeDisplayed"
    left
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-badge
          :value="isCountBadgeDisplayed && activeItemCount > 0"
          :content="activeItemCountLabel"
          color="error"
          overlap
        >
          <v-icon>notifications</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card class="notifications-card">
      <v-card-title class="py-2">
        {{ $t('general.notifications') }}
        <v-spacer />
        <v-tooltip v-if="notifications.length" bottom open-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="isMarkAllAsReadPending"
              :loading="isMarkAllAsReadPending"
              icon
              @click="markAllAsSeen"
            >
              <v-icon>done_all</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t('notifications.mark_all_as_read') }}
          </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list max-height="400" class="overflow-y-auto">
          <v-list-item v-if="!notifications.length && !reminders.length">
            <v-list-item-title class="grey--text">
              {{ $t('general.no_notifications') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-for="reminder in reminders"
            :key="reminder.key"
            :inactive="!reminder.route"
            :ripple="!!reminder.route"
            @click="onNotificationClick(reminder)"
          >
            <v-list-item-icon>
              <v-icon>
                {{ reminder.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-body-2 preserve-whitespace">{{
              reminder.title
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-for="notification in notifications"
            :key="notification.id"
            :ripple="false"
            @click="onNotificationClick(notification)"
          >
            <v-list-item-icon>
              <v-icon>
                {{ notification.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-body-2 preserve-whitespace py-1">{{
              notification.title
            }}</v-list-item-title>
            <v-list-item-action class="my-0">
              <v-tooltip open-delay="500" bottom>
                <template #activator="{ on }">
                  <v-btn v-on="on" icon @click.stop="updateNotificationSeenStatus(notification)">
                    <v-icon :color="notification.is_seen ? '' : 'error'" small>
                      {{ notification.is_seen ? 'panorama_fish_eye' : 'lens' }}
                    </v-icon>
                  </v-btn>
                </template>
                <div>
                  {{ $t(`notifications.mark_as_${notification.is_seen ? 'unread' : 'read'}`) }}
                </div>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item v-if="hasMorePages">
            <v-btn
              :disabled="isNextPageLoading"
              :loading="isNextPageLoading"
              color="primary"
              block
              text
              @click="fetchNextPage"
            >
              {{ $t('general.show_more') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getUTCDate } from '@/util/dates';
import { format } from 'date-fns';
import { isExternalUrl } from '@/util/url';

export default {
  name: 'Notifications',

  data() {
    return {
      isCountBadgeDisplayed: false,
      isNextPageLoading: false,
      isMarkAllAsReadPending: false,
      isMenuOpen: false,
      lastFetchTime: '',
      intervalId: '',
    };
  },

  computed: {
    ...mapState('notifications', ['reminders', 'notifications']),
    ...mapGetters('notifications', ['activeNotifications', 'notificationPagination']),

    hasMorePages() {
      const { page, rowsPerPage, total } = this.notificationPagination;
      return page * rowsPerPage < total;
    },

    activeItemCount() {
      return this.reminders.length + this.activeNotifications.length;
    },

    activeItemCountLabel() {
      if (this.activeItemCount > 9) {
        return '9+';
      }
      return this.activeItemCount.toString();
    },
  },

  created() {
    const promises = [this.getNotifications()];
    if (this.$isEmployee() || this.$isAdmin()) {
      promises.push(this.setupMyHealthAndSafetyReminders());
      promises.push(this.getLastEntries());
      promises.push(this.setupEmployeeAbsenceEvents());
    }
    if (this.$isAdmin()) {
      promises.push(this.setupAdminOverdueHealthAndSafetyReminders());
    }

    Promise.all(promises).finally(() => {
      this.isCountBadgeDisplayed = true;
    });

    this.setLastFetchTime();
    this.intervalId = setInterval(() => {
      this.getNotifications({
        date_from: this.lastFetchTime,
      });
      this.setLastFetchTime();
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_HIGHLIGHTED_USER_STORY_ID']),
    ...mapActions('notifications', [
      'getNotifications',
      'getLastEntries',
      'setupMyHealthAndSafetyReminders',
      'setupAdminOverdueHealthAndSafetyReminders',
      'updateAllNotificationsAsSeen',
      'updateNotificationSeenStatus',
      'setupEmployeeAbsenceEvents',
    ]),

    setLastFetchTime() {
      this.lastFetchTime = format(getUTCDate(new Date()), 'yyyy-MM-dd HH:mm');
    },

    fetchNextPage() {
      this.isNextPageLoading = true;
      const nextPage = this.notificationPagination.page + 1;
      this.getNotifications({ page: nextPage }).finally(() => {
        this.isNextPageLoading = false;
      });
    },

    onNotificationClick(notification) {
      if (!notification.is_seen && notification.id) {
        this.updateNotificationSeenStatus(notification);
      }
      if (notification.model === 'project_user_story') {
        this.SET_HIGHLIGHTED_USER_STORY_ID(notification.model_column_id);
      }
      if (
        notification.model === 'employee_absence_history_event' &&
        notification.action !== 'employee_absence_history_event_denied' &&
        notification.action !== 'employee_absence_history_event_substitution_request_declined'
      ) {
        this.$router.push({
          name: 'viewEmployeeVacationApplication',
          params: { applicationId: notification.model_column_id },
        });
        this.isMenuOpen = false;
      }
      if (!notification.route) {
        return;
      }
      if (isExternalUrl(notification.route)) {
        window.location.href = notification.route;
      } else {
        this.$router.push(notification.route);
        this.isMenuOpen = false;
      }
    },

    markAllAsSeen() {
      this.isMarkAllAsReadPending = true;
      this.updateAllNotificationsAsSeen().finally(() => {
        this.isMarkAllAsReadPending = false;
      });
    },
  },
};
</script>

<style scoped>
.notifications-card {
  width: 414px;
}
</style>

export function getReactPortalUrl(path) {
  return `${import.meta.env.VITE_APP_REACT_PORTAL_URL}${path}`;
}

export function getReactClientPortalUrl(path) {
  return `${import.meta.env.VITE_APP_REACT_CLIENT_PORTAL_URL}${path}`;
}

export function openReactPortal(path) {
  window.location.href = getReactPortalUrl(path);
}

export function openReactClientsPortal(path) {
  window.location.href = getReactClientPortalUrl(path);
}

<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <SupplierAgreementForm
      :supplier-agreement="newSupplierAgreement"
      :errors="supplierAgreementValidationErrors"
      :disabled="$store.getters.loading['post:api/documents']"
      @clear:errors="CLEAR_SUPPLIER_AGREEMENT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SupplierAgreementForm from '@/components/forms/SupplierAgreementForm.vue';
import { returnFromChildView } from '@/util/routing';

export default {
  name: 'CreateSupplierAgreement',

  components: { SupplierAgreementForm },

  computed: {
    ...mapState('supplierAgreements', [
      'newSupplierAgreement',
      'supplierAgreementValidationErrors',
      'supplierAgreementFilterParams',
    ]),
  },

  created() {
    this.SET_SUPPLIER_AGREEMENT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('supplierAgreements', ['storeSupplierAgreement']),
    ...mapMutations('supplierAgreements', [
      'SET_SUPPLIER_AGREEMENT_VALIDATION_ERRORS',
      'CLEAR_SUPPLIER_AGREEMENT_VALIDATION_ERRORS',
    ]),

    onSave(supplierAgreement) {
      this.storeSupplierAgreement(supplierAgreement).then(() => {
        this.goBack();
      });
    },

    goBack() {
      returnFromChildView(this.$route, 'uploading-document', {
        name: 'supplierAgreements',
        query: this.supplierAgreementFilterParams,
      });
    },
  },
};
</script>

import { permissionsGuard } from '@/router/guards';

export const sentInvoiceRoutes = [
  {
    path: 'documents/sent-invoices',
    name: 'sentInvoices',
    component: () =>
      import(/* webpackChunkName: "sentInvoices" */ '@/views/sent-invoices/SentInvoices.vue'),
    beforeEnter: permissionsGuard,
    meta: { menuGroup: 'documents', requiredPermissions: ['documents.sent_invoice.view.*'] },
    // children: [
    //   {
    //     path: 'create',
    //     name: 'createSentInvoice',
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "createSentInvoice" */ '@/views/sent-invoices/CreateSentInvoice.vue'
    //       ),
    //     beforeEnter: permissionsGuard,
    //     meta: { menuGroup: 'documents', requiredPermissions: ['documents.sent_invoice.view.*'] },
    //   },
    //   {
    //     path: ':id/edit',
    //     name: 'editSentInvoice',
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "editSentInvoice" */ '@/views/sent-invoices/EditSentInvoice.vue'
    //       ),
    //     beforeEnter: permissionsGuard,
    //     meta: { menuGroup: 'documents', requiredPermissions: ['documents.sent_invoice.view.*'] },
    //   },
    // ],
  },
];

import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format } from 'date-fns';

export const getDefaultSentInvoiceFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultSentInvoiceFilterParams = () => ({});

const state = {
  sentInvoices: [],
  sentInvoicePagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedSentInvoice: {},
  newSentInvoice: getDefaultSentInvoiceFormItem(),
  sentInvoiceValidationErrors: {},
  sentInvoiceFilterParams: getDefaultSentInvoiceFilterParams(),
  downloadingSentInvoices: false, // booleans instead of $store.getter.loading because both loading and downloading use the same route
  loadingSentInvoices: false,
};

const getters = {
  sentInvoiceFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  },
};

const mutations = {
  SET_SENT_INVOICES(state, { data, current_page, per_page, total }) {
    state.sentInvoices = data;
    state.sentInvoicePagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_SENT_INVOICE_FILTER_PARAMS(state, params) {
    state.sentInvoiceFilterParams = params;
  },

  SET_NEW_SENT_INVOICE(state, sentInvoice) {
    state.newSentInvoice = sentInvoice;
  },

  SET_EDITED_SENT_INVOICE(state, sentInvoice) {
    state.sentInvoiceValidationErrors = {};
    state.editedSentInvoice = JSON.parse(JSON.stringify(sentInvoice));
  },

  STORE_SENT_INVOICE(state, sentInvoice) {
    state.sentInvoices.unshift(sentInvoice);
    state.sentInvoicePagination.total += 1;
    state.sentInvoiceValidationErrors = {};
    state.newSentInvoice = getDefaultSentInvoiceFormItem();
  },

  UPDATE_SENT_INVOICE(state, sentInvoice) {
    state.sentInvoices = updateArrayItem(state.sentInvoices, sentInvoice);
  },

  DELETE_SENT_INVOICE(state, sentInvoice) {
    state.sentInvoices = removeArrayItem(state.sentInvoices, sentInvoice);
    state.sentInvoicePagination.total -= 1;
  },

  SET_SENT_INVOICE_VALIDATION_ERRORS(state, sentInvoiceValidationErrors) {
    state.sentInvoiceValidationErrors = sentInvoiceValidationErrors;
  },

  CLEAR_SENT_INVOICE_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.sentInvoiceValidationErrors, field);
  },

  SET_DOWNLOADING_SENT_INVOICES(state, value) {
    state.downloadingSentInvoices = value;
  },

  SET_LOADING_SENT_INVOICES(state, value) {
    state.loadingSentInvoices = value;
  },
};

const actions = {
  fetchSentInvoices({ commit }, params) {
    commit('SET_SENT_INVOICE_FILTER_PARAMS', params);
    commit('SET_LOADING_SENT_INVOICES', true);
    return documentService
      .getPage(params, 'sent_invoice')
      .then((res) => {
        commit('SET_SENT_INVOICES', res.data);
        return res.data;
      })
      .finally(() => {
        commit('SET_LOADING_SENT_INVOICES', false);
      });
  },

  async downloadSentInvoices({ state, commit }) {
    commit('SET_DOWNLOADING_SENT_INVOICES', true);
    const { data } = await documentService.downloadAll(
      state.sentInvoiceFilterParams,
      'sent_invoice'
    );
    commit('SET_DOWNLOADING_SENT_INVOICES', false);
    downloadFile(data, `${i18n.t('general.sent_invoices')}.zip`);
  },

  storeSentInvoice({ commit }, sentInvoice) {
    return documentService
      .create({ ...sentInvoice, document_type: 'sent_invoice' })
      .then((res) => {
        commit('STORE_SENT_INVOICE', res.data);
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_SENT_INVOICE_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editSentInvoice({ state, commit }, sentInvoiceId) {
    const sentInvoice = state.sentInvoices?.find((c) => c.id === sentInvoiceId);
    if (sentInvoice) {
      commit('SET_EDITED_SENT_INVOICE', sentInvoice);
      return Promise.resolve(sentInvoice);
    }
    return documentService.getById(sentInvoiceId).then((res) => {
      commit('SET_EDITED_SENT_INVOICE', res.data);
      return res.data;
    });
  },

  updateSentInvoice({ commit }, sentInvoice) {
    return documentService
      .update(sentInvoice)
      .then((res) => {
        commit('UPDATE_SENT_INVOICE', res.data);
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_SENT_INVOICE_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async toggleSeenSentInvoice({ commit }, sentInvoice) {
    try {
      commit('UPDATE_SENT_INVOICE', {
        ...sentInvoice,
        seen: sentInvoice.seen ? null : {},
      });
      await documentService.toggleSeen(sentInvoice);
    } catch (e) {
      commit('UPDATE_SENT_INVOICE', sentInvoice);
    }
  },

  deleteSentInvoice({ commit }, sentInvoice) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(sentInvoice).then(() => {
        commit('DELETE_SENT_INVOICE', sentInvoice);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

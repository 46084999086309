<template>
  <a v-if="isExternal" :href="to" :target="target" :class="classes">
    <slot></slot>
  </a>
  <router-link
    v-else
    :to="to"
    :class="classes"
    :disabled="disabled"
    class="base-link"
    active-class="primary--text"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'BaseLink',

  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    classes: {
      type: [String, Object, Array],
      default: 'text-decoration-none',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isExternal() {
      if (typeof this.to === 'object') {
        return false;
      }
      return this.to.includes('://');
    },
  },
};
</script>

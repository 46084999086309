<template>
  <div class="d-flex align-center">
    <template v-if="question">
      <v-subheader>
        {{ $t('client_survey.general.your_opinion_matters') }}. {{ question }}
      </v-subheader>

      <template v-if="answerType === 'scale'">
        <v-chip
          v-for="(number, index) of scaleAnswerValues"
          :key="index"
          class="clickable"
          small
          @click="setAnswer(number)"
        >
          {{ number }}
        </v-chip>
      </template>

      <template v-else>
        <v-text-field
          v-model="answer"
          :label="$t('client_survey.general.answer')"
          class="pa-0"
          hide-details
          single-line
          @keyup.enter="submitReview"
        ></v-text-field>

        <v-tooltip v-if="answer" bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" icon class="mx-0" @click.stop="submitReview">
              <v-icon color="teal">send</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('client_survey.general.send_answer') }}</span>
        </v-tooltip>
      </template>
    </template>
    <v-slide-y-transition mode="out-in">
      <v-subheader v-if="noActiveQuestions">
        {{ $t('client_survey.general.thank_you') }}
      </v-subheader>
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  name: 'ClientSurveyQuestionnaire',

  data() {
    return {
      questionsRoute: 'api/clients/feedback-questions-not-answered',
      question: '',
      answerType: 'scale',
      answer: '',
      noActiveQuestions: false,
      scaleAnswerValues: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    };
  },

  created() {
    this.getQuestion();
  },

  methods: {
    getQuestion() {
      if (this.$store.getters['auth/currentUser'].role === 'client') {
        this.$http.put(this.questionsRoute, {}).then((response) => {
          if (response.data.question) {
            this.question = response.data.question;
            this.answerType = response.data.answer_type;
          }
        });
      }
    },

    setAnswer(number) {
      this.answer = number;
      this.submitReview();
    },

    submitReview() {
      this.$http
        .put(this.questionsRoute, {
          answer: this.answer,
        })
        .then((response) => {
          if (response.data.question) {
            this.answer = '';
            this.question = response.data.question;
            this.answerType = response.data.answer_type;
          } else {
            this.question = '';
            this.answer = '';
            this.noActiveQuestions = true;
            setTimeout(() => {
              this.noActiveQuestions = false;
            }, 5000);
          }
        });
    },
  },
};
</script>

<style scoped></style>

/* eslint-disable implicit-arrow-linebreak */
export const getUTCDate = (date = new Date()) =>
  new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

export const getLocalDateFromUTC = (utcDate = new Date()) =>
  new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);

// yyyy-MM-dd HH:mm does not work in Safari & IE11, this function fixes it by swapping '-' with '/'
export function sanitizeDateString(date) {
  if (typeof date === 'object') {
    return date;
  }
  if (!date.includes('T') || !date.includes('Z')) {
    return date.replace(/-/g, '/');
  }
  return date;
}

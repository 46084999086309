import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import lt from 'vuetify/lib/locale/lt';
import { getLocalStorageItem } from '@/util/local-storage';

Vue.use(Vuetify);

let settings = getLocalStorageItem('evo_app_settings');

if (settings) {
  settings = JSON.parse(settings);
}

const currentLang = settings?.locale || 'en';

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#FFFFFF',
        accent: '#1976D2',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        weekend: '#F6F6F6',
        today: '#00FFCE',
      },
      dark: {
        primary: '#ffb612',
        secondary: '#424242',
        accent: '#ffb612',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        weekend: '#363636',
        today: '#39582d',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { lt },
    current: currentLang,
  },
});

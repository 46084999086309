<template>
  <v-dialog
    :value="dialog"
    max-width="800px"
    content-class="overflow-hidden"
    scrollable
    @input="$emit('input', $event)"
  >
    <v-sheet dark>
      <div class="d-flex justify-space-between align-center flex-column flex-md-row pl-3 pr-1 py-1">
        <div class="text-h6 text-break">
          {{ name }}
        </div>

        <div class="d-flex">
          <v-tooltip content-class="tooltip-in-dialog" bottom>
            <template #activator="{ on }">
              <v-btn v-on="on" class="ml-1" icon @click="openInNewTab">
                <v-icon>open_in_new</v-icon>
              </v-btn>
            </template>
            <div>
              {{ $t('general.open_in_new_tab') }}
            </div>
          </v-tooltip>
          <v-btn icon @click="downloadFile">
            <v-icon>file_download</v-icon>
          </v-btn>

          <!-- Dokobit actions -->
          <template v-if="fileType === 'pdf' && !!documentId">
            <SignDocumentButton :document-id="documentId" icon />
            <CleanupDocumentSignersButton :document-id="documentId" icon />
            <SendDocumentSigningInvitationButton
              :document-id="documentId"
              :document-title="name"
              icon
            />
          </template>

          <v-btn icon @click="$emit('input', false)">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </div>

      <embed
        v-if="fileType === 'pdf'"
        type="application/pdf"
        :src="fileUrl"
        width="100%"
        height="100%"
      />
      <div v-else-if="fileType === 'video'">
        <video width="100%" controls>
          <source :src="fileUrl" type="video/mp4" />
        </video>
      </div>
      <div v-else class="text-center pa-3">
        <img :src="fileUrl" style="max-width: 100%" />
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import CleanupDocumentSignersButton from '@/components/dokobit-action-buttons/CleanupDocumentSignersButton.vue';
import SendDocumentSigningInvitationButton from '@/components/dokobit-action-buttons/SendDocumentSigningInvitationButton.vue';
import SignDocumentButton from '@/components/dokobit-action-buttons/SignDocumentButton.vue';

export default {
  name: 'BaseFileViewer',

  components: {
    SignDocumentButton,
    SendDocumentSigningInvitationButton,
    CleanupDocumentSignersButton,
  },

  model: {
    prop: 'dialog',
    event: 'input',
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    fileUrl: {
      type: String,
      required: true,
    },
    documentId: {
      type: Number,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fileType() {
      const name = this.name?.toLowerCase();
      if (name.includes('pdf')) {
        return 'pdf';
      }

      const videoFormats = ['mp4'];
      for (let i = 0; i < videoFormats.length; i++) {
        if (name.includes(videoFormats[i])) {
          return 'video';
        }
      }
      return 'image';
    },
  },

  methods: {
    downloadFile() {
      const link = document.createElement('a');
      link.href = this.fileUrl;
      link.setAttribute('download', this.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    openInNewTab() {
      const w = window.open(this.fileUrl, '_blank');
      w.document.title = this.name;
    },
  },
};
</script>

<style scoped>
embed {
  height: calc(90vh - 44px);
}
</style>

import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format } from 'date-fns';
import googleDocService from '@/api/google-doc-service';
import clone from 'just-clone';

export const getDefaultClientAgreementFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultClientAgreementGoogleDocFormItem = () => ({
  date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultClientAgreementFilterParams = () => ({});

const state = {
  clientAgreements: [],
  clientAgreementPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedClientAgreement: {},
  newClientAgreement: getDefaultClientAgreementFormItem(),
  newClientAgreementGoogleDoc: getDefaultClientAgreementGoogleDocFormItem(),
  clientAgreementValidationErrors: {},
  clientAgreementFilterParams: getDefaultClientAgreementFilterParams(),
  downloadingClientAgreements: false,
  loadingClientAgreements: false,
};

const getters = {
  clientAgreementTypes() {
    return [
      'new_project_contract',
      'new_project_sprint',
      'new_project_sprint_act',
      'existing_project_contract',
      'existing_project_sprint',
      'existing_project_sprint_act',
    ].map((t) => ({
      value: t,
      text: i18n.t(`documents.client_agreement_types.${t}`),
    }));
  },

  clientAgreementFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg, .adoc';
  },
};

const mutations = {
  SET_CLIENT_AGREEMENTS(state, { data, current_page, per_page, total }) {
    state.clientAgreements = data;
    state.clientAgreementPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_CLIENT_AGREEMENT_FILTER_PARAMS(state, params) {
    state.clientAgreementFilterParams = params;
  },

  SET_NEW_CLIENT_AGREEMENT(state, clientAgreement) {
    state.newClientAgreement = clientAgreement;
  },

  SET_NEW_CLIENT_AGREEMENT_GOOGLE_DOC(state, clientAgreement) {
    state.newClientAgreementGoogleDoc = clientAgreement;
  },

  SET_EDITED_CLIENT_AGREEMENT(state, clientAgreement) {
    const editedClientAgreement = clone(clientAgreement);
    editedClientAgreement.contract_date = editedClientAgreement.model.contract_date;
    editedClientAgreement.contract_no = editedClientAgreement.model.contract_no;
    state.clientAgreementValidationErrors = {};
    state.editedClientAgreement = JSON.parse(JSON.stringify(editedClientAgreement));
  },

  STORE_CLIENT_AGREEMENT(state, clientAgreement) {
    state.clientAgreements.unshift(clientAgreement);
    state.clientAgreementPagination.total += 1;
    state.clientAgreementValidationErrors = {};
    state.newClientAgreement = getDefaultClientAgreementFormItem();
    state.newClientAgreementGoogleDoc = getDefaultClientAgreementGoogleDocFormItem();
  },

  UPDATE_CLIENT_AGREEMENT(state, clientAgreement) {
    state.clientAgreements = updateArrayItem(state.clientAgreements, clientAgreement);
  },

  DELETE_CLIENT_AGREEMENT(state, clientAgreement) {
    state.clientAgreements = removeArrayItem(state.clientAgreements, clientAgreement);
    state.clientAgreementPagination.total -= 1;
  },

  SET_CLIENT_AGREEMENT_VALIDATION_ERRORS(state, clientAgreementValidationErrors) {
    state.clientAgreementValidationErrors = clientAgreementValidationErrors;
  },

  CLEAR_CLIENT_AGREEMENT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.clientAgreementValidationErrors, field);
  },

  SET_DOWNLOADING_CLIENT_AGREEMENTS(state, value) {
    state.downloadingClientAgreements = value;
  },

  SET_LOADING_CLIENT_AGREEMENTS(state, value) {
    state.loadingClientAgreements = value;
  },
};

const actions = {
  fetchClientAgreements({ commit }, params) {
    commit('SET_CLIENT_AGREEMENT_FILTER_PARAMS', params);
    commit('SET_LOADING_CLIENT_AGREEMENTS', true);
    return documentService
      .getPage(params, 'client_agreement')
      .then((res) => {
        commit('SET_CLIENT_AGREEMENTS', res.data);
        return res.data;
      })
      .finally(() => {
        commit('SET_LOADING_CLIENT_AGREEMENTS', false);
      });
  },

  async downloadClientAgreements({ state, commit }) {
    commit('SET_DOWNLOADING_CLIENT_AGREEMENTS', true);
    const { data } = await documentService.downloadAll(
      state.clientAgreementFilterParams,
      'client_agreement'
    );
    commit('SET_DOWNLOADING_CLIENT_AGREEMENTS', false);
    downloadFile(data, `${i18n.t('general.client_agreements')}.zip`);
  },

  storeClientAgreement({ commit }, clientAgreement) {
    return documentService
      .create({ ...clientAgreement, document_type: 'client_agreement' })
      .then((res) => {
        commit('STORE_CLIENT_AGREEMENT', res.data);
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_CLIENT_AGREEMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  storeClientAgreementGoogleDoc({ commit }, clientAgreement) {
    return googleDocService
      .generate(clientAgreement)
      .then((res) => {
        commit('STORE_CLIENT_AGREEMENT', res.data);
        openSnackbar(i18n.t('documents.messages.google_doc_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_CLIENT_AGREEMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editClientAgreement({ state, commit }, clientAgreementId) {
    const clientAgreement = state.clientAgreements?.find((c) => c.id === clientAgreementId);
    if (clientAgreement) {
      commit('SET_EDITED_CLIENT_AGREEMENT', clientAgreement);
      return Promise.resolve(clientAgreement);
    }
    return documentService.getById(clientAgreementId).then((res) => {
      commit('SET_EDITED_CLIENT_AGREEMENT', res.data);
      return res.data;
    });
  },

  updateClientAgreement({ commit }, clientAgreement) {
    return documentService
      .update(clientAgreement)
      .then((res) => {
        commit('UPDATE_CLIENT_AGREEMENT', res.data);
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_CLIENT_AGREEMENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async syncClientAgreementGoogleDoc({ commit }, clientAgreement) {
    const { data } = await googleDocService.storeDocument(clientAgreement);
    commit('UPDATE_CLIENT_AGREEMENT', data);
    openSnackbar(i18n.t('documents.messages.document_saved'));
  },

  async shareClientAgreementGoogleDoc(context, { clientAgreement, email }) {
    await googleDocService.shareDocument(clientAgreement, email);
    openSnackbar(i18n.t('documents.messages.document_sent'));
  },

  async toggleSeenClientAgreement({ commit }, clientAgreement) {
    try {
      commit('UPDATE_CLIENT_AGREEMENT', {
        ...clientAgreement,
        seen: clientAgreement.seen ? null : {},
      });
      await documentService.toggleSeen(clientAgreement);
    } catch (e) {
      commit('UPDATE_CLIENT_AGREEMENT', clientAgreement);
    }
  },

  async toggleClientAgreementSignature({ commit }, updatedDocument) {
    try {
      commit('UPDATE_CLIENT_AGREEMENT', updatedDocument);
      await documentService.update(updatedDocument);
    } catch (e) {
      commit('UPDATE_CLIENT_AGREEMENT', {
        ...updatedDocument,
        is_signed_by_all_signees: !updatedDocument.is_signed_by_all_signees,
      });
    }
  },

  async uploadNewClientAgreementFile({ commit }, { clientAgreement, newFile }) {
    try {
      const { data } = await documentService.uploadNewFile(clientAgreement, newFile);
      commit('UPDATE_CLIENT_AGREEMENT', data);
      openSnackbar(i18n.t('documents.messages.file_changed'));
    } catch (e) {
      openSnackbar(i18n.t('documents.messages.file_upload_failed'));
    }
  },

  deleteClientAgreement({ commit }, clientAgreement) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(clientAgreement).then(() => {
        commit('DELETE_CLIENT_AGREEMENT', clientAgreement);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

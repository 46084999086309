// for getting nested object properties by passing path as string.
// for example: getObjectProperty(invoice, 'client.name')
export function getObjectProperty(object, propertyName) {
  const parts = propertyName.split('.');
  let property = object || this;

  for (let i = 0; i < parts.length; i += 1) {
    if (property) {
      property = property[parts[i]];
    }
  }

  return property;
}

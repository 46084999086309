import httpClient from '@/api/http-client';

const endpoint = 'api/employees';

const employeeService = {
  getEmployees: (params) => httpClient.get(endpoint, { params }),

  search: (query) => httpClient.get(`${endpoint}/find`, { params: { query } }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getBirthdays: () => httpClient.get(`${endpoint}/birthdays`),

  getRequestedHoliday: (id) => httpClient.get(`${endpoint}/absence-history-event/${id}`),

  approveHoliday: (id, data) => httpClient.patch(`${endpoint}/absence-history/${id}/approve`, data),

  deleteHoliday: (id) => httpClient.delete(`${endpoint}/absence-history/${id}`),

  getAbsenceEvents: () => httpClient.get(`${endpoint}/absence-history/need-action-events`),
};

export default employeeService;

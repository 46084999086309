<template>
  <BaseAutocomplete
    :value="value"
    :search-function="searchFunction"
    :get-by-id-function="getContactById"
    :label="label"
    :initial-item="initialItem"
    :error-messages="errorMessages"
    :append-icon="showCreateButton && $isAdmin() ? 'add' : ''"
    :disabled="disabled"
    item-text="name"
    clearable
    use-item-slot
    @input="$emit('input', $event)"
    @update:initial-item="$emit('update:initial-item', $event)"
    @click:append="onAppendClick"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <span>{{ item.name }}</span>
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="!item.is_supplier && !item.is_lead && !item.is_erp_company"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.client') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.is_supplier"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.supplier') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.is_lead"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.lead') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.is_erp_company"
          class="text-body-2"
          style="font-size: 12px !important"
        >
          {{ $t('general.erp_company') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </BaseAutocomplete>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete.vue';
import contactService from '@/api/contact-service';
import i18n from '@/i18n/i18n-config';
import { getReactPortalUrl } from '@/util/react-portal-integration';

export default {
  name: 'ContactAutocomplete',

  components: { BaseAutocomplete },

  props: {
    value: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    initialItem: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: () => i18n.t('general.contact'),
    },
    suppliersOnly: {
      type: Boolean,
      default: false,
    },
    showCreateButton: {
      // don't forget to update initialItem by listening to @update:initial-item
      // if this prop is set to true
      // otherwise item displayed inside autocomplete field won't update
      type: Boolean,
      default: false,
    },
    queryParam: {
      // if multiple instances of this component are used in the same page,
      // with showCreateButton set to true,
      // specify different value for this prop,
      // otherwise multiple forms will be opened
      type: String,
      default: 'create-contact',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    searchFunction() {
      if (this.suppliersOnly) {
        return this.searchSuppliers;
      }

      return this.searchContacts;
    },
  },

  methods: {
    getContactById: contactService.getById,
    searchContacts: contactService.search,
    searchSuppliers: contactService.searchSuppliers,

    onAppendClick() {
      window.open(getReactPortalUrl('contacts/create'), '_blank');
    },
  },
};
</script>

<style scoped></style>

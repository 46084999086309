<template>
  <BaseFilePreviewFormLayout
    :file="item.file"
    :title="formTitle"
    :disabled="disabled"
    @cancel="$emit('cancel')"
    @save="$emit('save', item)"
  >
    <v-row dense>
      <v-col v-if="!item.id" cols="12">
        <v-file-input
          v-model="item.file"
          :error-messages="errors['file']"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
          :accept="commercialOfferFileFormats"
          @change="formMixin_clearErrors('file')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.document_date"
          :error-messages="errors['document_date']"
          :label="$t('documents.labels.document_date')"
          @input="formMixin_clearErrors('document_date')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <ContactAutocomplete
          v-model="item.client_id"
          :error-messages="errors['client_id']"
          :initial-item="item.client"
          :label="$t('general.contact')"
          show-create-button
          @input="formMixin_clearErrors('client_id')"
          @update:initial-item="item.client = $event"
        />
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="item.description"
          :error-messages="errors.description"
          :label="$t('general.description')"
          rows="2"
          auto-grow
          @input="formMixin_clearErrors('description')"
        />
      </v-col>
    </v-row>
  </BaseFilePreviewFormLayout>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput.vue';
import clone from 'just-clone';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete.vue';
import { mapGetters } from 'vuex';
import BaseFilePreviewFormLayout from '@/components/base/BaseFilePreviewFormLayout.vue';

export default {
  name: 'CommercialOfferForm',

  components: {
    BaseFilePreviewFormLayout,
    ContactAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    commercialOffer: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapGetters('commercialOffers', ['commercialOfferFileFormats']),

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.commercialOffer.id ? 'edit' : 'new'}_commercial_offer`
      );
    },
  },

  created() {
    const item = clone(this.commercialOffer);
    if (item.id) {
      item.description = item.model.description;
      item.client_id = item.model.client_id;
      item.client = item.model.client;
      item.pdf_about_current_system = item.model.pdf_about_current_system;
      item.pdf_architecture_and_technologies = item.model.pdf_architecture_and_technologies;
      item.pdf_date = item.model.pdf_date;
      item.pdf_duration_in_days = item.model.pdf_duration_in_days;
      item.pdf_goal = item.model.pdf_goal;
      item.pdf_planed_tasks = item.model.pdf_planed_tasks;
      item.pdf_price = item.model.pdf_price;
      item.pdf_project_name = item.model.pdf_project_name;
      item.pdf_third_party_web_services = item.model.pdf_third_party_web_services;
    }
    this.item = item;
  },
};
</script>

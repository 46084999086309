<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <CompanyRequestForm
      :company-request="newCompanyRequest"
      :errors="companyRequestValidationErrors"
      :disabled="$store.getters.loading['post:api/documents']"
      @clear:errors="CLEAR_COMPANY_REQUEST_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import CompanyRequestForm from '@/components/forms/CompanyRequestForm.vue';
import { returnFromChildView } from '@/util/routing';

export default {
  name: 'CreateCompanyRequest',

  components: { CompanyRequestForm },

  computed: {
    ...mapState('companyRequests', [
      'newCompanyRequest',
      'companyRequestValidationErrors',
      'companyRequestFilterParams',
    ]),
  },

  created() {
    this.SET_COMPANY_REQUEST_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('companyRequests', ['storeCompanyRequest']),
    ...mapMutations('companyRequests', [
      'SET_COMPANY_REQUEST_VALIDATION_ERRORS',
      'CLEAR_COMPANY_REQUEST_VALIDATION_ERRORS',
    ]),

    onSave(companyRequest) {
      this.storeCompanyRequest(companyRequest).then(() => {
        this.goBack();
      });
    },

    goBack() {
      returnFromChildView(this.$route, 'uploading-document', {
        name: 'companyRequests',
        query: this.companyRequestFilterParams,
      });
    },
  },
};
</script>

export function parseArrayQueryParam(params, key, itemType = 'number') {
  if (!params[key]) {
    return params;
  }
  const param = params[key];
  let array = typeof param === 'string' ? [param] : [...param];
  if (array && itemType === 'number') {
    array = array.map((s) => +s);
  }
  return { ...params, [key]: array };
}

export function isExternalUrl(route) {
  if (typeof route !== 'string') {
    return false;
  }

  return route.includes('://');
}

import { roleGuard } from '@/router/guards';
import store from '@/store';

export function getFinancePlannerRoute() {
  return {
    name: 'financePlanner',
    query: store.state.plannedFinanceFlows.plannedFinanceFlowFilterParams,
  };
}

export const financePlannerRoutes = [
  {
    path: '/finance-planner',
    name: 'financePlanner',
    component: () => import('@/views/finance-planner/FinancePlanner.vue'),
    beforeEnter: roleGuard,
    meta: {
      menuGroup: 'finances',
      allowedRoles: ['admin'],
    },
    children: [
      // bank account forms
      {
        path: 'bank-accounts/create',
        name: 'createBankAccount',
        component: () => import('@/views/finance-planner/bank-accounts/CreateBankAccount.vue'),
        beforeEnter: roleGuard,
        meta: {
          menuGroup: 'finances',
          allowedRoles: ['admin'],
          getBackRoute: getFinancePlannerRoute,
        },
      },
      {
        path: 'bank-accounts/:bankAccountId/edit',
        name: 'editBankAccount',
        component: () => import('@/views/finance-planner/bank-accounts/EditBankAccount.vue'),
        beforeEnter: roleGuard,
        meta: {
          menuGroup: 'finances',
          allowedRoles: ['admin'],
          getBackRoute: getFinancePlannerRoute,
        },
      },

      // finance flow forms
      {
        path: 'planned-flows/create',
        name: 'createPlannedFinanceFlow',
        component: () =>
          import('@/views/finance-planner/planned-finance-flows/CreatePlannedFinanceFlow.vue'),
        beforeEnter: roleGuard,
        meta: {
          menuGroup: 'finances',
          allowedRoles: ['admin'],
          getBackRoute: getFinancePlannerRoute,
        },
      },
      {
        path: 'planned-flows/:plannedFinanceFlowId/edit',
        name: 'editPlannedFinanceFlow',
        component: () =>
          import('@/views/finance-planner/planned-finance-flows/EditPlannedFinanceFlow.vue'),
        beforeEnter: roleGuard,
        meta: {
          menuGroup: 'finances',
          allowedRoles: ['admin'],
          getBackRoute: getFinancePlannerRoute,
        },
      },
    ],
  },
];

import Vue from 'vue';
import plannedFinanceFlowService from '@/api/planned-finance-flow-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { endOfYear, format, startOfYear } from 'date-fns';

export const getDefaultClientPlannedFinanceFlowFormItem = () => ({
  date: format(new Date(), 'yyyy-MM-dd'),
  is_credit: false,
  is_approved: true,
});

export const getDefaultPlannedFinanceFlowFilterParams = () => ({
  date_from: format(startOfYear(new Date()), 'yyyy-MM-dd'),
  date_to: format(endOfYear(new Date()), 'yyyy-MM-dd'),
  is_approved: 'all',
});

export function isPlannedFinanceFlowReadonly(financeFlow, recurring = false) {
  if (recurring) {
    // true when creating a new recurring flow
    // or editing an exiting one from the recurring non duplicated list
    return false;
  }
  const { id, read_only, recurring_step } = financeFlow;
  if (id && recurring_step) {
    // opened an existing recurring flow from all flows list
    return true;
  }
  return read_only;
}

const state = {
  plannedFinanceFlows: [],
  recurringNonDuplicatedFinanceFlows: [],
  editedPlannedFinanceFlow: {},
  newPlannedFinanceFlow: getDefaultClientPlannedFinanceFlowFormItem(),
  plannedFinanceFlowValidationErrors: {},
  plannedFinanceFlowFilterParams: getDefaultPlannedFinanceFlowFilterParams(),
};

const getters = {
  sortedPlannedFinanceFlows(state) {
    const { date_from, date_to, type, is_approved } = state.plannedFinanceFlowFilterParams;
    const flows = state.plannedFinanceFlows.filter((flow) => {
      if (date_from && flow.date < date_from) {
        return false;
      }

      if (date_to && flow.date > date_to) {
        return false;
      }

      const flowType = flow.is_credit ? 'expenses' : 'earnings';
      if (type && type !== flowType) {
        return false;
      }

      if (is_approved && is_approved === 'yes' && !flow.is_approved) {
        return false;
      }

      if (is_approved && is_approved === 'no' && flow.is_approved) {
        return false;
      }

      return true;
    });

    flows.sort((a, b) => (a.date < b.date ? -1 : 1));
    return flows;
  },

  sortedRecurringNonDuplicatedFinanceFlows(state) {
    const flows = window.structuredClone(state.recurringNonDuplicatedFinanceFlows);
    flows.sort((a, b) => (a.date < b.date ? -1 : 1));
    return flows;
  },

  recurringStepOptions() {
    return [
      { value: 'yearly', text: i18n.t('finance_planner.recurring_step_options.yearly') },
      { value: 'monthly', text: i18n.t('finance_planner.recurring_step_options.monthly') },
    ];
  },
};

const mutations = {
  SET_PLANNED_FINANCE_FLOWS(state, data) {
    const plannedFinanceFlows = window.structuredClone(data.planned);
    for (let i = 0; i < plannedFinanceFlows.length; i++) {
      const flow = plannedFinanceFlows[i];

      // some items have ids, some don't, some have duplicated ids (recurring duplicated flows)
      // so we use a randomUUID for keys instead
      flow.key = window.crypto.randomUUID();

      if (flow.date) {
        if (flow.date.includes('T')) {
          // we need to remove time, because 2023-09-30T23:59:59.999999Z is always rounded to 2023-10-01
          // and time is not relevant here
          flow.date = flow.date.slice(0, flow.date.indexOf('T'));
        }

        flow.date = format(new Date(flow.date), 'yyyy-MM-dd');
      }

      if (!flow.id || flow.recurring_step) {
        // there are some dynamically generated flows that do not have an id
        // we need the id in URL when viewing a finance flow
        flow.id = flow.key;
      }

      if (flow.client && !flow.client_id) {
        // for dynamic flows client is returned but not client_id which breaks display in the form
        flow.client_id = flow.client.id;
      }
    }
    state.plannedFinanceFlows = plannedFinanceFlows;

    const recurringFinanceFlows = window.structuredClone(data.recurring_non_duplicated);
    for (let i = 0; i < recurringFinanceFlows.length; i++) {
      const flow = recurringFinanceFlows[i];
      if (flow.client && !flow.client_id) {
        // for dynamic flows client is returned but not client_id which breaks display in the form
        flow.client_id = flow.client.id;
      }
    }

    state.recurringNonDuplicatedFinanceFlows = recurringFinanceFlows;
  },

  SET_PLANNED_FINANCE_FLOW_FILTER_PARAMS(state, params) {
    state.plannedFinanceFlowFilterParams = params;
  },

  SET_EDITED_PLANNED_FINANCE_FLOW(state, plannedFinanceFlow) {
    state.plannedFinanceFlowValidationErrors = {};
    state.editedPlannedFinanceFlow = JSON.parse(JSON.stringify(plannedFinanceFlow));
  },

  SET_NEW_PLANNED_FINANCE_FLOW(state, plannedFinanceFlow) {
    state.newPlannedFinanceFlow = plannedFinanceFlow;
  },

  STORE_PLANNED_FINANCE_FLOW(state, plannedFinanceFlow) {
    state.plannedFinanceFlows.push(plannedFinanceFlow);
    state.plannedFinanceFlowValidationErrors = {};
    state.newPlannedFinanceFlow = getDefaultClientPlannedFinanceFlowFormItem();
  },

  UPDATE_PLANNED_FINANCE_FLOW(state, plannedFinanceFlow) {
    state.plannedFinanceFlows = updateArrayItem(state.plannedFinanceFlows, plannedFinanceFlow);
  },

  DELETE_PLANNED_FINANCE_FLOW(state, plannedFinanceFlow) {
    state.plannedFinanceFlows = removeArrayItem(state.plannedFinanceFlows, plannedFinanceFlow);
    state.recurringNonDuplicatedFinanceFlows = removeArrayItem(
      state.recurringNonDuplicatedFinanceFlows,
      plannedFinanceFlow
    );
  },

  SET_PLANNED_FINANCE_FLOW_VALIDATION_ERRORS(state, plannedFinanceFlowValidationErrors) {
    state.plannedFinanceFlowValidationErrors = plannedFinanceFlowValidationErrors;
  },

  CLEAR_PLANNED_FINANCE_FLOW_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.plannedFinanceFlowValidationErrors, field);
  },
};

const actions = {
  fetchPlannedFinanceFlows({ commit }, params) {
    commit('SET_PLANNED_FINANCE_FLOW_FILTER_PARAMS', params);
    return plannedFinanceFlowService.getAll(params).then((res) => {
      commit('SET_PLANNED_FINANCE_FLOWS', res.data);
      return res.data;
    });
  },

  storePlannedFinanceFlow({ dispatch, state, commit }, plannedFinanceFlow) {
    return plannedFinanceFlowService
      .create(plannedFinanceFlow)
      .then((res) => {
        if (!res.data.recurring_step) {
          commit('STORE_PLANNED_FINANCE_FLOW', res.data);
        } else {
          // if the created flow is recurring let BE fill them in
          dispatch('fetchPlannedFinanceFlows', state.plannedFinanceFlowFilterParams);
        }
        openSnackbar(i18n.t('finance_planner.planned_finance_flow_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PLANNED_FINANCE_FLOW_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editPlannedFinanceFlow({ state, commit }, plannedFinanceFlowId) {
    let plannedFinanceFlow = state.recurringNonDuplicatedFinanceFlows.find(
      (c) => c.id.toString() === plannedFinanceFlowId.toString()
    );
    if (plannedFinanceFlow) {
      commit('SET_EDITED_PLANNED_FINANCE_FLOW', plannedFinanceFlow);
      return Promise.resolve(plannedFinanceFlow);
    }
    plannedFinanceFlow = state.plannedFinanceFlows.find(
      (c) => c.id.toString() === plannedFinanceFlowId.toString()
    );
    if (plannedFinanceFlow) {
      commit('SET_EDITED_PLANNED_FINANCE_FLOW', plannedFinanceFlow);
      return Promise.resolve(plannedFinanceFlow);
    }
    return Promise.reject();
  },

  updatePlannedFinanceFlow({ state, commit, dispatch }, plannedFinanceFlow) {
    return plannedFinanceFlowService
      .update(plannedFinanceFlow)
      .then((res) => {
        if (!res.data.recurring_step) {
          commit('UPDATE_PLANNED_FINANCE_FLOW', { ...plannedFinanceFlow, ...res.data });
        } else {
          // if the updated flow is recurring let BE fill them in
          dispatch('fetchPlannedFinanceFlows', state.plannedFinanceFlowFilterParams);
        }
        openSnackbar(i18n.t('finance_planner.planned_finance_flow_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PLANNED_FINANCE_FLOW_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deletePlannedFinanceFlow({ commit }, plannedFinanceFlow) {
    openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      plannedFinanceFlowService.delete(plannedFinanceFlow).then(() => {
        commit('DELETE_PLANNED_FINANCE_FLOW', plannedFinanceFlow);
        openSnackbar(i18n.t('finance_planner.planned_finance_flow_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

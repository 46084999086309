/* eslint-disable no-param-reassign */
import axios from 'axios';
import authService from '@/api/auth-service';
import { scrollToFirstError } from '@/util/forms';
import store from '@/store';
import i18n from '@/i18n/i18n-config';
import { openSnackbar } from '@/util/event-bus';
import { adjustViewForPagination } from '@/util/scrolling';
import Qs from 'qs';
import { removeEmptyFilters } from '@/util/filter-params';
import { getLocalStorageItem } from '@/util/local-storage';

const REFRESH_TOKEN_NOT_FOUND_MESSAGE = 'Refresh token not found';

export function getRequestKey(config) {
  return `${config.method}:${config.url}`;
}

function refreshToken() {
  if (store.state.auth.refreshTokenPromise) {
    return store.state.auth.refreshTokenPromise;
  }

  const token = getLocalStorageItem('evo_refresh_token');
  if (!token) {
    return Promise.reject(new Error(REFRESH_TOKEN_NOT_FOUND_MESSAGE));
  }

  const tokenRefreshPromise = authService
    .refreshToken(token)
    .then((res) => {
      store.dispatch('auth/handleAuthData', res.data);
      return Promise.resolve(true);
    })
    .finally(() => {
      store.commit('auth/SET_REFRESH_TOKEN_PROMISE', null);
    });
  store.commit('auth/SET_REFRESH_TOKEN_PROMISE', tokenRefreshPromise);
  return tokenRefreshPromise;
}

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  paramsSerializer: (params) =>
    Qs.stringify(removeEmptyFilters(params), { arrayFormat: 'indices' }),
});

httpClient.interceptors.request.use((config) => {
  if (store.getters['auth/accessToken']) {
    config.headers.common.Authorization = `Bearer ${store.getters['auth/accessToken']}`;
  }
  if (store.getters['auth/impersonatedUserId']) {
    config.headers.common['Impersonating-As'] = `${store.getters['auth/impersonatedUserId']}`;
  }
  config.headers.common.Locale = store.state.settings.locale;

  store.dispatch('setPendingRequest', getRequestKey(config));
  return config;
});

httpClient.interceptors.response.use(
  (res) => {
    adjustViewForPagination(res);
    store.dispatch('removePendingRequest', getRequestKey(res.config));
    return res;
  },
  (error) => {
    if (!error?.response?.config) {
      openSnackbar(i18n.t('errors.network_error'));
      return Promise.reject(error);
    }
    const requestKey = getRequestKey(error.response.config);
    store.dispatch('removePendingRequest', requestKey);
    if (!error.response?.status || requestKey === 'post:oauth/token') {
      return Promise.reject(error);
    }
    switch (error.response.status) {
      case 401:
        if (requestKey === 'post:oauth/token') {
          // failed to refresh token
          return Promise.reject(error);
        }
        return refreshToken()
          .then(() => {
            error.config.headers.Authorization = `Bearer ${store.getters['auth/accessToken']}`;
            return httpClient.request(error.config);
          })
          .catch((refreshOrRepeatedRequestError) => {
            if (
              refreshOrRepeatedRequestError?.response?.status === 401 ||
              refreshOrRepeatedRequestError.toString().includes(REFRESH_TOKEN_NOT_FOUND_MESSAGE)
            ) {
              store.dispatch('auth/logout');
              openSnackbar({
                text: i18n.t('general.session_expired'),
                timeout: 10000,
              });
            }
            return Promise.reject(refreshOrRepeatedRequestError);
          });
      case 422:
        setTimeout(() => {
          scrollToFirstError();
        });
        openSnackbar(i18n.t(`errors.http.${error.response.status}`));
        return Promise.reject(error);
      case 403:
      case 404:
      case 429:
        openSnackbar(i18n.t(`errors.http.${error.response.status}`));
        return Promise.reject(error);
      case 500: {
        openSnackbar(i18n.t('errors.http.500'));
        return Promise.reject(error);
      }
      default: {
        return Promise.reject(error);
      }
    }
  }
);

export default httpClient;

import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format } from 'date-fns';

export const getDefaultCompanyRequestFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultCompanyRequestFilterParams = () => ({});

const state = {
  companyRequests: [],
  companyRequestPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedCompanyRequest: {},
  newCompanyRequest: getDefaultCompanyRequestFormItem(),
  companyRequestValidationErrors: {},
  companyRequestFilterParams: getDefaultCompanyRequestFilterParams(),
  downloadingCompanyRequests: false, // booleans instead of $store.getter.loading because both loading and downloading use the same route
  loadingCompanyRequests: false,
};

const getters = {
  companyRequestFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg, .adoc';
  },
};

const mutations = {
  SET_COMPANY_REQUESTS(state, { data, current_page, per_page, total }) {
    state.companyRequests = data;
    state.companyRequestPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_COMPANY_REQUEST_FILTER_PARAMS(state, params) {
    state.companyRequestFilterParams = params;
  },

  SET_NEW_COMPANY_REQUEST(state, companyRequest) {
    state.newCompanyRequest = companyRequest;
  },

  SET_EDITED_COMPANY_REQUEST(state, companyRequest) {
    state.companyRequestValidationErrors = {};
    state.editedCompanyRequest = JSON.parse(JSON.stringify(companyRequest));
  },

  STORE_COMPANY_REQUEST(state, companyRequest) {
    state.companyRequests.unshift(companyRequest);
    state.companyRequestPagination.total += 1;
    state.companyRequestValidationErrors = {};
    state.newCompanyRequest = getDefaultCompanyRequestFormItem();
  },

  UPDATE_COMPANY_REQUEST(state, companyRequest) {
    state.companyRequests = updateArrayItem(state.companyRequests, companyRequest);
  },

  DELETE_COMPANY_REQUEST(state, companyRequest) {
    state.companyRequests = removeArrayItem(state.companyRequests, companyRequest);
    state.companyRequestPagination.total -= 1;
  },

  SET_COMPANY_REQUEST_VALIDATION_ERRORS(state, companyRequestValidationErrors) {
    state.companyRequestValidationErrors = companyRequestValidationErrors;
  },

  CLEAR_COMPANY_REQUEST_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.companyRequestValidationErrors, field);
  },

  SET_DOWNLOADING_COMPANY_REQUESTS(state, value) {
    state.downloadingCompanyRequests = value;
  },

  SET_LOADING_COMPANY_REQUESTS(state, value) {
    state.loadingCompanyRequests = value;
  },
};

const actions = {
  fetchCompanyRequests({ commit }, params) {
    commit('SET_COMPANY_REQUEST_FILTER_PARAMS', params);
    commit('SET_LOADING_COMPANY_REQUESTS', true);
    return documentService
      .getPage(params, 'company_request')
      .then((res) => {
        commit('SET_COMPANY_REQUESTS', res.data);
        return res.data;
      })
      .finally(() => {
        commit('SET_LOADING_COMPANY_REQUESTS', false);
      });
  },

  async downloadCompanyRequests({ state, commit }) {
    commit('SET_DOWNLOADING_COMPANY_REQUESTS', true);
    const { data } = await documentService.downloadAll(
      state.companyRequestFilterParams,
      'company_request'
    );
    commit('SET_DOWNLOADING_COMPANY_REQUESTS', false);
    downloadFile(data, `${i18n.t('general.company_requests')}.zip`);
  },

  storeCompanyRequest({ commit }, companyRequest) {
    return documentService
      .create({ ...companyRequest, document_type: 'company_request' })

      .then((res) => {
        commit('STORE_COMPANY_REQUEST', res.data);
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_COMPANY_REQUEST_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editCompanyRequest({ state, commit }, companyRequestId) {
    const companyRequest = state.companyRequests?.find((c) => c.id === companyRequestId);
    if (companyRequest) {
      commit('SET_EDITED_COMPANY_REQUEST', companyRequest);
      return Promise.resolve(companyRequest);
    }
    return documentService.getById(companyRequestId).then((res) => {
      commit('SET_EDITED_COMPANY_REQUEST', res.data);
      return res.data;
    });
  },

  updateCompanyRequest({ commit }, companyRequest) {
    return documentService
      .update(companyRequest)
      .then((res) => {
        commit('UPDATE_COMPANY_REQUEST', res.data);
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_COMPANY_REQUEST_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async toggleSeenCompanyRequest({ commit }, companyRequest) {
    try {
      commit('UPDATE_COMPANY_REQUEST', {
        ...companyRequest,
        seen: companyRequest.seen ? null : {},
      });
      await documentService.toggleSeen(companyRequest);
    } catch (e) {
      commit('UPDATE_COMPANY_REQUEST', companyRequest);
    }
  },

  async toggleCompanyRequestSignature({ commit }, updatedDocument) {
    try {
      commit('UPDATE_COMPANY_REQUEST', updatedDocument);
      await documentService.update(updatedDocument);
    } catch (e) {
      commit('UPDATE_COMPANY_REQUEST', {
        ...updatedDocument,
        is_signed_by_all_signees: !updatedDocument.is_signed_by_all_signees,
      });
    }
  },

  async uploadNewCompanyRequestFile({ commit }, { companyRequest, newFile }) {
    try {
      const { data } = await documentService.uploadNewFile(companyRequest, newFile);
      commit('UPDATE_COMPANY_REQUEST', data);
      openSnackbar(i18n.t('documents.messages.file_changed'));
    } catch (e) {
      openSnackbar(i18n.t('documents.messages.file_upload_failed'));
    }
  },

  deleteCompanyRequest({ commit }, companyRequest) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(companyRequest).then(() => {
        commit('DELETE_COMPANY_REQUEST', companyRequest);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <BaseFilePreviewFormLayout
    :file="item.file"
    :title="formTitle"
    :disabled="disabled"
    @cancel="$emit('cancel')"
    @save="$emit('save', item)"
  >
    <v-row dense>
      <v-col v-if="!item.id" cols="12">
        <v-file-input
          v-model="item.file"
          :error-messages="errors['file']"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
          :accept="sentInvoiceFileFormats"
          @change="formMixin_clearErrors('file')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.document_date"
          :error-messages="errors['document_date']"
          :label="$t('documents.labels.document_date')"
          @input="formMixin_clearErrors('document_date')"
        />
      </v-col>
    </v-row>
  </BaseFilePreviewFormLayout>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput.vue';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import { mapGetters } from 'vuex';
import BaseFilePreviewFormLayout from '@/components/base/BaseFilePreviewFormLayout.vue';

export default {
  name: 'SentInvoiceForm',

  components: {
    BaseFilePreviewFormLayout,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    sentInvoice: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { item: {} };
  },

  computed: {
    ...mapGetters('sentInvoices', ['sentInvoiceFileFormats']),

    formTitle() {
      return this.$t(`documents.modal_titles.${this.sentInvoice.id ? 'edit' : 'new'}_sent_invoice`);
    },
  },

  created() {
    const item = clone(this.sentInvoice);
    if (item.id) {
      item.invoice_id = item.model.invoice_id;
      item.invoice = item.model.invoice;
    }
    this.item = item;
  },
};
</script>

<template>
  <div v-click-outside="closeMenu">
    <v-tooltip v-if="!isSearchFieldDisplayed" bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="search-toggle-btn" icon @click="openMenu">
          <v-icon> search </v-icon>
        </v-btn>
      </template>

      <div>Ctrl + Shift + F</div>
    </v-tooltip>

    <v-menu
      :value="isMenuOpen"
      :close-on-click="false"
      :close-on-content-click="false"
      :open-on-click="false"
      nudge-bottom="4px"
      bottom
      offset-y
      @input="handleMenuInput"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-show="isSearchFieldDisplayed"
          v-on="on"
          :value="query"
          :loading="isLoading"
          :label="$t('general.search')"
          class="pt-0 search-field"
          ref="searchInput"
          prepend-inner-icon="arrow_back"
          single-line
          hide-details
          solo
          @input="handleSearchInput"
          @focus="openMenu"
          @click:prepend-inner="closeMenu"
        />
      </template>

      <v-card :ripple="false" class="menu-content" @click.stop>
        <v-sheet @click.stop="handleMenuClick">
          <v-radio-group
            v-model="selectedModel"
            class="pa-3 mt-0"
            row
            hide-details
            @change="setLoadingStatus(true)"
          >
            <v-radio v-for="m of models" :key="m.value" :label="m.label" :value="m.value" />
          </v-radio-group>
        </v-sheet>

        <project-search
          v-if="selectedModel === models[0].value"
          :query="query"
          @done="closeMenu"
          @update="setLoadingStatus(false)"
        />

        <password-search
          v-if="selectedModel === models[1].value"
          :query="query"
          @update="setLoadingStatus(false)"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import globalSearchMixin from '../mixins/global-search-mixin';

export default {
  name: 'GlobalSearchMenu',

  mixins: [globalSearchMixin],

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      isMenuOpen: false,
      isSearchFieldDisplayed: false,
    };
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },

  methods: {
    closeMenu() {
      const passwordShareDialog = document.querySelector('.password-share-dialog');
      if (passwordShareDialog && passwordShareDialog.style.display !== 'none') {
        // Because of v-click-outside directive, clicking on password share form closes the menu
        // This check ensures that when the form is open the menu will not close
        return;
      }
      this.isMenuOpen = false;
      this.isSearchFieldDisplayed = false;
      this.$emit('searchToggle', false);
    },

    handleKeyDown(e) {
      if (e.key?.toLowerCase() === 'f' && e.ctrlKey && e.shiftKey) {
        e.preventDefault();
        if (!this.isMenuOpen) {
          this.openMenu();
        } else {
          this.closeMenu();
        }
      }
    },

    handleMenuClick() {
      this.$refs.searchInput.focus();
    },

    handleMenuInput(value) {
      if (!value) {
        this.closeMenu();
      }
    },

    openMenu() {
      this.$emit('searchToggle', true);
      this.isSearchFieldDisplayed = true;
      setTimeout(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus();
        }
        this.isMenuOpen = true;
      });
    },
  },
};
</script>

<style scoped>
.menu-content,
.search-field {
  max-width: 1000px;
  width: 100vw;
}

.menu-content::before {
  opacity: 0 !important;
}

.search-toggle-btn {
  margin-left: 10px !important;
}
</style>

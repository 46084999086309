import httpClient from '@/api/http-client';

function downloadFile(blob, name) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function downloadFileFromAPI(url, name) {
  httpClient({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    downloadFile(response.data, name);
  });
}

function getFileUrl(url) {
  return httpClient({
    url,
    method: 'GET',
    responseType: 'blob',
    // eslint-disable-next-line arrow-body-style
  }).then((response) => {
    return window.URL.createObjectURL(new Blob([response.data]));
  });
}

export { downloadFile, downloadFileFromAPI, getFileUrl };

import Vue from 'vue';
import i18n from '@/i18n/i18n-config';

const eventBus = new Vue();

export const OPEN_FILE = 'openFile';
export const REFRESH_FILE = 'refreshFile';
export const FILE_OPENED = 'fileOpened'; // to know when to resolve promise
export const OPEN_CONFIRM_DIALOG = 'openConfirmDialog';
export const CONFIRM_DIALOG_CLOSED = 'confirmDialogClosed';
export const OPEN_SNACKBAR = 'openSnackbar';
export const SHOW_TOP_PROGRESS_BAR = 'showTopProgressBar';
export const HIDE_TOP_PROGRESS_BAR = 'hideTopProgressBar';

/**
 * Call this to open files. When promise resolves - file was opened in viewer or downloaded
 * @param params (name, url, isPublic)
 * @returns {Promise}
 */
export function openFile(params) {
  eventBus.$emit(OPEN_FILE, params);
  return new Promise((resolve) => {
    eventBus.$on(FILE_OPENED, async (confirmed) => {
      resolve(confirmed);
      eventBus.$off(FILE_OPENED);
    });
  });
}

/**
 * Call this to refresh a file in the opened GlobalFileViewer.
 * If GlobalFileViewer is not open, nothing will happen.
 */
export function refreshFile() {
  eventBus.$emit(REFRESH_FILE);
}

// helper to open confirm dialog with less code
export function openConfirmDialog(params) {
  eventBus.$emit(OPEN_CONFIRM_DIALOG, {
    title: params?.title || i18n.t('general.confirm_entry_delete'),
    confirmText: params?.confirmText || i18n.t('general.controls.confirm'),
    cancelText: params?.cancelText || i18n.t('general.controls.cancel'),
  });
  return new Promise((resolve) => {
    eventBus.$on(CONFIRM_DIALOG_CLOSED, async (confirmed) => {
      resolve(confirmed);
      eventBus.$off(CONFIRM_DIALOG_CLOSED);
    });
  });
}

export function openSnackbar(params) {
  eventBus.$emit(OPEN_SNACKBAR, params);
}

export function showTopProgressBar() {
  eventBus.$emit(SHOW_TOP_PROGRESS_BAR);
}

export function hideTopProgressBar() {
  eventBus.$emit(HIDE_TOP_PROGRESS_BAR);
}

export default eventBus;

<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    :color="$vuetify.theme.isDark ? 'grey darken-4' : 'white'"
    class="border-b"
    app
    flat
  >
    <v-app-bar-nav-icon @click.stop="toggleMenu" />
    <img
      v-if="$vuetify.breakpoint.lgAndUp"
      :src="darkTheme ? '/img/logo-2x.png' : '/img/logo-black-2x.png'"
      height="30"
      class="clickable ml-5 mr-3"
      @click="onLogoClick"
    />

    <ClientSurveyQuestionnaire v-if="$isClient() && $vuetify.breakpoint.mdAndUp" />

    <template v-if="$can(['app.global_search.*'])">
      <GlobalSearchDialog v-if="$vuetify.breakpoint.mdAndDown" @searchToggle="handleSearchToggle" />

      <GlobalSearchMenu v-else @searchToggle="handleSearchToggle" />
    </template>

    <!-- Shortcuts -->
    <template v-if="$can(['app.shortcuts.*'])">
      <template v-if="$vuetify.breakpoint.mdAndUp && !isSearchFieldDisplayed">
        <v-toolbar-items v-if="!iconShortcuts">
          <v-btn
            v-for="shortcut of decoratedShortcuts"
            :key="shortcut.id"
            :to="shortcut.isExternal ? undefined : shortcut.link"
            :href="shortcut.isExternal ? shortcut.link : undefined"
            target="_self"
            text
          >
            <v-icon class="mr-2">
              {{ shortcut.icon }}
            </v-icon>
            {{ shortcut.title }}
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items v-else>
          <v-tooltip v-for="shortcut of decoratedShortcuts" :key="shortcut.id" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :to="shortcut.isExternal ? undefined : shortcut.link"
                :href="shortcut.isExternal ? shortcut.link : undefined"
                text
                class="mx-0 min-width-unset"
              >
                <v-icon>
                  {{ shortcut.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ shortcut.title }}
            </span>
          </v-tooltip>
        </v-toolbar-items>
      </template>

      <v-toolbar-items v-else-if="!isSearchFieldDisplayed">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>location_on</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="shortcut of decoratedShortcuts"
              :key="shortcut.id"
              :to="shortcut.isExternal ? undefined : shortcut.link"
              :href="shortcut.isExternal ? shortcut.link : undefined"
            >
              <v-list-item-action class="list-action-icon">
                <v-icon>
                  {{ shortcut.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ shortcut.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </template>

    <v-spacer />

    <SentryIssueCounts v-if="!$isClient()" />

    <v-tooltip v-if="$can(['projects.view.*']) && totalProjectIssuesCount > 0" bottom>
      <template #activator="{ on }">
        <v-btn v-on="on" icon @click="viewNewIssues">
          <v-badge
            :value="totalProjectIssuesCount > 0"
            :content="issueCountLabel"
            :color="newProjectIssuesCount > 0 ? 'error' : 'grey darken-1'"
            overlap
          >
            <v-icon>bug_report</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <table>
        <tr>
          <td>{{ $t('home_page.issues.new_issues') }}:</td>
          <td class="text-right pl-1">{{ newProjectIssuesCount }}</td>
        </tr>
        <tr>
          <td>{{ $t('projects.labels.open_issues') }}:</td>
          <td class="text-right pl-1">{{ totalProjectIssuesCount }}</td>
        </tr>
      </table>
    </v-tooltip>

    <Notifications />

    <EmployeeBirthdays v-if="$vuetify.breakpoint.smAndUp && !$isClient()" show-in-menu />

    <v-toolbar-items>
      <v-menu v-if="currentUser" bottom offset-y offset-x>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" v-if="$vuetify.breakpoint.mdAndUp" text>
            {{ currentUser.username }}
          </v-btn>
          <v-btn v-else v-on="on" icon>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list :class="{ 'pt-2': $vuetify.breakpoint.mdAndUp }" subheader>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-subheader>
              {{ currentUser ? currentUser.username : '' }}
            </v-subheader>
            <v-divider />
          </template>

          <v-list-item @click="viewProfile">
            <v-list-item-icon>
              <v-icon>settings</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('general.my_account') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="impersonatedUserId" @click="stopImpersonating">
            <v-list-item-icon>
              <v-icon>stop</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('general.controls.stop_impersonating') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="loggingOut" @click.stop="logout">
            <v-list-item-icon>
              <v-progress-circular
                v-if="loggingOut"
                color="inherit"
                size="24"
                width="3"
                indeterminate
              />
              <v-icon v-else>exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('general.controls.logout') }}
            </v-list-item-title>
          </v-list-item>

          <EmployeeBirthdays v-if="$vuetify.breakpoint.xsOnly && !$isClient()" />
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import GlobalSearchDialog from '../GlobalSearchDialog.vue';
import GlobalSearchMenu from '../GlobalSearchMenu.vue';
import ClientSurveyQuestionnaire from '../../legacy-components/client-surveys/ClientSurveyQuestionnaire.vue';
import Notifications from '../Notifications.vue';
import EmployeeBirthdays from '@/components/EmployeeBirthdays.vue';
import authService from '@/api/auth-service';
import SentryIssueCounts from '@/components/SentryIssueCounts.vue';
import { openReactPortal } from '@/util/react-portal-integration';

let intervalId;

export default {
  name: 'Toolbar',

  components: {
    SentryIssueCounts,
    EmployeeBirthdays,
    Notifications,
    ClientSurveyQuestionnaire,
    GlobalSearchMenu,
    GlobalSearchDialog,
  },

  data() {
    return {
      loggingOut: false,
      isSearchFieldDisplayed: false,
    };
  },

  created() {
    if (!this.$isClient()) {
      this.fetchTotalProjectIssuesCount();
      intervalId = setInterval(() => {
        this.fetchTotalProjectIssuesCount();
      }, 30000);
    }
  },

  beforeDestroy() {
    clearInterval(intervalId);
  },

  computed: {
    ...mapState('settings', ['iconShortcuts', 'darkTheme', 'sidenavOpen']),
    ...mapState('projectIssues', [
      'newProjectIssuesCount',
      'totalProjectIssuesCount',
    ]),
    ...mapGetters('auth', ['currentUser', 'impersonatedUserId']),
    ...mapGetters('shortcuts', ['decoratedShortcuts']),

    issueCountLabel() {
      const count =
        this.newProjectIssuesCount > 0 ? this.newProjectIssuesCount : this.totalProjectIssuesCount;
      if (count > 9) {
        return '9+';
      }
      return count.toString();
    },
  },

  methods: {
    ...mapActions('settings', ['updateSetting']),
    ...mapActions('projectIssues', ['fetchTotalProjectIssuesCount']),
    ...mapActions('auth', ['stopImpersonating']),

    handleSearchToggle(isOpen) {
      this.isSearchFieldDisplayed = isOpen;
    },

    async logout() {
      this.loggingOut = true;
      try {
        await authService.logout();
        await this.$store.dispatch('auth/logout');
      } catch (e) {
        //
      }
      this.loggingOut = false;
    },

    viewProfile() {
      this.$router.push({ name: 'profile' });
    },

    toggleMenu() {
      this.updateSetting({
        key: 'sidenavOpen',
        value: !this.sidenavOpen,
      });
    },

    viewNewIssues() {
      openReactPortal(``);
    },

    onLogoClick() {
      if (this.currentUser.role === 'client') {
        this.$router.push('/');
        return;
      }
      openReactPortal('');
    },
  },
};
</script>

<template>
  <div>
    <v-btn class="search-toggle-btn" icon @click="openDialog">
      <v-icon> search </v-icon>
    </v-btn>

    <v-dialog :value="isDialogOpen" fullscreen scrollable transition="slide-y-reverse-transition">
      <v-card>
        <v-text-field
          :value="query"
          :loading="isLoading"
          :label="$t('general.search')"
          class="pt-0 search-field"
          ref="searchInput"
          prepend-inner-icon="arrow_back"
          single-line
          hide-details
          solo
          @input="handleSearchInput"
          @focus="openDialog"
          @click:prepend-inner="closeDialog"
        />

        <v-card-text class="pa-0 mt-1">
          <v-sheet class="sticky border-b">
            <v-radio-group
              v-model="selectedModel"
              class="pa-3 mt-0"
              row
              hide-details
              @change="setLoadingStatus(true)"
            >
              <v-radio v-for="m of models" :key="m.value" :label="m.label" :value="m.value" />
            </v-radio-group>
          </v-sheet>

          <project-search
            v-if="selectedModel === models[0].value"
            :query="query"
            @update="setLoadingStatus(false)"
          />

          <password-search
            v-if="selectedModel === models[1].value"
            :query="query"
            @update="setLoadingStatus(false)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalSearchMixin from '../mixins/global-search-mixin';
import clone from 'just-clone';

export default {
  name: 'GlobalSearchDialog',

  mixins: [globalSearchMixin],

  computed: {
    isDialogOpen() {
      return this.$route.query['is-search-open'];
    },
  },

  methods: {
    blurSearchField() {
      this.$refs.searchInput.blur();
    },

    closeDialog() {
      this.blurSearchField();
      if (!this.$route.query['is-search-open']) {
        return;
      }
      const newQuery = clone(this.$route.query);
      delete newQuery['is-search-open'];
      this.$router.push({
        ...this.$route,
        query: newQuery,
      });
      this.$emit('searchToggle', false);
    },

    openDialog() {
      if (this.$route.query['is-search-open']) {
        return;
      }

      this.$emit('searchToggle', true);
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          'is-search-open': true,
        },
      });

      setTimeout(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus();
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
.search-field {
  width: 100%;
  flex: 0;
}

.search-toggle-btn {
  margin-left: 10px !important;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>

import httpClient from '@/api/http-client';
import clone from 'just-clone';

const googleDocService = {
  // generates a document using provided data in google docs
  generate: (document) => {
    const payload = clone(document);
    delete payload.client;
    delete payload.project; // potentially very heavy object, BE only needs project_id
    return httpClient.post('api/documents-generate', payload);
  },

  // downloads document from google docs on the server and stores it locally
  storeDocument: (document) => httpClient.put(`api/documents-store-file/${document.id}`),

  // sends document's Google docs link to specified email
  shareDocument: (document, email) =>
    httpClient.put(`api/documents-store-file/${document.id}`, { email }),
};

export default googleDocService;

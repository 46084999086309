import httpClient from '@/api/http-client';

const endpoint = 'oauth/token';

const AUTH_PARAMS = {
  scope: import.meta.env.VITE_APP_SCOPE,
  client_id: import.meta.env.VITE_APP_CLIENT_ID,
  client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
};

const authService = {
  login: (username, password) => {
    const payload = {
      ...AUTH_PARAMS,
      grant_type: import.meta.env.VITE_APP_GRANT_TYPE,
      username,
      password,
    };
    return httpClient.post(`${endpoint}`, payload);
  },

  refreshToken: (token) => {
    const payload = {
      ...AUTH_PARAMS,
      grant_type: 'refresh_token',
      refresh_token: token,
    };
    return httpClient.post(`${endpoint}`, payload);
  },

  logout: () => {
    return httpClient.post('api/logout');
  },

  getTotalActiveLoginCount: () => {
    return httpClient.get('api/total-active-login-count');
  },

  logoutOtherDevices: () => {
    return httpClient.post('api/logout-other-devices');
  },
};

export default authService;

<template>
  <BaseFilePreviewFormLayout
    :file="item.file"
    :title="formTitle"
    :disabled="disabled"
    @cancel="$emit('cancel')"
    @save="$emit('save', item)"
  >
    <v-row dense>
      <v-col v-if="!item.id" cols="12">
        <v-file-input
          v-model="item.file"
          :error-messages="errors['file']"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
          :accept="supplierAgreementFileFormats"
          @change="formMixin_clearErrors('file')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.document_date"
          :error-messages="errors['document_date']"
          :label="$t('documents.labels.document_date')"
          @input="formMixin_clearErrors('document_date')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <ContactAutocomplete
          v-model="item.supplier_id"
          :error-messages="errors['supplier_id']"
          :initial-item="item.supplier"
          :label="formMixin_getRequiredFieldLabel($t('general.contact'))"
          suppliers-only
          show-create-button
          @input="formMixin_clearErrors('supplier_id')"
          @update:initial-item="item.supplier = $event"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.valid_from"
          :error-messages="errors.valid_from"
          :label="$t('documents.labels.valid_from')"
          @input="formMixin_clearErrors('valid_from')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.valid_until"
          :error-messages="errors.valid_until"
          :label="$t('general.date_and_time.valid_until')"
          @input="formMixin_clearErrors('valid_until')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="item.is_signatures_needed"
          :error-messages="errors.is_signatures_needed"
          :label="$t('documents.labels.signature_needed')"
          @change="formMixin_clearErrors('is_signatures_needed')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
          v-if="item.is_signatures_needed"
          v-model="item.is_signed_by_all_signees"
          :error-messages="errors.is_signed_by_all_signees"
          :label="$t('documents.labels.signed')"
          @change="formMixin_clearErrors('is_signed_by_all_signees')"
        />
      </v-col>
    </v-row>
  </BaseFilePreviewFormLayout>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput.vue';
import clone from 'just-clone';
import { mapGetters } from 'vuex';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete.vue';
import BaseFilePreviewFormLayout from '@/components/base/BaseFilePreviewFormLayout.vue';

export default {
  name: 'SupplierAgreementForm',

  components: {
    BaseFilePreviewFormLayout,
    ContactAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    supplierAgreement: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapGetters('supplierAgreements', ['supplierAgreementFileFormats']),

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.supplierAgreement.id ? 'edit' : 'new'}_supplier_agreement`
      );
    },
  },

  created() {
    const item = clone(this.supplierAgreement);
    if (item.id) {
      item.supplier_id = item.model.supplier_id;
      item.supplier = item.model.supplier;
      item.valid_from = item.model.valid_from;
      item.valid_until = item.model.valid_until;
    }
    this.item = item;
  },
};
</script>

import { permissionsGuard } from '@/router/guards';

export const companyRequestRoutes = [
  {
    path: 'documents/company-requests',
    name: 'companyRequests',
    component: () =>
      import(
        /* webpackChunkName: "companyRequests" */ '@/views/company-requests/CompanyRequests.vue'
      ),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'documents',
      requiredPermissions: ['documents.company_request.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createCompanyRequest',
        component: () =>
          import(
            /* webpackChunkName: "createCompanyRequest" */ '@/views/company-requests/CreateCompanyRequest.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: { menuGroup: 'documents', requiredPermissions: ['documents.company_request.view.*'] },
      },
      {
        path: ':id/edit',
        name: 'editCompanyRequest',
        component: () =>
          import(
            /* webpackChunkName: "editCompanyRequest" */ '@/views/company-requests/EditCompanyRequest.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: { menuGroup: 'documents', requiredPermissions: ['documents.company_request.view.*'] },
      },
    ],
  },
];

import { permissionsGuard } from '@/router/guards';

export const employeeDocumentRoutes = [
  {
    path: 'documents/employee-documents',
    name: 'employeeDocuments',
    component: () =>
      import(
        /* webpackChunkName: "employeeDocuments" */ '@/views/employee-documents/EmployeeDocuments.vue'
      ),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'documents',
      requiredPermissions: ['documents.employee_documents.view.*'],
    },
    children: [
      {
        path: 'create-vacation-application',
        name: 'createEmployeeVacationApplication',
        component: () =>
          import(
            /* webpackChunkName: "createEmployeeVacationApplication" */ '@/views/employee-documents/CreateEmployeeVacationApplication.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.employee_documents.view.*'],
        },
      },
      {
        path: 'view-vacation-application/:applicationId',
        name: 'viewEmployeeVacationApplication',
        component: () =>
          import(
            /* webpackChunkName: "createEmployeeVacationApplication" */ '@/views/employee-documents/ViewEmployeeVacationApplication.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.employee_documents.view.*'],
        },
      },
      {
        path: 'create',
        name: 'createEmployeeDocument',
        component: () =>
          import(
            /* webpackChunkName: "createEmployeeDocument" */ '@/views/employee-documents/CreateEmployeeDocument.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.employee_documents.view.*'],
        },
      },
      {
        path: ':id/edit',
        name: 'editEmployeeDocument',
        component: () =>
          import(
            /* webpackChunkName: "editEmployeeDocument" */ '@/views/employee-documents/EditEmployeeDocument.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.employee_documents.view.*'],
        },
      },
    ],
  },
];

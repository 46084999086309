<template>
  <v-menu
    v-if="!!sentryIssueCounts.length"
    v-model="isMenuOpen"
    :class="{ 'mr-1': $vuetify.breakpoint.mdAndUp }"
    :close-on-content-click="false"
    left
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-badge :value="activeItemCount > 0" :content="activeItemCountLabel" color="error" overlap>
          <v-icon>nearby_error</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card class="sentry-issues-card">
      <v-card-title class="py-2">
        {{ $t('general.sentry_issues') }}
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list max-height="400" class="overflow-y-auto">
          <v-list-item v-if="!sentryIssueCounts.length">
            <v-list-item-title class="grey--text">
              {{ $t('general.no_sentry_issues') }}
            </v-list-item-title>
          </v-list-item>

          <template>
            <v-list-item v-for="project in sentryIssueCounts" :key="project.project_id">
              <v-list-item-content>
                <v-list-item-title
                  class="clickable underline-on-hover"
                  @click="openReactPortal(`project/${project.project_id}`)"
                  >{{ project.project.title }}</v-list-item-title
                >
                <v-list-item-subtitle
                  v-for="(issue, index) in project.details"
                  :key="`issue_${project.project_id}_${index}`"
                  :class="issue.sentry_project_url ? 'clickable underline-on-hover' : ''"
                  @click="redirectToSentryProject(issue.sentry_project_url)"
                >
                  <v-badge :content="issue.issue_count" color="error" inline />
                  {{ getGitlabRepoName(issue.url_git) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { openReactPortal } from '@/util/react-portal-integration';

export default {
  name: 'SentryIssueCounts',

  data() {
    return {
      isMenuOpen: false,
      intervalId: null,
      // sentryIssueCounts: [
      //   {
      //     project_id: 1,
      //     project: { id: 1, title: '7S7VB6kmvPxbBiKH' },
      //     total_issue_count: 5,
      //     details: [
      //       {
      //         url_git: 'https://gitlab.com/evchargeplus/api.evchargeplus.com',
      //         sentry_project_url: 'https://www.google.lt',
      //         issue_count: 3,
      //       },
      //       {
      //         url_git: 'https://gitlab.com/cramo/cramodokumentai.lt',
      //         sentry_project_url: 'https://www.google.lt',
      //         issue_count: 2,
      //       },
      //     ],
      //   },
      //   {
      //     project_id: 2,
      //     project: { id: 2, title: 'Xh8jT5pumZC2VMOt' },
      //     total_issue_count: 1,
      //     details: [
      //       { url_git: 'https://gitlab.com/evolco-intranet-fe/i.evolco.eu', issue_count: 1 },
      //     ],
      //   },
      // ],
    };
  },

  computed: {
    ...mapState('projectIssues', ['sentryIssueCounts']),

    activeItemCount() {
      return this.sentryIssueCounts.length;
      // let count = 0;
      // this.sentryIssueCounts.map((project) => {
      //   count += project.total_issue_count;
      // });
      // return count;
    },

    activeItemCountLabel() {
      if (this.activeItemCount > 9) {
        return '9+';
      }
      return this.activeItemCount.toString();
    },
  },

  methods: {
    openReactPortal,
    ...mapActions('sentryIssues', ['getSentryIssueCounts']),

    getGitlabRepoName(gitlabUrl) {
      return gitlabUrl.split('/').pop();
    },

    redirectToSentryProject(url) {
      if (!url) {
        return;
      }
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.sentry-issues-card {
  width: 414px;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
</style>

<template>
  <v-row :class="{ 'border-t': passwords.length }" no-gutters>
    <v-col v-if="passwords.length" cols="12">
      <PasswordTable
        :items="passwords"
        compact
        @share:password="sharePassword"
        @encryptPassword="encryptPassword($event.item, $event.index)"
      />
    </v-col>

    <!-- Password share modal -->
    <v-dialog
      :value="isShareDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      content-class="password-share-dialog"
      persistent
      scrollable
    >
      <password-share-form
        :dialog="isShareDialogOpen"
        :item="selectedPassword"
        @passwordShared="passwordShared"
        @dialogClosed="closeSharePasswordDialog"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import * as CryptoJS from 'crypto-js';
import PasswordShareForm from './PasswordShareForm.vue';
import passwordService from '../../api/password-service';
import eventBus, { OPEN_SNACKBAR, openSnackbar } from '@/util/event-bus';
import clone from 'just-clone';
import { getLocalStorageItem } from '@/util/local-storage';
import PasswordTable from '@/components/tables/PasswordTable.vue';

export default {
  name: 'PasswordSearch',

  components: {
    PasswordTable,
    PasswordShareForm,
  },

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      passwords: [],
      passwordCrKey: '',
      selectedPassword: {},
    };
  },

  computed: {
    isShareDialogOpen() {
      return this.$route.query['is-password-share-dialog-open'];
    },
  },

  watch: {
    query(value) {
      this.getPasswords(value);
    },
  },

  created() {
    this.passwordCrKey = getLocalStorageItem('evo_cr');
    this.getPasswords(this.query);
  },

  methods: {
    closeSharePasswordDialog() {
      if (!this.$route.query['is-password-share-dialog-open']) {
        return;
      }
      const newQuery = clone(this.$route.query);
      delete newQuery['is-password-share-dialog-open'];
      this.$router.push({
        ...this.$route,
        query: newQuery,
      });
    },

    encryptPassword(password, index) {
      const requestBody = JSON.parse(JSON.stringify(password));
      const cipherText = CryptoJS.AES.encrypt(requestBody.details, this.passwordCrKey);
      requestBody.details = cipherText.toString();
      this.$http
        .put(`api/www-resource-entities/${requestBody.id}`, requestBody)
        .then((response) => {
          const bytes = CryptoJS.AES.decrypt(response.data.details, this.passwordCrKey);
          response.data.details = bytes.toString(CryptoJS.enc.Utf8);
          this.$set(this.passwords, index, response.data);
          eventBus.$emit(
            OPEN_SNACKBAR,
            this.$t('password_manager.general.password_encrypted_start_using')
          );
        });
    },

    async getPasswords(query) {
      if (!query || query.length < 3) {
        this.passwords = [];
        this.$emit('update');
        return;
      }

      try {
        const res = await passwordService.search(query);
        this.passwords = res.data.map((item) => {
          if (item.details && item.is_encrypted) {
            const bytes = CryptoJS.AES.decrypt(item.details, this.passwordCrKey);
            item.details = bytes.toString(CryptoJS.enc.Utf8);
            return item;
          }
          return item;
        });
      } catch (e) {
        this.passwords = [];
      } finally {
        this.$emit('update');
      }
    },

    passwordShared() {
      openSnackbar(this.$t('password_manager.general.password_shared'));
      this.closeSharePasswordDialog();
    },

    sharePassword(password) {
      this.selectedPassword = password;
      if (this.$route.query['is-password-share-dialog-open']) {
        return;
      }
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          'is-password-share-dialog-open': true,
        },
      });
    },
  },
};
</script>

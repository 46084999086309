<template>
  <div class="text-center py-3">
    <h3 class="text-h5" style="font-size: 20px !important">
      <template v-if="displayedText">
        {{ displayedText }}
      </template>
      <template v-else>
        {{ $t('general.no_data') }}
      </template>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'NoData',

  props: {
    displayedText: String,
  },
};
</script>

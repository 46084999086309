import httpClient from '@/api/http-client';

const endpoint = 'api/shortcut';

const shortcutService = {
  getMy: (params = {}) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  create: (shortcut) => httpClient.post(endpoint, shortcut),

  update: (shortcut) => httpClient.put(`${endpoint}/${shortcut.id}`, shortcut),

  delete: (shortcut) => httpClient.delete(`${endpoint}/${shortcut.id}`),
};

export default shortcutService;

<template>
  <v-card>
    <v-card-title style="min-height: 62px">
      <slot name="title">
        {{ title }}
      </slot>
      <v-spacer />
      <v-btn
        v-if="$vuetify.breakpoint.smAndUp && canPreviewFile"
        icon
        @click="isExpanded = !isExpanded"
      >
        <v-icon>{{ isExpanded ? 'chevron_right' : 'chevron_left' }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12" :sm="formSm">
          <slot></slot>
        </v-col>

        <v-col
          v-if="canPreviewFile"
          v-show="isExpanded || $vuetify.breakpoint.xsOnly"
          cols="12"
          sm="6"
          class="text-center"
        >
          <v-img v-if="fileType === 'img'" :src="fileUrl" />
          <embed
            v-else-if="fileType === 'pdf'"
            :src="fileUrl"
            type="application/pdf"
            width="100%"
            height="100%"
            style="min-height: 460px"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <slot name="actions">
        <v-spacer />

        <v-btn text @click="$emit('cancel')">
          {{ $t('general.controls.cancel') }}
        </v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text @click="$emit('save')">
          {{ $t('general.controls.save') }}
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'BaseFilePreviewFormLayout',

  props: {
    file: {
      type: File,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fileUrl: '',
      isExpanded: false,
    };
  },

  computed: {
    fileType() {
      if (this.file.type.includes('image')) {
        return 'img';
      }
      if (this.file.type.includes('pdf')) {
        return 'pdf';
      }
      return 'unsupported';
    },

    canPreviewFile() {
      return this.fileUrl && this.fileType !== 'unsupported';
    },

    formSm() {
      if (this.canPreviewFile && this.isExpanded) {
        return 6;
      }

      return 12;
    },
  },

  watch: {
    file: {
      handler(newValue) {
        this.fileUrl = '';
        if (!newValue || typeof newValue !== 'object') {
          return;
        }
        const fileReader = new FileReader();
        fileReader.readAsDataURL(newValue);
        fileReader.addEventListener('load', (event) => {
          this.fileUrl = event.target.result || '';
          this.isExpanded = this.canPreviewFile;
        });
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>

<template>
  <BaseFilePreviewFormLayout
    :file="item.file"
    :title="formTitle"
    :disabled="disabled"
    @cancel="$emit('cancel')"
    @save="$emit('save', item)"
  >
    <v-row dense>
      <v-col v-if="!employeeDocument.id" cols="12" sm="6">
        <v-file-input
          v-model="item.file"
          :error-messages="errors['file']"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
          :accept="employeeDocumentFileFormats"
          @change="formMixin_clearErrors('file')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.document_date"
          :error-messages="errors['document_date']"
          :label="$t('documents.labels.document_date')"
          @input="formMixin_clearErrors('document_date')"
        />
      </v-col>

      <v-col v-if="$isAdmin() && item.type !== 'order' && !hideEmployeeField" cols="12" sm="6">
        <BaseAutocomplete
          v-model="item.employee_id"
          :search-function="searchEmployees"
          :initial-item="item.employee"
          :error-messages="errors['employee_id']"
          :label="formMixin_getRequiredFieldLabel($t('general.employee'))"
          item-text="personal_data.full_name"
          @input="formMixin_clearErrors('employee_id')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
          v-model="item.type"
          :items="filteredEmployeeDocumentTypes"
          :error-messages="errors['type']"
          :label="formMixin_getRequiredFieldLabel($t('general.type'))"
          @input="
            onTypeChange($event);
            formMixin_clearErrors('type');
          "
        />
      </v-col>
    </v-row>

    <v-row v-if="item.type !== 'order'" dense>
      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.valid_from"
          :error-messages="errors.valid_from"
          :label="$t('documents.labels.valid_from')"
          @input="formMixin_clearErrors('valid_from')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.valid_until"
          :error-messages="errors.valid_until"
          :label="$t('general.date_and_time.valid_until')"
          @input="formMixin_clearErrors('valid_until')"
        />
      </v-col>
    </v-row>

    <v-row v-if="item.type === 'nid'" dense>
      <v-col cols="12">
        <v-checkbox
          v-model="item.encrypt"
          :error-messages="errors.encrypt"
          :label="$t('employees.general.encrypt')"
          @change="
            updateEncryptionFields(item.encrypt);
            formMixin_clearErrors('encrypt');
          "
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="item.is_signatures_needed"
          :error-messages="errors.is_signatures_needed"
          :label="$t('documents.labels.signature_needed')"
          @change="formMixin_clearErrors('is_signatures_needed')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
          v-if="item.is_signatures_needed"
          v-model="item.is_signed_by_all_signees"
          :error-messages="errors.is_signed_by_all_signees"
          :label="$t('documents.labels.signed')"
          @change="formMixin_clearErrors('is_signed_by_all_signees')"
        />
      </v-col>
    </v-row>
  </BaseFilePreviewFormLayout>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput.vue';
import formMixin from '@/mixins/form-mixin';
import BaseAutocomplete from '@/components/base/BaseAutocomplete.vue';
import employeeService from '@/api/employee-service';
import { mapGetters } from 'vuex';
import clone from 'just-clone';
import BaseFilePreviewFormLayout from '@/components/base/BaseFilePreviewFormLayout.vue';

export default {
  name: 'EmployeeDocumentForm',

  components: {
    BaseFilePreviewFormLayout,
    BaseAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    employeeDocument: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideEmployeeField: {
      type: Boolean,
      default: false,
    },
    employeeId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapGetters('employeeDocuments', ['employeeDocumentTypes', 'employeeDocumentFileFormats']),

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.employeeDocument.id ? 'edit' : 'new'}_employee_document`
      );
    },

    filteredEmployeeDocumentTypes() {
      if (!this.employeeDocument.id) {
        // vacation applications are created using another form
        return this.employeeDocumentTypes.filter((t) => t.value !== 'vacation_application');
      }
      return this.employeeDocumentTypes;
    },
  },

  created() {
    const item = clone(this.employeeDocument);
    if (item.id) {
      item.type = item.model.type;
      item.employee_id = item.model.employee_id;
      item.employee = item.model.employee;
      item.nid = item.model.nid;
      item.other = item.model.other;
      item.valid_from = item.model.valid_from;
      item.valid_until = item.model.valid_until;
      item.employee_cr_key = item.model.employee_cr_key;
      item.encrypt = item.model.encrypt;
    } else {
      if (this.employeeId) {
        item.employee_id = this.employeeId;
      }
      if (item.type === 'nid') {
        item.employee_cr_key = true;
        item.encrypt = true;
      }
    }
    this.item = item;
  },

  methods: {
    searchEmployees: employeeService.search,

    updateEncryptionFields(value) {
      this.item.employee_cr_key = value;
    },

    onTypeChange(type) {
      if (type === 'nid') {
        this.item.employee_cr_key = true;
        this.item.encrypt = true;
      } else {
        this.item.employee_cr_key = false;
        this.item.encrypt = false;
      }
    },
  },
};
</script>

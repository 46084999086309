<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <CommercialOfferForm
      :commercial-offer="newCommercialOffer"
      :errors="commercialOfferValidationErrors"
      :disabled="$store.getters.loading['post:api/documents']"
      @clear:errors="CLEAR_COMMERCIAL_OFFER_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import CommercialOfferForm from '@/components/forms/CommercialOfferForm.vue';
import { returnFromChildView } from '@/util/routing';

export default {
  name: 'CreateCommercialOffer',

  components: { CommercialOfferForm },

  computed: {
    ...mapState('commercialOffers', [
      'newCommercialOffer',
      'commercialOfferValidationErrors',
      'commercialOfferFilterParams',
    ]),
  },

  created() {
    this.SET_COMMERCIAL_OFFER_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('commercialOffers', ['storeCommercialOffer']),
    ...mapMutations('commercialOffers', [
      'SET_COMMERCIAL_OFFER_VALIDATION_ERRORS',
      'CLEAR_COMMERCIAL_OFFER_VALIDATION_ERRORS',
    ]),

    onSave(commercialOffer) {
      this.storeCommercialOffer(commercialOffer).then(() => {
        this.goBack();
      });
    },

    goBack() {
      returnFromChildView(this.$route, 'uploading-document', {
        name: 'commercialOffers',
        query: this.commercialOfferFilterParams,
      });
    },
  },
};
</script>

<template>
  <component
    :class="{ 'compact-table-wrapper': compact && $vuetify.breakpoint.lgAndUp }"
    :is="compact && $vuetify.breakpoint.lgAndUp ? 'simplebar' : 'div'"
    data-simplebar-auto-hide="false"
  >
    <input :id="copyToClipboardInputId" type="hidden" v-model="valueToCopy" />
    <v-data-table
      :disable-pagination="compact"
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :hide-default-footer="compact"
      :items="items"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      :item-class="getItemClass"
      disable-sort
      @click:row="onRowClick"
      @update:page="onPageChange"
    >
      <template v-slot:item.no="{ index }">
        {{ index + 1 + (pagination.page - 1) * pagination.rowsPerPage }}.
      </template>

      <template v-slot:item.type="{ item }">
        <template v-if="$vuetify.breakpoint.smAndUp">
          {{ item.type }}
        </template>
        <template v-else>
          {{ item.type }}:
          <a :href="item.url" target="_blank" class="table-link text-break" @click.stop>
            {{ item.url }}
          </a>
          <br />
          <div class="text-break">{{ item.description }}</div>
        </template>
      </template>

      <template v-slot:item.url="{ item }">
        <v-hover v-if="item.url" v-slot:default="{ hover }">
          <div>
            <a :href="item.url" target="_blank" class="table-link text-break mr-1" @click.stop>{{
              item.url
            }}</a>
            <v-icon
              :class="{ 'visibility-hidden': !hover }"
              small
              @click.stop="copyToClipboard(item, 'url')"
              >content_copy</v-icon
            >
          </div>
        </v-hover>
      </template>

      <template v-slot:item.login_name="{ item }">
        <v-hover v-if="item.description" v-slot:default="{ hover }">
          <div>
            <span class="mr-1">{{ item.description }}</span>
            <v-icon
              :class="{ 'visibility-hidden': !hover }"
              small
              @click.stop="copyToClipboard(item, 'description')"
              >content_copy</v-icon
            >
          </div>
        </v-hover>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <BaseActionMenu
          :actions="getRowActions(item, index)"
          :loading="$store.getters.loading[`delete:api/www-resource-entities/${item.id}`]"
          :item="item"
        />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </component>
</template>

<script>
import simplebar from 'simplebar-vue';
import tableMixin from '../../mixins/table-mixin';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow.vue';
import BaseActionMenu from '../base/BaseActionMenu.vue';
import generateUuid from '../../util/uuid';
import { openSnackbar } from '../../util/event-bus';

export default {
  name: 'PasswordTable',

  components: { BaseActionMenu, BaseExpandedTableRow, simplebar },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        rowsPerPage: 1000,
        totalItems: 999,
        page: 1,
      }),
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valueToCopy: '',
      copyToClipboardInputId: '',
    };
  },

  created() {
    this.copyToClipboardInputId = `copy-text-${generateUuid()}`;
  },

  computed: {
    headers() {
      let headers = [
        {
          text: this.$t('general.no'),
          value: 'no',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.type'),
          value: 'type',
        },
        {
          text: this.$t('password_manager.labels.owner'),
          value: 'client_name',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.project'),
          value: 'project',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.url'),
          value: 'url',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('general.common_contact_info.login_name'),
          value: 'login_name',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('password_manager.labels.tags'),
          value: 'tags',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
          align: this.$vuetify.breakpoint.smAndDown ? 'hidden' : '', // "hidden" puts text-hidden class on td to hide it
        },
      ];

      if (this.compact) {
        headers[0].hidden = 'lgAndDown';
        headers[2].hidden = 'lgAndDown';
      }

      if (this.$vuetify.breakpoint.smAndDown) {
        headers = headers.filter((a) => a.value !== 'no');
      }

      if (this.$vuetify.breakpoint.xsOnly) {
        headers = headers.filter((a) => a.value !== 'url' && a.value !== 'login_name');
      }

      return headers;
    },
  },

  methods: {
    getRowActions(item, index) {
      let actions = [
        {
          callback: (p) => this.$emit('delete', { item: p, index }),
          label: this.$t('general.controls.remove'),
          icon: 'clear',
        },
      ];

      if (this.$vuetify.breakpoint.smAndDown) {
        actions.unshift({
          callback: (p) => this.$emit('click:row', { item: p, index }),
          label: this.$t('general.controls.edit'),
          icon: 'edit',
        });
      }

      if (item.is_encrypted) {
        actions.unshift(
          {
            callback: (p) => this.copyToClipboard(p),
            label: this.$t('password_manager.controls.copy_password'),
            icon: 'content_copy',
          },
          {
            callback: (p) => this.$emit('share:password', p),
            label: this.$t('general.controls.share'),
            icon: 'share',
          }
        );
      } else {
        actions.unshift({
          callback: (p) => this.$emit('encrypt:password', { item: p, index }),
          label: this.$t('password_manager.controls.encrypt'),
          icon: 'lock',
        });
      }

      if (this.compact) {
        actions = actions.filter((a) => a.label !== this.$t('general.controls.remove'));
      }

      if (!item.details) {
        actions = actions.filter(
          (a) => a.label !== this.$t('password_manager.controls.copy_password')
        );
      }

      return actions;
    },

    onRowClick(item, { index }) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.tableMixin_toggleRowExpand(item);
      } else {
        this.$emit('click:row', { item, index });
      }
    },

    onPageChange(page) {
      if (page !== this.pagination.page) {
        this.$emit('update:page', page);
        this.$vuetify.goTo(0, {
          duration: 900,
          easing: 'easeOutQuint',
        });
      }
    },

    getItemIndex(item) {
      // this is for getting item index on mobile, because we cannot take index from expanded-item slot
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === item.id) {
          return i;
        }
      }
    },

    getItemClass(item) {
      let classes = ['relative'];
      if (!item.is_encrypted) {
        classes.push('bg-weekend');
      } else {
        classes.push('clickable');
      }
      return classes;
    },

    copyToClipboard(item, field = 'details') {
      const copyText = document.querySelector(`#${this.copyToClipboardInputId}`);
      copyText.setAttribute('type', 'text');
      copyText.value = item[field];
      copyText.select();
      document.execCommand('copy');
      copyText.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
      openSnackbar(this.$t(`password_manager.general.${field}_copied`));
    },
  },
};
</script>

<style scoped></style>

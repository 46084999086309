<template>
  <div v-if="documentId && $isAdmin()">
    <v-tooltip
      v-if="!displayAsListItem"
      :open-delay="tooltipOpenDelay"
      :top="tooltipDirection === 'top'"
      :bottom="tooltipDirection === 'bottom'"
      :left="tooltipDirection === 'left'"
      :right="tooltipDirection === 'right'"
      content-class="tooltip-in-dialog"
    >
      <template #activator="{ on }">
        <v-btn v-on="on" class="ml-1" :fab="fab" :small="small" :icon="icon" @click.stop="onClick">
          <v-icon>{{ buttonIcon }}</v-icon>
        </v-btn>
      </template>
      <div>
        {{ buttonText }}
      </div>
    </v-tooltip>

    <!-- For display on mobile inside BaseActionMenu -->
    <v-list-item v-else @click.stop="onClick">
      <v-list-item-icon>
        <v-icon>{{ buttonIcon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ buttonText }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  // Used together with <DocumentSigningInvitationDialog/> somewhere in the same view.
  // Couldn't place it here because of flickering when inside <BaseActionMenu/>'s v-speed-dial
  name: 'SendDocumentSigningInvitationButton',

  props: {
    documentId: {
      type: Number,
      default: 0,
    },
    documentTitle: {
      type: String,
      default: '',
    },

    tooltipDirection: {
      type: String,
      default: 'bottom',
    },
    tooltipOpenDelay: {
      type: [Number, String],
      default: 0,
    },

    displayAsListItem: {
      // Changes button to a list item. So it works consistent on smAndTouch devices in BaseActionMenu
      type: Boolean,
      default: false,
    },

    // Vuetify button props
    fab: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDialogOpen() {
      return +this.$route.query['signing-invitation-id'] === this.documentId;
    },

    buttonIcon() {
      return 'email';
    },

    buttonText() {
      return this.$t('documents.controls.send_signing_invitation');
    },
  },

  methods: {
    onClick() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          'signing-invitation-id': this.documentId,
          'signing-invitation-title': this.documentTitle,
        },
      });
    },
  },
};
</script>

<style scoped></style>

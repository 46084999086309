<template>
  <v-progress-linear v-show="isVisible" color="accent" class="progress-bar-small" indeterminate />
</template>

<script>
import eventBus, { HIDE_TOP_PROGRESS_BAR, SHOW_TOP_PROGRESS_BAR } from '@/util/event-bus';

export default {
  name: 'TopProgressBar',

  data() {
    return {
      isVisible: false,
    };
  },

  created() {
    eventBus.$on(SHOW_TOP_PROGRESS_BAR, () => {
      this.isVisible = true;
    });

    eventBus.$on(HIDE_TOP_PROGRESS_BAR, () => {
      this.isVisible = false;
    });
  },
};
</script>

<style scoped></style>

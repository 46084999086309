import httpClient from '@/api/http-client';

const endpoint = 'api/finances/bank-accounts';

const bankAccountService = {
  getAll: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find`, { params: { query } }),

  create: (bankAccount) => httpClient.post(endpoint, bankAccount),

  update: (bankAccount) => httpClient.put(`${endpoint}/${bankAccount.id}`, bankAccount),

  delete: (bankAccount) => httpClient.delete(`${endpoint}/${bankAccount.id}`),
};

export default bankAccountService;

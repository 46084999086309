import Vue from 'vue';
import timeTrackingService from '@/api/time-tracking-service';
import {
  endOfMonth,
  format,
  isMonday,
  isSunday,
  nextSunday,
  previousMonday,
  startOfMonth,
} from 'date-fns';

export function getDefaultTimeTrackingFilterParams() {
  let dateFrom = startOfMonth(new Date());
  let dateTo = endOfMonth(new Date());

  if (!isMonday(dateFrom)) {
    dateFrom = previousMonday(dateFrom);
  }

  if (!isSunday(dateTo)) {
    dateTo = nextSunday(dateTo);
  }

  return {
    date_from: format(dateFrom, 'yyyy-MM-dd'),
    date_to: format(dateTo, 'yyyy-MM-dd'),
  };
}

const state = {
  timeTrackingEntriesByUser: {},
  timeTrackingFilterParams: getDefaultTimeTrackingFilterParams(),
};

const getters = {};

const mutations = {
  SET_TIME_TRACKING_FILTER_PARAMS(state, params) {
    state.timeTrackingFilterParams = params;
  },

  RESET_TIME_TRACKING_ENTRIES_BY_USER(state) {
    state.timeTrackingEntriesByUser = {};
  },

  SET_TIME_TRACKING_ENTRIES_BY_USER(state, timeTrackingUsers) {
    const userTimeTrackingMap = {};
    for (let i = 0; i < timeTrackingUsers.length; i++) {
      const { user_id, projects } = timeTrackingUsers[i];
      userTimeTrackingMap[user_id] = projects;
    }
    state.timeTrackingEntriesByUser = userTimeTrackingMap;
  },

  STORE_TIME_TRACKING_ENTRY(state, entry) {
    for (let i = 0; i < state.timeTrackingEntriesByUser[entry.user_id].length; i++) {
      const project = state.timeTrackingEntriesByUser[entry.user_id][i];
      for (let j = 0; j < project.sprints.length; j++) {
        const sprint = project.sprints[j];
        if (sprint.sprint_id === entry.sprint_id) {
          Vue.set(sprint.time_spent, entry.date, entry.time_spent);
          break;
        }
      }
    }
  },
};

const actions = {
  async fetchTimeTrackingEntries({ commit, rootState }, params) {
    const { data } = await timeTrackingService.get(params);
    commit('SET_TIME_TRACKING_FILTER_PARAMS', params);
    commit('SET_TIME_TRACKING_ENTRIES_BY_USER', data);
  },

  async storeTimeTrackingEntry({ rootState, commit }, entry) {
    const { data } = await timeTrackingService.create(entry);
    commit('STORE_TIME_TRACKING_ENTRY', data);

    if (entry.user_id === rootState.auth.currentUser.id) {
      commit('notifications/REMOVE_REMINDER', 'fillInTimeTracking', { root: true });
    }

    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import httpClient from '@/api/http-client';

const endpoint = 'api/projects';

const projectService = {
  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find`, { params: { query } }),
};

export default projectService;

<template>
  <v-slide-y-transition>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12 mx-2">
            <v-row align="center" justify="space-between" no-gutters>
              <div class="mt-3 mb-0 mx-3">
                <img
                  :src="darkTheme ? '/img/logo-2x.png' : '/img/logo-black-2x.png'"
                  height="35px"
                />
              </div>
              <div class="mr-2">
                <LanguageSelector :selected-locale="locale" @change="UPDATE_LOCALE" />
              </div>
            </v-row>
            <v-form @submit.prevent="login">
              <v-card-text>
                <v-text-field
                  v-model="authData.username"
                  :label="$t('general.common_contact_info.login_name')"
                  prepend-icon="person"
                  name="login"
                  type="email"
                />
                <v-text-field
                  v-model="authData.password"
                  :label="$t('general.common_contact_info.password')"
                  prepend-icon="lock"
                  name="password"
                  id="password"
                  type="password"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="loading[`post:oauth/token`]"
                  :loading="loading[`post:oauth/token`]"
                  color="primary"
                  type="submit"
                  text
                >
                  {{ $t('general.controls.login') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <SentryTester />
    </v-container>
  </v-slide-y-transition>
</template>

<script>
import { hideTopProgressBar } from '@/util/event-bus';
import { mapGetters, mapMutations, mapState } from 'vuex';
import LanguageSelector from '@/components/LanguageSelector.vue';
import SentryTester from '@/components/SentryTester.vue';

export default {
  name: 'Login',

  components: {
    SentryTester,
    LanguageSelector,
  },

  data() {
    return {
      authData: {
        username: '',
        password: '',
      },
    };
  },

  computed: {
    ...mapState('auth', ['lastAccessedRoute']),
    ...mapState('settings', ['darkTheme', 'locale']),
    ...mapGetters(['loading']),
  },

  created() {
    hideTopProgressBar();
  },

  methods: {
    ...mapMutations('settings', ['UPDATE_LOCALE']),

    login() {
      this.$store
        .dispatch('auth/login', this.authData)
        .then(() => {
          this.$router.push(this.lastAccessedRoute || { path: '/' });
        })
        .catch(() => {});
    },
  },
};
</script>

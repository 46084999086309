<template>
  <BaseAutocomplete
    :value="value"
    :search-function="search"
    :get-by-id-function="getById"
    :label="label"
    :initial-item="initialItem"
    :error-messages="errorMessages"
    :append-icon="appendIcon"
    item-text="title"
    clearable
    @input="$emit('input', $event)"
    @update:initial-item="$emit('update:initial-item', $event)"
    @click:append="$emit('click:append')"
  />
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete.vue';
import i18n from '@/i18n/i18n-config';
import projectService from '@/api/project-service';

export default {
  name: 'ProjectAutocomplete',

  components: { BaseAutocomplete },

  props: {
    value: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    initialItem: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: () => i18n.t('general.project'),
    },
    appendIcon: {
      type: String,
      default: '',
    },
  },

  methods: {
    getById: projectService.getById,
    search: projectService.search,
  },
};
</script>

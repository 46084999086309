import { permissionsGuard } from '@/router/guards';
import store from '@/store';

export const invoiceRoutes = [
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/invoices/Invoices.vue'),
    beforeEnter: permissionsGuard,
    meta: { requiredPermissions: ['invoices.view.*'], menuGroup: 'finances' },
    children: [
      {
        path: 'create',
        name: 'createInvoice',
        component: () => import('@/views/invoices/CreateInvoice.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['invoices.view.*'],
          menuGroup: 'finances',
          getBackRoute: () => ({
            name: 'invoices',
            query: store.state.invoices.invoiceFilterParams,
          }),
        },
      },
      {
        path: 'update-counter',
        name: 'updateInvoiceCounter',
        component: () => import('@/views/invoices/EditInvoiceNumberCounter.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['invoices.view.*'],
          menuGroup: 'finances',
          getBackRoute: () => ({
            name: 'invoices',
            query: store.state.invoices.invoiceFilterParams,
          }),
        },
      },
    ],
  },
  {
    path: '/invoices/:invoiceId',
    name: 'invoice',
    component: () => import('@/views/invoices/Invoice.vue'),
    beforeEnter: permissionsGuard,
    meta: { requiredPermissions: ['invoices.view.*'], menuGroup: 'finances' },
    children: [
      {
        path: 'edit',
        name: 'editInvoice',
        component: () => import('@/views/invoices/EditInvoice.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['invoices.view.*'],
          menuGroup: 'finances',
          getBackRoute: () => ({
            name: 'invoice',
          }),
        },
      },
      {
        path: 'edit-paid-sum',
        name: 'editInvoicePaidSum',
        component: () => import('@/views/invoices/EditInvoicePaidSum.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['invoices.view.*'],
          menuGroup: 'finances',
          getBackRoute: () => ({
            name: 'invoice',
          }),
        },
      },
      {
        path: 'sent-history',
        name: 'invoiceSentHistory',
        component: () => import('@/views/invoices/InvoiceSentHistory.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['invoices.view.*'],
          menuGroup: 'finances',
          getBackRoute: () => ({
            name: 'invoice',
          }),
        },
      },
    ],
  },
];

// localStorage doesn't work in android web view
// since it's used for optional features such as remembering logged-in user
// we can ignore it, otherwise thrown errors might break some app functionality in that environment

export function getLocalStorageItem(key, options = { parse: false }) {
  try {
    let item = localStorage.getItem(key);
    if (options.parse) {
      item = JSON.parse(item);
    }
    return item;
  } catch (e) {
    return null;
  }
}

export function setLocalStorageItem(key, value, options = { stringify: false }) {
  try {
    localStorage.setItem(key, options.stringify ? JSON.stringify(value) : value);
  } catch (e) {
    // failed to set, it's okay, do nothing
  }
}

export function removeLocalStorageItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // failed to remove, it's okay, do nothing
  }
}

import clone from 'just-clone';

export function removeEmptyFilters(params) {
  const updatedParams = {};
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'boolean' || params[key]) {
      updatedParams[key] = params[key];
    }
  });
  return updatedParams;
}

export function getSanitizedFilterParams(params) {
  const p = clone(params);

  // id fields that don't end with '_id'
  const nonStandardIdFields = ['assigned_to'];

  Object.keys(params).forEach((key) => {
    if ((!key.includes('_ids') && key.includes('_id')) || nonStandardIdFields.includes(key)) {
      p[key] = +p[key];
    }

    if (p[key] === 'false') {
      p[key] = false;
    }

    if (p[key] === 'true') {
      p[key] = true;
    }

    if (
      key.includes('filter-open') ||
      key.includes('dropped-document') ||
      key.includes('uploading-document') ||
      key.includes('signing-document') ||
      key.includes('signing-invitation-id') ||
      key.includes('signing-invitation-title') ||
      key.includes('create-') // for in modal modals, like creating an invoice contact while creating a received invoice
    ) {
      delete p[key];
    }
  });

  if (+p.page === 1) {
    delete p.page;
  }

  return p;
}

// Supports strings and non-nested arrays
export function getSearchParamsString(params) {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      return;
    }
    if (typeof params[key] === 'object' && params[key].length > 0) {
      // params[key] is an array
      for (let i = 0; i < params[key].length; i++) {
        if (params[key][i]) {
          searchParams.append(`${key}[]`, params[key][i]);
        }
      }
    } else if (['number', 'string', 'boolean'].includes(typeof params[key])) {
      searchParams.append(key, params[key]);
    }
  });
  return searchParams.toString();
}

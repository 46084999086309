import httpClient from '@/api/http-client';

const endpoint = 'api/time-tracking';

const timeTrackingService = {
  get: (params) => httpClient.get(endpoint, { params }),

  getReport: (params) => httpClient.get(`${endpoint}/report`, { params }),

  create: (timeTrackingEntry) => httpClient.post(endpoint, timeTrackingEntry),
};

export default timeTrackingService;

<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <BaseFilePreviewFormLayout
    :file="internalDocument.file"
    :title="formTitle"
    :disabled="disabled"
    @cancel="$emit('cancel')"
    @save="$emit('save', internalDocument)"
  >
    <v-row dense>
      <v-col v-if="!internalDocument.id" cols="12">
        <v-file-input
          v-model="internalDocument.file"
          :error-messages="errors['file']"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
          :accept="internalDocumentFileFormats"
          @change="formMixin_clearErrors('file')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="internalDocument.document_date"
          :error-messages="errors['document_date']"
          :label="$t('documents.labels.document_date')"
          @input="formMixin_clearErrors('document_date')"
        />
      </v-col>
    </v-row>
  </BaseFilePreviewFormLayout>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput.vue';
import formMixin from '@/mixins/form-mixin';
import { mapGetters } from 'vuex';
import BaseFilePreviewFormLayout from '@/components/base/BaseFilePreviewFormLayout.vue';

export default {
  name: 'InternalDocumentForm',

  components: {
    BaseFilePreviewFormLayout,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    internalDocument: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('internalDocuments', ['internalDocumentFileFormats']),

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.internalDocument.id ? 'edit' : 'new'}_internal_document`
      );
    },
  },
};
</script>

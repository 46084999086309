import router from '@/router';

// always pass this.$route to currentRoute argument
export function returnFromChildView(currentRoute, queryParam = '', returnRoute = null) {
  if (queryParam && currentRoute.query[queryParam]) {
    // check if view was opened with a query param
    // if yes, pushing current route without queryParam will close the view
    const newQuery = {
      ...currentRoute.query,
    };
    delete newQuery[queryParam];
    router.push({
      ...currentRoute,
      query: newQuery,
    });
    return;
  }

  if (returnRoute) {
    // else, if a returnRoute was specified, push that instead
    router.push(returnRoute);
    return;
  }

  if (currentRoute.meta.getBackRoute) {
    // lastly, check if return route function was specified in currentRoute.meta
    router.push(currentRoute.meta.getBackRoute());
  }
}

// http://www.jacklmoore.com/notes/rounding-in-javascript/
function round(value, decimals = 2) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export default round;

export function getCurrency(value, currency = '€') {
  return `${currency}${round(+value).toFixed(2)}`;
}

import i18n from '@/i18n/i18n-config';
import moment from 'moment';
import { lt } from 'date-fns/locale';
import { parseLocalStorageBoolean } from '@/util/booleans';
import { setLocalStorageItem } from '@/util/local-storage';

function getSettingsStructureForLocalStorage(settings) {
  const transformedSettings = {};
  Object.keys(settings).forEach((key) => {
    if (typeof settings[key] === 'boolean') {
      transformedSettings[key] = settings[key] ? 'on' : 'off';
    } else {
      transformedSettings[key] = settings[key];
    }
  });
  return JSON.stringify(transformedSettings);
}

const state = {
  locale: 'en',
  darkTheme: false,
  devRant: true,
  miniWiki: true,
  newIssues: true,
  ongoingSprints: true,
  upcomingEvents: true,
  showPersonalEvents: true,
  showCompanyEvents: true,
  showLeadEvents: true,
  showClientEvents: true,
  showCompletedEvents: true,
  urgentUserStories: true,
  showTeamEvents: {},
  sidenavOpen: true,
  iconShortcuts: false,
  isTouchDevice: false,
  showAlternateDashboard: false,
  displayMyAndUnassignedUserStoriesOnly: false,
};

const getters = {
  defaultDateFnsConfig(state) {
    // use this in date-fns function calls where locale matters as the last argument
    // https://date-fns.org/v2.26.0/docs/format (check docs, e.g.)
    const config = {
      weekStartsOn: 1,
    };
    if (state.locale === 'lt') {
      config.locale = lt;
    }
    return config;
  },
};

const mutations = {
  STORE_APPLICATION_SETTINGS(state, payload) {
    state.locale = payload.locale || 'en';
    state.darkTheme = parseLocalStorageBoolean(payload.darkTheme);
    state.devRant = parseLocalStorageBoolean(payload.devRant);
    state.miniWiki = parseLocalStorageBoolean(payload.miniWiki);
    state.newIssues = parseLocalStorageBoolean(payload.newIssues);
    state.ongoingSprints = parseLocalStorageBoolean(payload.ongoingSprints);
    state.urgentUserStories = parseLocalStorageBoolean(payload.urgentUserStories);
    state.upcomingEvents = parseLocalStorageBoolean(payload.upcomingEvents);
    state.showPersonalEvents = parseLocalStorageBoolean(payload.showPersonalEvents);
    state.showCompanyEvents = parseLocalStorageBoolean(payload.showCompanyEvents);
    state.showLeadEvents = parseLocalStorageBoolean(payload.showLeadEvents);
    state.showClientEvents = parseLocalStorageBoolean(payload.showClientEvents);
    state.showCompletedEvents = parseLocalStorageBoolean(payload.showCompletedEvents);
    state.showTeamEvents = payload.showTeamEvents || {};
    state.sidenavOpen = parseLocalStorageBoolean(payload.sidenavOpen);
    state.iconShortcuts = parseLocalStorageBoolean(payload.iconShortcuts);
    state.showAlternateDashboard = parseLocalStorageBoolean(payload.showAlternateDashboard);
    state.displayMyAndUnassignedUserStoriesOnly = parseLocalStorageBoolean(
      payload.displayMyAndUnassignedUserStoriesOnly
    );
  },

  UPDATE_APPLICATION_SETTINGS(state, payload) {
    if (payload) {
      state[payload.key] = payload.value;
    }
    setLocalStorageItem('evo_app_settings', getSettingsStructureForLocalStorage(state));
  },

  UPDATE_SETTING(state, { key, value }) {
    state[key] = value;
  },

  UPDATE_LOCALE(state, locale) {
    state.locale = locale;
    i18n.locale = locale;
    moment.locale(locale);
    setLocalStorageItem('evo_app_settings', getSettingsStructureForLocalStorage(state));
  },

  SET_TOUCH_DEVICE(state) {
    state.isTouchDevice = true;
  },
};

const actions = {
  updateSetting({ state, commit }, { key, value }) {
    if (key) {
      commit('UPDATE_SETTING', { key, value });
    }
    setLocalStorageItem('evo_app_settings', getSettingsStructureForLocalStorage(state));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vue from 'vue';

// if request URL contains a string from this array - don't add it to pendingRequests
const blacklistedFragments = [];

function isUrlBlacklisted(url) {
  for (let i = 0; i < blacklistedFragments.length; i++) {
    if (url.indexOf(blacklistedFragments[i]) > -1) {
      return true;
    }
  }

  return false;
}

const state = {
  pendingRequests: {},
};

const getters = {
  // Use this to show progress indicators for API requests
  // e.g.: $store.getters.loading['get:api/users']
  loading(state) {
    return state.pendingRequests;
  },
};

const mutations = {
  STORE_PENDING_REQUEST(state, url) {
    Vue.set(state.pendingRequests, url, true);
  },

  REMOVE_PENDING_REQUEST(state, url) {
    Vue.delete(state.pendingRequests, url);
  },
};

const actions = {
  setPendingRequest({ commit }, url) {
    if (url && !isUrlBlacklisted(url)) {
      commit('STORE_PENDING_REQUEST', url);
    }
  },

  removePendingRequest({ commit }, url) {
    if (url && !isUrlBlacklisted(url)) {
      commit('REMOVE_PENDING_REQUEST', url);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

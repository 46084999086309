<template>
  <div ref="actionsDiv" class="inherit-background full-height">
    <div
      v-if="computedActions.length && deviceType === 'mdAndMouse'"
      class="base-action-menu-wrapper"
    >
      <div :class="{ 'always-visible': alwaysVisible }" class="desktop-and-mouse-actions">
        <slot name="desktop-prepend"></slot>
        <template v-for="action in computedActions">
          <v-speed-dial
            v-if="action.children && action.children.length"
            :key="action.label"
            :direction="childActionsSpeedDialDirection"
            open-on-hover
          >
            <template v-slot:activator>
              <slot :name="`action.${action.key}`" :device-type="deviceType">
                <v-tooltip
                  :top="childActionsSpeedDialActivatorTooltipDirection === 'top'"
                  :bottom="childActionsSpeedDialActivatorTooltipDirection === 'bottom'"
                  open-delay="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click.stop="onActionClick(action, $event)">
                      <v-icon v-if="action.icon" :small="smallButtons">{{ action.icon }}</v-icon>
                      <img v-else-if="action.image" :src="action.image" height="20" />
                    </v-btn>
                  </template>
                  <div>
                    {{ action.label }}
                  </div>
                </v-tooltip>
              </slot>
            </template>

            <slot
              v-for="childAction in action.children"
              :name="`action.${childAction.key}`"
              :device-type="deviceType"
            >
              <v-tooltip :key="childAction.label" open-delay="500" left>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      :disabled="disabled || childAction.disabled || childAction.loading"
                      :loading="childAction.loading"
                      :class="childAction.class"
                      fab
                      small
                      @click.stop="onActionClick(childAction, $event)"
                    >
                      <v-icon v-if="childAction.icon" :small="smallButtons">{{
                        childAction.icon
                      }}</v-icon>
                      <img v-else-if="childAction.image" :src="childAction.image" height="20" />
                    </v-btn>
                  </div>
                </template>
                <span>
                  {{ childAction.label }}
                </span>
              </v-tooltip>
            </slot>
          </v-speed-dial>

          <slot v-else :name="`action.${action.key}`" :device-type="deviceType">
            <v-tooltip open-delay="500" bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    :disabled="disabled || action.disabled || action.loading"
                    :loading="action.loading"
                    :small="smallButtons"
                    :class="action.class"
                    icon
                    @click.stop="onActionClick(action, $event)"
                  >
                    <v-icon v-if="action.icon" :small="smallButtons">{{ action.icon }}</v-icon>
                    <img v-else-if="action.image" :src="action.image" height="20" />
                  </v-btn>
                </div>
              </template>
              <span>
                {{ action.label }}
              </span>
            </v-tooltip>
          </slot>
        </template>
      </div>
    </div>

    <template v-else-if="computedActions.length">
      <v-menu
        :value="mobileMenuIsOpen"
        :position-x="positionX"
        :position-y="positionY"
        :left="left"
        z-index="202"
        offset-y
        @input="onCloseMenu"
      >
        <template v-if="showMenuActivatorOnMobile" v-slot:activator="{ on }">
          <v-icon v-if="simple" v-on="on" class="ml-2" :small="smallButtons" @click.stop
            >more_vert</v-icon
          >

          <v-btn v-else v-on="on" :small="smallButtons" icon @click.stop>
            <v-icon :small="smallButtons">more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <slot
            v-for="action in computedActions"
            :name="`action.${action.key}`"
            :device-type="deviceType"
          >
            <v-list-item
              :key="action.label"
              :disabled="disabled || action.disabled || action.loading"
              :loading="action.loading"
              @click="onActionClick(action, $event)"
              @pointerdown.stop
            >
              <v-list-item-icon>
                <v-progress-circular v-if="action.loading" size="24" width="2" indeterminate />
                <v-icon v-else-if="action.icon">
                  {{ action.icon }}
                </v-icon>
                <img v-else-if="action.image" :src="action.image" height="20" class="mx-auto" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ action.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </slot>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BaseActionMenu',

  props: {
    actions: {
      type: Array,
      default: () => [
        // { icon: '', label: '', loading: false, disabled: false, callback: () => { ... } },
      ],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    item: {
      type: [Object, null],
      default: null,
    },

    // ----
    // For computing direction of children action speed dials
    // so they never hide themselves under the table
    // Only necessary when there are actions with children
    index: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 50,
    },
    // ----

    maxButtons: {
      type: Number,
      default: 2,
    },

    simple: {
      // removes tooltips and replaces v-btns with v-icons
      // which reduces render times in large tables
      type: Boolean,
      default: false,
    },

    smallButtons: {
      type: Boolean,
      default: false,
    },

    left: {
      type: Boolean,
      default: false,
    },

    alwaysVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      touchTimer: null,
      mobileMenuIsOpen: false,
      positionX: null,
      positionY: null,
      contextMenuTimeoutId: null,
      showMenuActivatorOnMobile: false,
      isScrolling: false,
    };
  },

  computed: {
    ...mapState('settings', ['isTouchDevice']),

    childActionsSpeedDialDirection() {
      if (this.totalItems < 3) {
        return 'left';
      }

      return this.index < this.totalItems - 2 ? 'bottom' : 'top';
    },

    childActionsSpeedDialActivatorTooltipDirection() {
      if (this.childActionsSpeedDialDirection === 'top') {
        return 'bottom';
      }

      return 'top';
    },

    deviceType() {
      if (this.isTouchDevice || this.$vuetify.breakpoint.smAndDown) {
        return 'smOrTouch';
      }

      return 'mdAndMouse';
    },

    computedActions() {
      // flattens child actions to top level on mobile
      const computedActions = [];
      for (let i = 0; i < this.actions.length; i++) {
        const action = this.actions[i];
        computedActions.push(action);
        if (action.children?.length && this.deviceType === 'smOrTouch') {
          computedActions.push(...action.children);
        }
      }
      return computedActions;
    },
  },

  mounted() {
    const row = this.$refs.actionsDiv.closest('tr');
    if (row) {
      // Attempt to bind to a table row to work like a context menu.
      row.addEventListener('contextmenu', this.onRowContextMenu);
      row.addEventListener('touchstart', this.onRowTouchStart);
      // row.addEventListener('touchmove', this.onRowTouchMove);  // this event ruins functionality for firefox mobile
      row.addEventListener('touchend', this.onRowTouchEnd);
    } else {
      // If table row is not found, show the activator button
      this.showMenuActivatorOnMobile = true;
    }
    window.addEventListener('pointerdown', this.onCloseMenu);
    window.addEventListener('scroll', this.onWindowScroll);
    window.addEventListener('scrollend', this.onWindowScrollEnd);
  },

  beforeDestroy() {
    window.removeEventListener('pointerdown', this.onCloseMenu);
    window.removeEventListener('scroll', this.onWindowScroll);
    window.removeEventListener('scrollend', this.onWindowScrollEnd);
  },

  methods: {
    onActionClick(action, domEvent) {
      if (!action.callback) {
        return;
      }

      const payload = this.item ? this.item : domEvent;
      action.callback(payload);
    },

    onRowContextMenu(event) {
      // using touchstart event instead, because contextmenu doesn't work on iOS Safari while using touch.
      // preventing default behaviour, so it doesn't fire on other browsers.
      event.preventDefault();
    },

    async onRowTouchStart(event) {
      if (this.deviceType === 'mdAndMouse' || this.isScrolling || !event) {
        return;
      }
      event.target.classList.add('user-select-none');
      clearTimeout(this.contextMenuTimeoutId);
      this.contextMenuTimeoutId = setTimeout(() => {
        if (event.touches.length) {
          this.positionX = event.touches[0].clientX;
          this.positionY = event.touches[0].clientY;
        } else {
          this.positionX = event.touchstartX;
          this.positionY = event.touchstartY;
        }
        this.onOpenMobileMenu(event);
        event.target.classList.remove('user-select-none');
      }, 700);
    },

    onRowTouchMove() {
      clearTimeout(this.contextMenuTimeoutId);
    },

    onWindowScroll() {
      this.isScrolling = true;
      clearTimeout(this.contextMenuTimeoutId);
    },

    onWindowScrollEnd() {
      this.isScrolling = false;
    },

    onRowTouchEnd(event) {
      if (this.mobileMenuIsOpen) {
        // to prevent the menu from closing
        event.preventDefault();
      }
      clearTimeout(this.contextMenuTimeoutId);
      this.isScrolling = false;
    },

    onOpenMobileMenu() {
      if (!this.isScrolling) {
        this.mobileMenuIsOpen = true;
      }
    },

    onCloseMenu() {
      this.mobileMenuIsOpen = false;
    },
  },
};
</script>

export const getDefaultProjectWarrantyFormItem = () => ({});

const state = {
  newProjectWarranty: getDefaultProjectWarrantyFormItem(),
};

const getters = {};

const mutations = {
  SET_NEW_PROJECT_WARRANTY(state, projectWarranty) {
    state.newProjectWarranty = projectWarranty;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

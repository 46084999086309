/* eslint-disable no-shadow,no-param-reassign,no-mixed-operators */
import router from '../../router/index';
import userService from '../../api/user-service';
import authService from '../../api/auth-service';
import i18n from '../../i18n/i18n-config';
import { openSnackbar, showTopProgressBar } from '@/util/event-bus';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/util/local-storage';
import { getDefaultUserFilterParams } from '@/store/modules/users-module';

let availablePermissionsPromise = null;

const state = {
  accessToken: null,
  lastAccessedRoute: null,
  impersonatedUserId: null,
  currentUser: null,
  refreshTokenPromise: null,
  permissions: null,
  allPermissions: [],
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },

  accessToken(state) {
    return state.accessToken;
  },

  impersonatedUserId(state) {
    return state.impersonatedUserId;
  },

  permissions(state) {
    return state.permissions;
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
  },

  SET_LAST_ACCESSED_ROUTE(state, route) {
    state.lastAccessedRoute = route;
  },

  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },

  SET_ALL_PERMISSIONS(state, permissions) {
    state.allPermissions = permissions;
  },

  SET_REFRESH_TOKEN_PROMISE(state, promise) {
    state.refreshTokenPromise = promise;
  },

  CLEAR_AUTH_DATA(state) {
    state.accessToken = null;
    state.currentUser = null;
    state.permissions = null;
    state.impersonatedUserId = null;
  },

  SET_IMPERSONATED_USER_ID(state, id) {
    state.impersonatedUserId = id;
  },

  SET_USER_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
};

const actions = {
  async login({ dispatch }, { username, password }) {
    showTopProgressBar();
    try {
      const res = await authService.login(username, password);
      await dispatch('handleAuthData', res.data);
      await dispatch('getCurrentUser');
    } catch (e) {
      if (e.response?.status === 400) {
        openSnackbar(i18n.t('users.invalid_credentials'));
      }
      throw e;
    }
  },

  handleAuthData({ commit }, payload) {
    commit('SET_ACCESS_TOKEN', payload.access_token);
    setLocalStorageItem('evo_access_token', payload.access_token);
    setLocalStorageItem('evo_refresh_token', payload.refresh_token);
  },

  async tryAutoLogin({ commit, dispatch }) {
    if (state.accessToken && state.currentUser) {
      // user is already logged in
      return;
    }

    const accessToken = getLocalStorageItem('evo_access_token');
    if (accessToken) {
      commit('SET_ACCESS_TOKEN', accessToken);
    } else {
      return;
    }

    const impersonatedUserId = getLocalStorageItem('evo_impersonated_user_id');
    if (+impersonatedUserId > 0) {
      commit('SET_IMPERSONATED_USER_ID', +impersonatedUserId);
    }

    await dispatch('getCurrentUser');
  },

  logout({ commit }) {
    commit('CLEAR_AUTH_DATA');
    commit('notifications/RESET_NOTIFICATIONS', null, { root: true });
    removeLocalStorageItem('evo_access_token');
    removeLocalStorageItem('evo_refresh_token');
    removeLocalStorageItem('evo_impersonated_user_id');
    router.replace({ name: 'login' });
  },

  async getCurrentUser({ commit, state, dispatch }) {
    let response;
    if (!state.accessToken) {
      return response;
    }
    try {
      availablePermissionsPromise = userService.getAvailablePermissions();
      response = await userService.getCurrent();
      commit('SET_CURRENT_USER', response.data.user);
      await dispatch('transformUserPermissions', response.data.permissions);
      if (response.data.user?.role !== 'client') {
        dispatch('shortcuts/fetchCurrentUserShortcuts', null, { root: true });
      }
    } catch (e) {
      response = e;
      dispatch('logout');
    }
    return response;
  },

  async impersonateUser({ state }, user) {
    const { accessToken } = state;
    const refreshToken = getLocalStorageItem('evo_refresh_token');

    if (accessToken && refreshToken) {
      let url = 'impersonate';
      url += `?user_id=${user.id}`;
      url += `&access_token=${accessToken}`;
      url += `&refresh_token=${refreshToken}`;
      if (user.role === 'client') {
        window.open(`${import.meta.env.VITE_APP_CLIENT_PORTAL_URL}${url}`);
      } else {
        window.location.href = `/${url}`;
      }
    } else {
      openSnackbar(`${i18n.t('users.lacking_auth_data')} ${i18n.t('users.try_login_again')}`);
    }
  },

  setImpersonator({ commit, dispatch }, payload) {
    commit('SET_ACCESS_TOKEN', payload.access_token);
    commit('SET_IMPERSONATED_USER_ID', +payload.user_id);
    setLocalStorageItem('evo_impersonated_user_id', payload.user_id);
    setLocalStorageItem('evo_access_token', payload.access_token);
    setLocalStorageItem('evo_refresh_token', payload.refresh_token);
    dispatch('getCurrentUser').then(() => {
      router.replace('/').catch();
    });
  },

  stopImpersonating() {
    removeLocalStorageItem('evo_impersonated_user_id');
    const usersPage = router.resolve({ name: 'users', query: getDefaultUserFilterParams() }).href;
    window.location.replace(usersPage);
  },

  async transformUserPermissions({ commit, state }, currentUserPermissions) {
    const userPermissions = {};
    const response = await availablePermissionsPromise;
    const allPermissions = response.data;
    commit('SET_ALL_PERMISSIONS', allPermissions);
    // if a user is admin, grant him all permissions. This is done to avoid multiple checks,
    // e.g. it's enough to check in the app $can['documents.*'], instead of
    // $can['documents.*'] || $isAdmin()
    if (state.currentUser.role === 'admin') {
      Object.values(allPermissions).forEach((group) => {
        group.forEach((permission) => {
          userPermissions[permission] = true;
        });
      });
    } else {
      // role === 'employee'
      Object.values(allPermissions).forEach((group) => {
        group.forEach((permission) => {
          userPermissions[permission] = false;
        });
      });
      currentUserPermissions.forEach((permission) => {
        Object.values(allPermissions).forEach((group) => {
          if (group.indexOf(permission.permission) !== -1) {
            userPermissions[permission.permission] = true;
          }
        });
      });

      if (state.currentUser.role === 'employee') {
        // ---------------------------------------------
        // These permissions don't exist on backend. They are here in order to have
        // a unified method in showing/hiding stuff with the helper function $can(['permission'])
        userPermissions['calendar.*'] = true;
        userPermissions['app.shortcuts.*'] = true;
        userPermissions['app.birthdays.*'] = true;
        userPermissions['app.global_search.*'] = true;
        userPermissions['app.settings.home_page.*'] = true;
        userPermissions['app.settings.dark_theme.*'] = true;
        // ---------------------------------------------
      }

      if (userPermissions['calendar.company_events.*']) {
        userPermissions['calendar.company_events.create'] = true;
      }

      if (userPermissions['documents.*']) {
        userPermissions['documents.internal.*'] = true;
        userPermissions['documents.received_invoice.*'] = true;
        userPermissions['documents.sent_invoice.*'] = true;
        userPermissions['documents.company_request.*'] = true;
        userPermissions['documents.supplier_agreement.*'] = true;
        userPermissions['documents.client_agreement.*'] = true;
        userPermissions['documents.commercial_offer.*'] = true;
        userPermissions['documents.employee.*'] = true;
        userPermissions['documents.non_disclosure_agreement.*'] = true;
      }
      if (userPermissions['documents.internal.*']) {
        userPermissions['documents.internal.view.*'] = true;
        userPermissions['documents.internal.create'] = true;
      }
      if (userPermissions['documents.received_invoice.*']) {
        userPermissions['documents.received_invoice.view.*'] = true;
        userPermissions['documents.received_invoice.create'] = true;
      }
      if (userPermissions['documents.sent_invoice.*']) {
        userPermissions['documents.sent_invoice.view.*'] = true;
        userPermissions['documents.sent_invoice.create'] = true;
      }
      if (userPermissions['documents.company_request.*']) {
        userPermissions['documents.company_request.view.*'] = true;
        userPermissions['documents.company_request.create'] = true;
      }
      if (userPermissions['documents.supplier_agreement.*']) {
        userPermissions['documents.supplier_agreement.view.*'] = true;
        userPermissions['documents.supplier_agreement.create'] = true;
      }
      if (userPermissions['documents.client_agreement.*']) {
        userPermissions['documents.client_agreement.view.*'] = true;
        userPermissions['documents.client_agreement.create'] = true;
      }
      if (userPermissions['documents.commercial_offer.*']) {
        userPermissions['documents.commercial_offer.view.*'] = true;
        userPermissions['documents.commercial_offer.create'] = true;
      }
      if (userPermissions['documents.non_disclosure_agreement.*']) {
        userPermissions['documents.non_disclosure_agreement.view.*'] = true;
        userPermissions['documents.non_disclosure_agreement.create'] = true;
      }
      if (userPermissions['documents.employee.*']) {
        userPermissions['documents.employee.create'] = true;
      }
      userPermissions['documents.employee_documents.view.*'] = true;

      if (userPermissions['expenses.*']) {
        userPermissions['expenses.assignments.*'] = true;
        userPermissions['expenses.categories.*'] = true;
        userPermissions['expenses.items.*'] = true;
      }

      if (userPermissions['invoices.*']) {
        userPermissions['invoices.view.*'] = true;
        userPermissions['invoices.create'] = true;
      }

      if (userPermissions['inventory.*']) {
        userPermissions['inventory.categories.*'] = true;
        userPermissions['inventory.items.*'] = true;
      }
      userPermissions['inventory.items.view.*'] = true;
      userPermissions['inventory.requests.view.*'] = true;

      if (userPermissions['projects.*']) {
        userPermissions['projects.create.*'] = true;
        userPermissions['projects.view.*'] = true;
      }
    }

    commit('SET_USER_PERMISSIONS', userPermissions);
  },
};

const authModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default authModule;

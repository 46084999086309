<template>
  <div class="full-height">
    <TopProgressBar />

    <NavigationDrawer />

    <Toolbar />

    <DocumentDropzone v-if="!$isClient()" />

    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-main>
  </div>
</template>

<script>
import NavigationDrawer from './NavigationDrawer.vue';
import Toolbar from './Toolbar.vue';
import DocumentDropzone from '@/components/DocumentDropzone.vue';
import TopProgressBar from '@/components/TopProgressBar.vue';

export default {
  name: 'AppWrapper',

  components: {
    TopProgressBar,
    DocumentDropzone,
    NavigationDrawer,
    Toolbar,
  },
};
</script>

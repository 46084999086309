import store from '../store';

function canAccess(permissions) {
  if (store.getters['auth/currentUser'].role !== 'admin') {
    let isAllowed = false;
    permissions.forEach((permission) => {
      if (store.getters['auth/permissions'][permission]) {
        isAllowed = true;
      }
    });
    return isAllowed;
  }
  return true;
}

function loginGuard(to, from, next) {
  if (store.getters['auth/currentUser']) {
    return next(from.path);
  }
  return next();
}

function authGuard(to, from, next) {
  if (!store.getters['auth/currentUser']) {
    return next(from.path === '/' ? '/login' : false);
  }
  return next();
}

// To use this guard, specify requiredPermission in route meta.
// e.g. meta: { requiredPermission: 'can_view_clients' },
// Not used in every project though
function permissionsGuard(to, from, next) {
  if (!store.getters['auth/currentUser']) {
    return next(from.path === '/' ? '/login' : false);
  }

  if (canAccess(to.meta.requiredPermissions)) {
    return next();
  }
  return next(from.path);
}

// To use this guard, specify allowedRoles in route meta.
// e.g. meta: { allowedRoles: ['admin', 'manager'] },
async function roleGuard(to, from, next) {
  if (!to.meta.allowedRoles || !store.getters['auth/currentUser']) {
    return next('/login');
  }

  if (to.meta.allowedRoles.indexOf(store.getters['auth/currentUser'].role) > -1) {
    return next();
  }
  return next(from.path);
}

export { loginGuard, authGuard, permissionsGuard, roleGuard };

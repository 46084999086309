<template>
  <v-navigation-drawer
    :value="sidenavOpen"
    :clipped="$vuetify.breakpoint.lgAndUp"
    fixed
    app
    @input="updateSetting({ key: 'sidenavOpen', value: $event })"
  >
    <div v-if="$vuetify.breakpoint.mdAndDown" class="ma-3">
      <img
        :src="darkTheme ? '/img/logo-2x.png' : '/img/logo-black-2x.png'"
        height="30"
        class="clickable"
        @click="onLogoClick"
      />
    </div>
    <v-list dense nav>
      <template v-for="(item, index) in menuItems">
        <v-divider v-if="item.divider" :key="`divider-${index}`" class="mb-1" />
        <BaseLink
          v-else-if="!item.children && $can(item.permissions)"
          :key="item.text"
          :to="item.route"
          class="mb-1 d-flex"
        >
          <v-list-item
            :prepend-icon="item.icon"
            :disabled="!item.route"
            active-class="primary--text"
            link
          >
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </BaseLink>

        <v-list-group
          v-else-if="$can(item.permissions)"
          v-model="item.expanded"
          :key="item.text"
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </template>

          <template v-for="(child, i) in item.children">
            <BaseLink
              v-if="
                !child.grandchildren &&
                (!child.permissions || $can(child.permissions)) &&
                !child.hidden
              "
              :key="i"
              :to="child.route"
              :disabled="!child.route"
              class="mb-1 d-flex"
            >
              <v-list-item :disabled="!child.route" link>
                <v-list-item-icon />
                <v-list-item-title> {{ child.text }} </v-list-item-title>
              </v-list-item>
            </BaseLink>

            <v-list-group
              v-else-if="(!child.permissions || $can(child.permissions)) && !child.hidden"
              v-model="child.expanded"
              :key="child.text"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </template>

              <template v-for="grandchild in child.grandchildren">
                <BaseLink
                  v-if="$can(grandchild.permissions) && !grandchild.hidden"
                  :key="grandchild.text"
                  :to="grandchild.route"
                  :disabled="!grandchild.route"
                  class="mb-1 d-flex"
                >
                  <v-list-item :disabled="!grandchild.route" class="pl-16" link>
                    <v-list-item-title>
                      {{ grandchild.text }}
                    </v-list-item-title>
                  </v-list-item>
                </BaseLink>
              </template>
            </v-list-group>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getDefaultTimeTrackingFilterParams } from '@/store/modules/time-tracking-module';
import { getSearchParamsString } from '@/util/filter-params';
import { getReactPortalUrl, openReactPortal } from '@/util/react-portal-integration';
import BaseLink from '@/components/base/BaseLink.vue';
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';

export default {
  name: 'NavigationDrawer',

  components: { BaseLink },

  computed: {
    ...mapState('settings', ['sidenavOpen', 'darkTheme']),
    ...mapState('clientAgreements', ['clientAgreementFilterParams']),
    ...mapState('commercialOffers', ['commercialOfferFilterParams']),
    ...mapState('companyRequests', ['companyRequestFilterParams']),
    ...mapState('employeeDocuments', ['employeeDocumentFilterParams']),
    ...mapState('internalDocuments', ['internalDocumentFilterParams']),
    ...mapState('invoices', ['invoiceFilterParams']),
    ...mapState('plannedFinanceFlows', ['plannedFinanceFlowFilterParams']),
    ...mapState('receivedInvoices', ['receivedInvoiceFilterParams']),
    ...mapState('sentInvoices', ['sentInvoiceFilterParams']),
    ...mapState('supplierAgreements', ['supplierAgreementFilterParams']),
    ...mapState('users', ['userFilterParams']),

    defaultCalendarParams() {
      return {
        type: ['personal', 'company', 'lead', 'client', 'team'],
        date_from: format(startOfWeek(startOfMonth(new Date())), 'yyyy-MM-dd'),
        date_to: format(endOfWeek(endOfMonth(new Date())), 'yyyy-MM-dd'),
      };
    },

    menuItems() {
      if (this.$store.getters['auth/currentUser']?.role === 'client') {
        // client menu items
        return [
          {
            icon: 'euro_symbol',
            text: this.$t('general.invoices'),
            permissions: ['invoices.view.*'],
            route: { name: 'invoices', query: this.invoiceFilterParams },
          },
          {
            icon: 'build',
            text: this.$t('general.projects'),
            route: '/client-projects',
            permissions: ['projects.view.*'],
          },
          {
            icon: 'insert_drive_file',
            text: this.$t('general.documents'),
            expanded: this.$route.meta.menuGroup === 'documents',
            permissions: ['documents.client_agreement.view.*', 'documents.commercial_offer.view.*'],
            children: [
              {
                text: this.$t('general.clients_agreements'),
                route: { name: 'clientAgreements', query: this.clientAgreementFilterParams },
                permissions: ['documents.client_agreement.view.*'],
              },
              {
                text: this.$t('general.commercial_offers'),
                route: { name: 'commercialOffers', query: this.commercialOfferFilterParams },
                permissions: ['documents.commercial_offer.view.*'],
              },
            ],
          },
        ];
      }
      // employee/admin menu items
      return [
        {
          icon: 'dashboard',
          text: 'Dashboard',
          route: getReactPortalUrl(''),
          permissions: ['app.settings.home_page.*'],
        },
        {
          icon: 'today',
          text: this.$t('general.date_and_time.calendar'),
          route: getReactPortalUrl(`calendar?${getSearchParamsString(this.defaultCalendarParams)}`),
          permissions: ['calendar.*'],
        },
        {
          icon: 'lock',
          text: this.$t('general.password_management'),
          route: getReactPortalUrl('passwords'),
          permissions: ['password_manager.*'],
        },
        {
          icon: 'shopping_cart',
          text: this.$t('general.long_term_assets'),
          route: getReactPortalUrl('inventory/list'),
          permissions: ['inventory.items.view.*'],
        },
        {
          icon: 'repeat',
          text: this.$t('general.recurring_works'),
          route: getReactPortalUrl(
            'recurring-works/works?statuses%5B%5D=planned&statuses%5B%5D=in_progress'
          ),
        },
        {
          icon: 'build',
          text: this.$t('general.project_management'),
          expanded: this.$route.meta.menuGroup === 'project-management',
          permissions: ['projects.view.*'],
          children: [
            {
              text: this.$t('general.projects'),
              route: getReactPortalUrl('projects/list?page=1'),
              permissions: ['projects.view.*'],
            },
            {
              text: this.$t('general.time_tracking'),
              route: getReactPortalUrl(
                `time-tracking?${getSearchParamsString(getDefaultTimeTrackingFilterParams())}`
              ),
              permissions: ['projects.view.*'],
              hidden: this.$vuetify.breakpoint.mdAndDown,
            },
            {
              text: this.$t('general.project_workloads'),
              route: getReactPortalUrl(`project-workloads`),
              hidden: !this.$isAdmin(),
            },
            {
              text: this.$t('general.statistics'),
              route: getReactPortalUrl(
                `project-statistics/issues?environment=production&employment_status=present&statuses%5B%5D=active&statuses%5B%5D=planned`
              ),
              permissions: ['projects.view.*'],
            },
            {
              text: this.$t('project_warranties.project_warranties'),
              route: getReactPortalUrl('project-warranties'),
              hidden: !this.$isAdmin(),
            },
          ],
        },
        {
          icon: 'import_contacts',
          text: 'CRM',
          expanded: this.$route.meta.menuGroup === 'contacts',
          permissions: ['newsletters.*', 'crm.leads.manage_all', 'crm.leads.manage_own'], // because we did not add clients.view here - menu group will only be visible for employee with clients.view, as it should
          children: [
            {
              text: this.$t('general.offers'),
              route: getReactPortalUrl(
                'crm?page=1&decision_status=pending&inquiry_received_at_from=2024-01-01&inquiry_received_at_to=2024-12-31'
              ),
              permissions: ['crm.leads.manage_all', 'crm.leads.manage_own'],
            },
            {
              text: this.$t('general.common_contact_info.contacts'),
              route: getReactPortalUrl('contacts'),
              permissions: ['clients.view'],
            },
            {
              icon: 'help',
              text: this.$t('general.surveys'),
              route: '/client-surveys',
              permissions: ['clients.view'],
            },
            {
              icon: 'help',
              text: this.$t('general.newsletters'),
              route: getReactPortalUrl('newsletters/list'),
              permissions: ['newsletters.*'],
            },
          ],
        },
        {
          icon: 'euro_symbol',
          text: this.$t('general.finances'),
          expanded: this.$route.meta.menuGroup === 'finances',
          permissions: [
            'expenses.items.*',
            'expenses.assignments.*',
            'expenses.categories.*',
            'invoices.*',
          ],
          children: [
            {
              text: this.$t('general.overview'),
              route: { name: 'financePlanner', query: this.plannedFinanceFlowFilterParams },
            },
            {
              text: this.$t('general.invoices'),
              route: { name: 'invoices', query: this.invoiceFilterParams },
              permissions: ['invoices.*'],
            },
            {
              text: this.$t('general.expenses'),
              route: '/expenses',
              permissions: ['expenses.items.*', 'expenses.assignments.*', 'expenses.categories.*'],
            },
          ],
        },
        {
          icon: 'insert_drive_file',
          text: this.$t('general.documents'),
          expanded: this.$route.meta.menuGroup === 'documents',
          permissions: [
            'documents.employee_documents.view.*',
            'documents.internal.view.*',
            'documents.received_invoice.view.*',
            'documents.sent_invoice.view.*',
            'documents.company_request.view.*',
            'documents.client_agreement.view.*',
            'documents.commercial_offer.view.*',
            'documents.supplier_agreement.view.*',
          ],
          children: [
            {
              text: this.$t('general.employee_documents'),
              route: { name: 'employeeDocuments', query: this.employeeDocumentFilterParams },
              permissions: ['documents.employee_documents.view.*'],
            },
            {
              text: this.$t('general.internal_documents'),
              route: { name: 'internalDocuments', query: this.internalDocumentFilterParams },
              permissions: ['documents.internal.view.*'],
            },
            {
              text: this.$t('general.received_invoices'),
              route: { name: 'receivedInvoices', query: this.receivedInvoiceFilterParams },
              permissions: ['documents.received_invoice.view.*'],
            },
            {
              text: this.$t('general.sent_invoices'),
              route: { name: 'sentInvoices', query: this.sentInvoiceFilterParams },
              permissions: ['documents.sent_invoice.view.*'],
            },
            {
              text: this.$t('general.company_applications'),
              route: { name: 'companyRequests', query: this.companyRequestFilterParams },
              permissions: ['documents.company_request.view.*'],
            },
            {
              text: this.$t('general.clients_agreements'),
              route: { name: 'clientAgreements', query: this.clientAgreementFilterParams },
              permissions: ['documents.client_agreement.view.*'],
            },
            {
              text: this.$t('general.commercial_offers'),
              route: { name: 'commercialOffers', query: this.commercialOfferFilterParams },
              permissions: ['documents.commercial_offer.view.*'],
            },
            {
              text: this.$t('general.supplier_agreements'),
              route: { name: 'supplierAgreements', query: this.supplierAgreementFilterParams },
              permissions: ['documents.supplier_agreement.view.*'],
            },
          ],
        },
        {
          icon: 'business',
          text: this.$t('general.company_management'),
          expanded: this.$route.meta.menuGroup === 'company-management',
          permissions: ['strategic_plan.*', 'employees.*'],
          children: [
            {
              text: this.$t('general.strategic_plan'),
              route: getReactPortalUrl(
                'strategic-plan/goals?status=active&starts_at_from=2024-01-01&starts_at_to=2024-12-31'
              ),
              permissions: ['strategic_plan.*'],
            },
            {
              text: this.$t('general.company_info.departments'),
              route: getReactPortalUrl('erp-companies'),
            },
            {
              text: this.$t('general.employees'),
              route: '/employees',
              permissions: ['employees.*'],
            },
            {
              text: this.$t('general.candidates'),
              route: getReactPortalUrl('hr-positions'),
              permissions: [],
            },
          ],
        },
        {
          icon: 'adb',
          text: this.$t('general.system'),
          expanded: this.$route.meta.menuGroup === 'system',
          permissions: [],
          children: [
            { text: this.$t('general.teams'), route: getReactPortalUrl('teams') },
            {
              text: this.$t('general.users'),
              route: getReactPortalUrl('users?status=active'),
            },
          ],
        },
        {
          icon: 'cloud',
          text: this.$t('general.external_systems'),
          hasSubGroups: true,
          expanded: this.$route.meta.menuGroup === 'external-systems',
          permissions: [],
          children: [
            {
              text: this.$t('general.system_status'),
              route: getReactPortalUrl('infrastructure-health'),
            },
            { text: 'Gitlab' },
            { text: 'Sentry' },
            { text: 'Slack' },
            { text: 'Interneto vizija' },
            {
              icon: 'help',
              text: this.$t('general.evolvo_eu_management'),
              expanded: this.$route.meta.menuSubGroup === 'evolvo-eu-management',
              grandchildren: [
                {
                  text: this.$t('general.testimonials'),
                  route: '/client-testimonials',
                  permissions: [],
                },
              ],
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions('settings', ['updateSetting']),

    onLogoClick() {
      openReactPortal('');
    },
  },
};
</script>

/* eslint-disable no-param-reassign,default-case */
import Vue from 'vue';
import Vuetify from '@/plugins/vuetify';
import i18n from '../../i18n/i18n-config';
import notificationService from '../../api/notification-service';
import { findById } from '@/util/array';
import { addWeeks, format, isBefore, isSaturday, isSunday, subDays } from 'date-fns';
import { getLocalDateFromUTC } from '@/util/dates';
import { getDefaultTimeTrackingFilterParams } from '@/store/modules/time-tracking-module';
import { getReactPortalUrl } from '@/util/react-portal-integration';
import { openReactPortal } from '@/util/react-portal-integration';
import { getSearchParamsString } from '@/util/filter-params';
import employeeService from '@/api/employee-service.js';

function decorateNotification(notification) {
  switch (notification.model) {
    case 'document':
      notification.icon = 'insert_drive_file';
      notification.route = {
        name: 'employeeDocuments',
      };
      break;
    case 'client_survey_answer':
      notification.icon = 'question_answer';
      notification.route = { name: 'client-surveys' };
      break;
    case 'inventory_request':
      notification.icon = 'assignment';
      notification.route = getReactPortalUrl('inventory/inquiries');
      break;
    case 'project_user_story':
      notification.icon = 'comment';
      notification.route = getReactPortalUrl(
        `project/${notification.related_model_id}/user-stories/${notification.model_column_id}`
      );
      break;
    case 'project_issue':
      notification.icon = 'bug_report';
      notification.route = getReactPortalUrl(
        `project/${notification.related_model_id}/issues/${notification.model_column_id}/edit`
      );
      break;
    case 'www_resource':
      notification.icon = 'lock';
      notification.route = getReactPortalUrl('passwords');
      break;
    default:
      notification.icon = 'info';
      break;
  }
  return notification;
}

export function getAbsenceHistoryEventsNeedApprovedByResponsibleKey(eventId) {
  return `absenceHistoryEventNeedApprovedByResponsible${eventId}`;
}
export function getAbsenceHistoryEventsNeedApproved(eventId) {
  return `absenceHistoryEventNeedApproved${eventId}`;
}

const state = {
  notifications: [],
  notificationPagination: {},
  reminders: [],
};

const getters = {
  activeNotifications(state) {
    return state.notifications.filter((n) => !n.is_seen);
  },

  notifications(state) {
    return state.notifications;
  },

  notificationPagination(state) {
    return state.notificationPagination;
  },
};

const mutations = {
  STORE_NOTIFICATIONS_PAGE(state, payload) {
    const notifications = payload.data;
    if (payload.current_page > 1) {
      // Some notifications may have made their way into state.notifications during
      // unseen notification fetching. This code block ensures that those notifications
      // do not get added a second time.
      const newNotifications = [];
      for (let i = 0; i < notifications.length; i++) {
        if (!findById(notifications[i].id, state.notifications)) {
          newNotifications.push(decorateNotification(notifications[i]));
        }
      }
      state.notifications.push(...newNotifications);
    } else {
      state.notifications = notifications.map((n) => decorateNotification(n));
    }
    state.notificationPagination = {
      page: payload.current_page,
      rowsPerPage: payload.per_page,
      total: payload.total,
    };
  },

  STORE_NEW_NOTIFICATIONS(state, notifications) {
    const newNotifications = [];
    for (let i = 0; i < notifications.length; i++) {
      if (!findById(notifications[i].id, state.notifications)) {
        newNotifications.push(decorateNotification(notifications[i]));
      }
    }
    state.notifications = [...newNotifications, ...state.notifications];
  },

  RESET_NOTIFICATIONS(state) {
    state.notifications = [];
    state.notificationPagination = {};
    state.reminders = [];
  },

  STORE_REMINDER(state, reminder) {
    state.reminders.push(reminder);
  },

  REMOVE_REMINDER(state, key) {
    state.reminders = state.reminders.filter((r) => r.key !== key);
  },

  TOGGLE_NOTIFICATION_SEEN_STATUS(state, notification) {
    for (let i = 0; i < state.notifications.length; i++) {
      const n = state.notifications[i];
      if (n.id === notification.id) {
        Vue.set(n, 'is_seen', !n.is_seen);
        break;
      }
    }
  },

  MARK_ALL_NOTIFICATIONS_AS_SEEN(state) {
    for (let i = 0; i < state.notifications.length; i++) {
      const notification = state.notifications[i];
      Vue.set(notification, 'is_seen', true);
    }
  },
};

const actions = {
  async getLastEntries({ dispatch, rootGetters }) {
    await notificationService.getLastEntries().then((response) => {
      if (rootGetters['auth/permissions']['projects.view.*']) {
        dispatch('remindAboutTimeTracking', response.data.last_time_tracking_updated_at);
      }
      if (rootGetters['auth/permissions']['newsletters.*']) {
        dispatch('remindAboutNewsletter', response.data.last_newsletter_updated_at);
      }
    });
  },

  async getNotifications({ commit }, params = {}) {
    await notificationService.get(params).then((res) => {
      if (params.date_from) {
        commit('STORE_NEW_NOTIFICATIONS', res.data);
      } else {
        commit('STORE_NOTIFICATIONS_PAGE', res.data);
      }
    });
  },

  async setupMyHealthAndSafetyReminders({ commit }) {
    const { data } = await notificationService.getMyHealthAndSafetyCheckDates();

    if (!data?.length) {
      return;
    }

    const twoWeeksFromNow = addWeeks(new Date(), 2);

    if (data[0].next_health_check_at) {
      const nextHealthCheckAt = new Date(data[0].next_health_check_at);
      if (isBefore(nextHealthCheckAt, twoWeeksFromNow)) {
        commit('STORE_REMINDER', {
          key: 'checkHealth',
          icon: 'local_hospital',
          title: `${i18n.t('notifications.health_certificate_expiring_on')} ${format(
            nextHealthCheckAt,
            'yyyy-MM-dd'
          )}.`,
        });
      }
    }

    if (data[0].next_work_safety_instruction_at) {
      const nextSafetyInstructionAt = new Date(data[0].next_work_safety_instruction_at);
      if (isBefore(nextSafetyInstructionAt, twoWeeksFromNow)) {
        commit('STORE_REMINDER', {
          key: 'getWorkSafetyInstruction',
          icon: 'engineering',
          title: `${i18n.t('notifications.listen_to_work_safety_instruction')} ${format(
            nextSafetyInstructionAt,
            'yyyy-MM-dd'
          )}.`,
        });
      }
    }
  },

  async setupAdminOverdueHealthAndSafetyReminders({ commit }) {
    const { data } = await notificationService.getOverdueHealthAndSafetyEmployees();
    if (!data?.length) {
      return;
    }

    const now = format(new Date(), 'yyyy-MM-dd');
    const overdueHealthChecks = [];
    const overdueSafetyInstructions = [];

    data.forEach((employee) => {
      if (employee.next_health_check_at < now) {
        overdueHealthChecks.push(`${employee.first_name} ${employee.last_name}`);
      }
      if (employee.next_work_safety_instruction_at < now) {
        overdueSafetyInstructions.push(`${employee.first_name} ${employee.last_name}`);
      }
    });

    if (overdueHealthChecks.length) {
      commit('STORE_REMINDER', {
        key: 'overdueHealthChecks',
        icon: 'local_hospital',
        title: `${i18n.t('notifications.health_certificates_expired')}: ${overdueHealthChecks.join(
          ', '
        )}.`,
      });
    }

    if (overdueSafetyInstructions.length) {
      commit('STORE_REMINDER', {
        key: 'overdueSafetyInstructions',
        icon: 'engineering',
        title: `${i18n.t(
          'notifications.work_safety_instructions_expired'
        )}: ${overdueSafetyInstructions.join(', ')}.`,
      });
    }
  },

  async setupEmployeeAbsenceEvents({ commit }) {
    const { data } = await employeeService.getAbsenceEvents();

    if (!data) {
      return;
    }

    const absence_history_events_need_approved = data.absence_history_events_need_approved;
    const absence_history_events_need_approved_by_responsible =
      data.absence_history_events_need_approved_by_responsible;

    absence_history_events_need_approved.forEach((event) => {
      const key = getAbsenceHistoryEventsNeedApproved(event.id);
      const title = `${event.user.personal_data.full_name} ${i18n
        .t('notifications.asks_you_to_approve_their_vacation_application')
        .toLowerCase()}`;

      commit('STORE_REMINDER', {
        key,
        icon: 'waving_hand',
        title,
        route: {
          name: 'viewEmployeeVacationApplication',
          params: { applicationId: event.id },
        },
      });
    });

    absence_history_events_need_approved_by_responsible.forEach((event) => {
      const key = getAbsenceHistoryEventsNeedApprovedByResponsibleKey(event.id);
      const title = `${event.user.personal_data.full_name} ${i18n
        .t('notifications.asks_you_to_substitute_while_on_vacation')
        .toLowerCase()}`;

      commit('STORE_REMINDER', {
        key,
        icon: 'waving_hand',
        title,
        route: {
          name: 'viewEmployeeVacationApplication',
          params: { applicationId: event.id },
        },
      });
    });
  },

  async updateNotificationSeenStatus({ commit }, notification) {
    commit('TOGGLE_NOTIFICATION_SEEN_STATUS', notification);
    await notificationService.updateNotificationSeenStatus(notification).catch(() => {
      commit('TOGGLE_NOTIFICATION_SEEN_STATUS', notification);
    });
  },

  async updateAllNotificationsAsSeen({ commit }) {
    await notificationService.updateAllNotificationsAsSeen().then(() => {
      commit('MARK_ALL_NOTIFICATIONS_AS_SEEN');
    });
  },

  remindAboutTimeTracking({ rootState, commit }, date) {
    if (!date || Vuetify.framework.breakpoint.mdAndDown || rootState.auth.impersonatedUserId) {
      return;
    }
    let lastWorkDay = subDays(new Date().setHours(6), 1);
    if (isSunday(lastWorkDay)) {
      lastWorkDay = subDays(lastWorkDay, 2);
    } else if (isSaturday(lastWorkDay)) {
      lastWorkDay = subDays(lastWorkDay, 1);
    }
    if (isBefore(getLocalDateFromUTC(new Date(date)), lastWorkDay)) {
      openReactPortal(
        `time-tracking/sheet?${getSearchParamsString(
          getDefaultTimeTrackingFilterParams()
        )}&show-message=true`
      );
    }
  },

  remindAboutNewsletter({ commit }, date) {
    if (!date) {
      return;
    }

    const updateInterval = 30; // days
    const lastUpdate = subDays(new Date(), updateInterval);
    if (isBefore(getLocalDateFromUTC(new Date(date)), lastUpdate)) {
      commit('STORE_REMINDER', {
        key: 'createNewsletter',
        icon: 'email',
        title: i18n.t('notifications.create_newsletter'),
        route: '/newsletters',
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

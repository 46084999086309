<template>
  <div v-if="documentId && !$isClient()">
    <v-tooltip
      v-if="!displayAsListItem"
      :open-delay="tooltipOpenDelay"
      :top="tooltipDirection === 'top'"
      :bottom="tooltipDirection === 'bottom'"
      :left="tooltipDirection === 'left'"
      :right="tooltipDirection === 'right'"
      content-class="tooltip-in-dialog"
    >
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          :loading="cleaningSigners"
          :disabled="cleaningSigners"
          :fab="fab"
          :small="small"
          :icon="icon"
          class="ml-1"
          @click.stop="onClick"
        >
          <v-icon>{{ buttonIcon }}</v-icon>
        </v-btn>
      </template>
      <div>
        {{ buttonText }}
      </div>
    </v-tooltip>

    <!-- For display on mobile inside BaseActionMenu -->
    <v-list-item v-else :disabled="cleaningSigners" @click.stop="onClick">
      <v-list-item-icon>
        <v-progress-circular v-if="cleaningSigners" size="24" width="2" indeterminate />
        <v-icon v-else>{{ buttonIcon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ buttonText }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import documentService from '@/api/document-service';
import { openSnackbar } from '@/util/event-bus';

export default {
  name: 'CleanupDocumentSignersButton',

  props: {
    documentId: {
      type: Number,
      default: 0,
    },

    tooltipDirection: {
      type: String,
      default: 'bottom',
    },
    tooltipOpenDelay: {
      type: [Number, String],
      default: 0,
    },

    displayAsListItem: {
      // Changes button to a list item. So it works consistent on smAndTouch devices in BaseActionMenu
      type: Boolean,
      default: false,
    },

    // Vuetify button props
    fab: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    cleaningSigners() {
      return this.$store.getters.loading[`post:api/documents/${this.documentId}/cleanup-signers`];
    },

    buttonIcon() {
      return 'group_remove';
    },

    buttonText() {
      return this.$t('documents.controls.cleanup_document_signers');
    },
  },

  methods: {
    async onClick() {
      await documentService.cleanupSigners(this.documentId);
      openSnackbar(this.$t('documents.general.signers_cleaned_up'));
    },
  },
};
</script>

<style scoped></style>

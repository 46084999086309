/* eslint-disable no-param-reassign */
const formMixin = {
  methods: {
    formMixin_clearErrors(field) {
      if (!this.errors[field]) {
        return;
      }
      this.$emit('clear:errors', field);
    },

    formMixin_getRequiredFieldLabel(label) {
      return `${label}*`;
    },
  },
};

export default formMixin;

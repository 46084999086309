import httpClient from '@/api/http-client';

const projectIssueService = {
  getTotalIssuesCount: () => httpClient.get('api/total-issues'),

  deleteAttachment: (projectIssue, attachment) =>
    httpClient.delete(`api/project-issues-image/${projectIssue.id}`, {
      data: { attachment_ids: [attachment.id] },
    }),
};

export default projectIssueService;

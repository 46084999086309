import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openFile, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format } from 'date-fns';

export const getDefaultCommercialOfferFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultCommercialOfferPdfFormItem = () => ({
  pdf_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultCommercialOfferFilterParams = () => ({});

const state = {
  commercialOffers: [],
  commercialOfferPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedCommercialOffer: {},
  newCommercialOffer: getDefaultCommercialOfferFormItem(),
  newCommercialOfferPdf: getDefaultCommercialOfferPdfFormItem(),
  commercialOfferValidationErrors: {},
  commercialOfferFilterParams: getDefaultCommercialOfferFilterParams(),
  downloadingCommercialOffers: false, // booleans instead of $store.getter.loading because both loading and downloading use the same route
  loadingCommercialOffers: false,
  loadingCommercialOfferPdfPreview: false,
  savingCommercialOfferPdf: false,
};

const getters = {
  commercialOfferFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  },
};

const mutations = {
  SET_COMMERCIAL_OFFERS(state, { data, current_page, per_page, total }) {
    state.commercialOffers = data;
    state.commercialOfferPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_COMMERCIAL_OFFER_FILTER_PARAMS(state, params) {
    state.commercialOfferFilterParams = params;
  },

  SET_EDITED_COMMERCIAL_OFFER(state, commercialOffer) {
    state.commercialOfferValidationErrors = {};
    state.editedCommercialOffer = JSON.parse(JSON.stringify(commercialOffer));
  },

  SET_NEW_COMMERCIAL_OFFER(state, commercialOffer) {
    state.newCommercialOffer = commercialOffer;
  },

  SET_NEW_COMMERCIAL_OFFER_PDF(state, commercialOfferPdf) {
    state.newCommercialOfferPdf = commercialOfferPdf;
  },

  STORE_COMMERCIAL_OFFER(state, commercialOffer) {
    state.commercialOffers.unshift(commercialOffer);
    state.commercialOfferPagination.total += 1;
    state.commercialOfferValidationErrors = {};
  },

  UPDATE_COMMERCIAL_OFFER(state, commercialOffer) {
    state.commercialOffers = updateArrayItem(state.commercialOffers, commercialOffer);
  },

  DELETE_COMMERCIAL_OFFER(state, commercialOffer) {
    state.commercialOffers = removeArrayItem(state.commercialOffers, commercialOffer);
    state.commercialOfferPagination.total -= 1;
  },

  SET_COMMERCIAL_OFFER_VALIDATION_ERRORS(state, commercialOfferValidationErrors) {
    state.commercialOfferValidationErrors = commercialOfferValidationErrors;
  },

  CLEAR_COMMERCIAL_OFFER_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.commercialOfferValidationErrors, field);
  },

  SET_DOWNLOADING_COMMERCIAL_OFFERS(state, value) {
    state.downloadingCommercialOffers = value;
  },

  SET_LOADING_COMMERCIAL_OFFERS(state, value) {
    state.loadingCommercialOffers = value;
  },

  SET_LOADING_COMMERCIAL_OFFER_PDF_PREVIEW(state, value) {
    state.loadingCommercialOfferPdfPreview = value;
  },

  SET_SAVING_COMMERCIAL_OFFER_PDF(state, value) {
    state.savingCommercialOfferPdf = value;
  },
};

const actions = {
  fetchCommercialOffers({ commit }, params) {
    commit('SET_COMMERCIAL_OFFER_FILTER_PARAMS', params);
    commit('SET_LOADING_COMMERCIAL_OFFERS', true);
    return documentService
      .getPage(params, 'commercial_offer')
      .then((res) => {
        commit('SET_COMMERCIAL_OFFERS', res.data);
        return res.data;
      })
      .finally(() => {
        commit('SET_LOADING_COMMERCIAL_OFFERS', false);
      });
  },

  async downloadCommercialOffers({ state, commit }) {
    commit('SET_DOWNLOADING_COMMERCIAL_OFFERS', true);
    const { data } = await documentService.downloadAll(
      state.commercialOfferFilterParams,
      'commercial_offer'
    );
    commit('SET_DOWNLOADING_COMMERCIAL_OFFERS', false);
    downloadFile(data, `${i18n.t('general.commercial_offers')}.zip`);
  },

  storeCommercialOffer({ commit }, commercialOffer) {
    return documentService
      .create({ ...commercialOffer, document_type: 'commercial_offer' })
      .then((res) => {
        commit('STORE_COMMERCIAL_OFFER', res.data);
        commit('SET_NEW_COMMERCIAL_OFFER', getDefaultCommercialOfferFormItem());
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_COMMERCIAL_OFFER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  previewCommercialOfferPdf({ commit }, commercialOffer) {
    commit('SET_LOADING_COMMERCIAL_OFFER_PDF_PREVIEW', true);
    return documentService
      .createPdf(
        { ...commercialOffer, document_type: 'commercial_offer' },
        { responseType: 'blob' }
      )
      .then((res) => {
        const fileURL = URL.createObjectURL(res.data);
        openFile({
          name: 'Preview.pdf',
          url: fileURL,
          isPublic: true,
        });
        return res.data;
      })
      .catch((err) => {
        // because of responseType: 'blob' I can't display validation errors
        // commit('SET_COMMERCIAL_OFFER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      })
      .finally(() => {
        commit('SET_LOADING_COMMERCIAL_OFFER_PDF_PREVIEW', false);
      });
  },

  storeCommercialOfferPdf({ commit }, commercialOffer) {
    commit('SET_SAVING_COMMERCIAL_OFFER_PDF', true);
    return documentService
      .createPdf(
        { ...commercialOffer, document_type: 'commercial_offer' },
        { params: { save: true } }
      )
      .then((res) => {
        commit('STORE_COMMERCIAL_OFFER', res.data);
        commit('SET_NEW_COMMERCIAL_OFFER_PDF', getDefaultCommercialOfferPdfFormItem());
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_COMMERCIAL_OFFER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      })
      .finally(() => {
        commit('SET_SAVING_COMMERCIAL_OFFER_PDF', false);
      });
  },

  editCommercialOffer({ state, commit }, commercialOfferId) {
    const commercialOffer = state.commercialOffers?.find((c) => c.id === commercialOfferId);
    if (commercialOffer) {
      commit('SET_EDITED_COMMERCIAL_OFFER', commercialOffer);
      return Promise.resolve(commercialOffer);
    }
    return documentService.getById(commercialOfferId).then((res) => {
      commit('SET_EDITED_COMMERCIAL_OFFER', res.data);
      return res.data;
    });
  },

  updateCommercialOffer({ commit }, commercialOffer) {
    return documentService
      .update(commercialOffer)
      .then((res) => {
        if (commercialOffer.is_pdf) {
          commit('DELETE_COMMERCIAL_OFFER', commercialOffer);
          commit('STORE_COMMERCIAL_OFFER', res.data);
        } else {
          commit('UPDATE_COMMERCIAL_OFFER', res.data);
        }
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_COMMERCIAL_OFFER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async toggleSeenCommercialOffer({ commit }, commercialOffer) {
    try {
      commit('UPDATE_COMMERCIAL_OFFER', {
        ...commercialOffer,
        seen: commercialOffer.seen ? null : {},
      });
      await documentService.toggleSeen(commercialOffer);
    } catch (e) {
      commit('UPDATE_COMMERCIAL_OFFER', commercialOffer);
    }
  },

  deleteCommercialOffer({ commit }, commercialOffer) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(commercialOffer).then(() => {
        commit('DELETE_COMMERCIAL_OFFER', commercialOffer);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

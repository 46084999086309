<template>
  <v-menu v-if="showInMenu && showBirthdays" bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon :color="birthdayIconColor"> cake </v-icon>
      </v-btn>
    </template>

    <EmployeeBirthdaysList :birthdays="employeeBirthdays" />
  </v-menu>

  <EmployeeBirthdaysList v-else-if="showBirthdays" :birthdays="employeeBirthdays" />
</template>

<script>
import { format } from 'date-fns';
import employeeService from '@/api/employee-service';
import EmployeeBirthdaysList from '@/components/EmployeeBirthdaysList.vue';

export default {
  name: 'EmployeeBirthdays',

  components: { EmployeeBirthdaysList },

  props: {
    showInMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      employeeBirthdays: {
        today: [],
        tomorrow: [],
        this_month: [],
      },
    };
  },

  computed: {
    showBirthdays() {
      return this.$can(['app.birthdays.*']) && this.areBirthdaysIncoming;
    },

    birthdayIconColor() {
      if (this.employeeBirthdays.today.length) {
        return 'primary';
      }
      return '';
    },

    areBirthdaysIncoming() {
      return (
        this.employeeBirthdays.today.length > 0 ||
        this.employeeBirthdays.tomorrow.length > 0 ||
        this.employeeBirthdays.this_month.length > 0
      );
    },
  },

  async created() {
    const { data } = await employeeService.getBirthdays();
    const today = +format(new Date(), 'd');
    this.employeeBirthdays.today = data.today;
    this.employeeBirthdays.tomorrow = data.tomorrow;
    this.employeeBirthdays.this_month = [];
    data.this_month.forEach((birthday) => {
      if (birthday.day - 1 > today) {
        this.employeeBirthdays.this_month.push(birthday);
      }
    });
  },
};
</script>

import projectIssueService from '@/api/project-issue-service';

const state = {
  totalProjectIssuesCount: 0,
  newProjectIssuesCount: 0,
  sentryIssueCounts: [],
};

const getters = {};

const mutations = {
  SET_PROJECT_ISSUE_COUNTS(state, data) {
    state.totalProjectIssuesCount = data.intranet.total_issues;
    state.newProjectIssuesCount = data.intranet.new_issues;
    state.sentryIssueCounts = data.sentry;
  },
};

const actions = {
  async fetchTotalProjectIssuesCount({ commit, rootGetters }) {
    if (rootGetters['auth/currentUser']?.role === 'client') {
      return;
    }
    const { data } = await projectIssueService.getTotalIssuesCount();

    commit('SET_PROJECT_ISSUE_COUNTS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

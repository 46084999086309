import { getRequestKey } from '@/api/http-client';

export function scrollElementIntoView(selector, topBarOffset = 56) {
  const el = document.querySelector(selector);
  if (el) {
    window.scrollTo(0, el.getBoundingClientRect().top + window.scrollY - topBarOffset);
  }
}

export function adjustViewForPagination(res) {
  // scrolls to top of the table when a response returns with page param
  if (!res.config?.params?.page) {
    return;
  }

  const requestKey = getRequestKey(res.config);
  let selector = '.v-data-table-header';

  if (requestKey === 'get:api/project-issues') {
    selector = '.v-data-table-header, .v-main';
  }

  scrollElementIntoView(selector);
}

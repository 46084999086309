<template>
  <v-list subheader>
    <template v-if="birthdays.today.length > 0">
      <v-subheader>
        {{ $t('general.date_and_time.todays_birthdays') }}
      </v-subheader>
      <v-list-item v-for="(employee, index) in birthdays.today" :key="index">
        <v-list-item-avatar>
          <v-icon color="primary">cake</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ employee.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-if="birthdays.tomorrow.length > 0">
      <v-divider />
      <v-subheader>
        {{ $t('general.date_and_time.tomorrows_birthdays') }}
      </v-subheader>
      <v-list-item v-for="(employee, index) in birthdays.tomorrow" :key="index">
        <v-list-item-avatar>
          <v-icon>cake</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ employee.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-if="birthdays.this_month.length > 0">
      <v-divider />
      <v-subheader>
        {{ $t('general.date_and_time.this_months_birthdays') }}
      </v-subheader>
      <v-list-item v-for="(employee, index) in birthdays.this_month" :key="index">
        <v-list-item-avatar> {{ employee.day }} d. </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ employee.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'EmployeeBirthdaysList',

  props: {
    birthdays: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped></style>

import { permissionsGuard } from '@/router/guards';
import store from '@/store';

export const clientAgreementRoutes = [
  {
    path: 'documents/client-agreements',
    name: 'clientAgreements',
    component: () =>
      import(
        /* webpackChunkName: "clientAgreements" */ '@/views/client-agreements/ClientAgreements.vue'
      ),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'documents',
      requiredPermissions: ['documents.client_agreement.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createClientAgreement',
        component: () =>
          import(
            /* webpackChunkName: "createClientAgreement" */ '@/views/client-agreements/CreateClientAgreement.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.client_agreement.view.*'],
        },
      },
      {
        path: 'create-google-doc',
        name: 'createClientAgreementGoogleDoc',
        component: () =>
          import(
            /* webpackChunkName: "createClientAgreementGoogleDoc" */ '@/views/client-agreements/CreateClientAgreementGoogleDoc.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.client_agreement.view.*'],
          getBackRoute: () => ({
            name: 'clientAgreements',
            query: store.state.clientAgreements.clientAgreementFilterParams,
          }),
        },
      },
      {
        path: ':id/share-google-doc',
        name: 'shareClientAgreementGoogleDoc',
        beforeEnter: permissionsGuard,
        component: () =>
          import(
            /* webpackChunkName: "shareClientAgreementGoogleDoc" */ '@/views/client-agreements/ShareClientAgreementGoogleDoc.vue'
          ),
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.client_agreement.view.*'],
          getBackRoute: () => ({
            name: 'clientAgreements',
            query: store.state.clientAgreements.clientAgreementFilterParams,
          }),
        },
      },
      {
        path: ':id/edit',
        name: 'editClientAgreement',
        component: () =>
          import(
            /* webpackChunkName: "editClientAgreement" */ '@/views/client-agreements/EditClientAgreement.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.client_agreement.view.*'],
        },
      },
    ],
  },
];

import httpClient from '@/api/http-client';

const endpoint = 'api/clients';

const contactService = {
  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find`, { params: { query } }),

  searchSuppliers: (query) =>
    httpClient.get(`${endpoint}/find`, { params: { type: 'supplier', query } }),
};

export default contactService;

<template>
  <td :colspan="colspan">
    <slot></slot>
    <v-row class="py-4">
      <template v-for="header in headers">
        <v-col :key="header.value" cols="12" sm="6">
          <span class="text-subtitle-2">
            {{ header.text }}
          </span>
          <br />
          <slot :name="`item.${header.value}`">
            <span v-if="getObjectProperty(item, header.value)">{{
              getObjectProperty(item, header.value)
            }}</span>
            <span v-else> - </span>
          </slot>
        </v-col>
      </template>
    </v-row>
    <slot name="append"></slot>
  </td>
</template>

<script>
import { getObjectProperty } from '@/util/objects';

export default {
  name: 'BaseExpandedTableRow',

  props: {
    headers: {
      type: Array,
      default: () => [],
    },

    colspan: {
      type: Number,
      default: 1,
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    getObjectProperty,
  },
};
</script>

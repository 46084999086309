import httpClient from '@/api/http-client';

const endpoint = 'api/finances/invoices';

const invoiceService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getTotalSales: (params) => httpClient.get(`${endpoint}/total-sales`, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  download: (id, params) =>
    httpClient({
      url: `${endpoint}/download/${id}`,
      method: 'GET',
      responseType: 'blob',
      params,
    }),

  sendViaEmail: (invoiceId, data, lang) =>
    httpClient.post(`${endpoint}/${invoiceId}/send/via-email`, data, { params: { lang } }),

  getSentEmailHistory: (invoice, params) =>
    httpClient.get(`${endpoint}/${invoice.id}/sent-history`, { params }),

  getInvoiceSummary: (params) => httpClient.get(`${endpoint}/aggregate`, { params }),

  // search: (query) => httpClient.get(`${endpoint}/find`, { params: { query } }),

  searchProduct: (query) => httpClient.get(`${endpoint}/items/find`, { params: { query } }),

  create: (invoice) => httpClient.post(endpoint, invoice),

  update: (invoice) => httpClient.put(`${endpoint}/${invoice.id}`, invoice),

  updateNumberCounter: (data) => httpClient.put(`${endpoint}/settings/last-invoice-no`, data),

  updatePaidSum: (invoice) => httpClient.patch(`${endpoint}/${invoice.id}/paid_sum`, invoice),

  markAsPaid: (invoice) => httpClient.put(`${endpoint}/${invoice.id}/payment-received`, invoice),

  invalidate: (invoice) => httpClient.put(`${endpoint}/${invoice.id}/invalidate`, invoice),

  delete: (invoice) => httpClient.delete(`${endpoint}/${invoice.id}`),
};

export default invoiceService;
